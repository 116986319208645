import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { Query, User } from "@revit-order/shared";
import { QueryGenerator } from "@revit-order/shared/src/query";
import { graphQLProductQueryWithAuth, graphQLQueryWithAuth } from "../graphql";
import { HttpFetch } from "..";

export type Result<TResponse, TAction> =
  | {
      readonly type: "next";
      readonly cmd: Cmd<TAction>;
    }
  | {
      readonly type: "done";
      readonly response: TResponse;
    };

export type QueryState<TResponse> = {
  readonly queryGenerator: QueryGenerator<TResponse>;
  readonly data: unknown | undefined;
};

export function startQuery<TResponse, TAction>(
  activeUser: User.ActiveUser,
  marketName: string,
  graphQLProductQuery: ReturnType<typeof graphQLProductQueryWithAuth>,
  queryGenerator: QueryGenerator<TResponse>,
  onReceive: (qs: QueryState<TResponse>) => TAction
): Result<TResponse, TAction> {
  return nextQuery(
    activeUser,
    marketName,
    graphQLProductQuery,
    { queryGenerator: queryGenerator, data: undefined },
    onReceive
  );
}

export function nextQuery<TResponse, TAction>(
  activeUser: User.ActiveUser,
  marketName: string,
  graphQLProductQuery: ReturnType<typeof graphQLProductQueryWithAuth>,
  queryState: QueryState<TResponse>,
  onReceive: (qs: QueryState<TResponse>) => TAction
): Result<TResponse, TAction> {
  const result = queryState.queryGenerator.next(queryState.data);
  if (result.done) {
    return {
      type: "done",
      response: result.value,
    };
  } else {
    return {
      type: "next",
      cmd: requestsToCommand(activeUser, marketName, graphQLProductQuery, result.value as Query.Query, (data) => {
        return onReceive({ ...queryState, data });
      }),
    };
  }
}

function requestsToCommand<Action>(
  activeUser: User.ActiveUser,
  marketName: string,
  graphQLProductQuery: ReturnType<typeof graphQLProductQueryWithAuth>,
  query: Query.Query,
  onReceive: (data: unknown) => Action
): Cmd<Action> {
  switch (query.type) {
    case "HttpBlobQuery": {
      return HttpFetch.fetchOne({}, query.url, "blob", (data) => {
        return onReceive(data);
      });
    }
    case "HttpBlobQueryMultiple": {
      return HttpFetch.fetchMultiple({}, query.url, "blob", (data) => {
        return onReceive(data);
      });
    }
    case "GraphQLProductQuery": {
      return graphQLProductQuery<unknown, unknown, Action>(query.query, query.variables, (data) => {
        return onReceive(data);
      });
    }
    case "GraphQLQuery": {
      return graphQLQueryWithAuth(activeUser)<unknown, unknown, Action>(
        query.query,
        query.variables,
        marketName,
        (data) => {
          return onReceive(data);
        }
      );
    }
    default:
      exhaustiveCheck(query);
      throw new Error("Not implemented");
  }
}
