import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { SharedState, Routes, graphQLQueryWithAuth, RequestGenerator } from "@revit-order/client-infra";
import { Project, MaterialList as ML } from "@revit-order/shared";
import * as General from "./parts/general";
import * as MaterialList from "./parts/material-list";
import * as Files from "./parts/files";
import * as Log from "./parts/log";
import * as GQLOps from "../../generated/generated-operations";
import { clientConfig } from "../../client-config";
import * as ProjectState from "./project-state";

export type State = InitStateData | InitStateProject | InitStateUpdate | ReadyState | ErrorState;

export interface InitStateData {
  readonly type: "init-data";
  readonly projectId: string;
  readonly location: Routes.ProjectLocation;
  readonly metaProduct: Project.MetaProductQuery | undefined;
  readonly materialTables: ML.MaterialTables | undefined;
  readonly projectStatus: Project.ProjectStatusQuery["projectStatus"] | undefined;
}

export interface InitStateProject {
  readonly type: "init-project";
  readonly location: Routes.ProjectLocation;
  readonly metaProduct: Project.MetaProductQuery;
  readonly materialTables: ML.MaterialTables;
  readonly projectStatus: Project.ProjectStatus;
  readonly project: Project.Project | undefined;
}

export interface InitStateUpdate {
  readonly type: "init-update";
  readonly location: Routes.ProjectLocation;
  readonly metaProduct: Project.MetaProductQuery;
  readonly materialTables: ML.MaterialTables;
  readonly projectStatus: Project.ProjectStatus;
  readonly project: Project.Project;
  readonly remainingRecalculationPatches: ReadonlyArray<number>;
}

export interface ReadyState {
  readonly type: "ready";
  readonly location: Routes.ProjectLocation;
  readonly generalState: General.State | undefined;
  readonly materialListState: MaterialList.State | undefined;
  readonly filesState: Files.State | undefined;
  readonly logState: Log.State | undefined;
  readonly projectStatus: Project.ProjectStatus;
  readonly projectState: ProjectState.State;
}

export interface ErrorState {
  readonly type: "error";
  readonly message: "project_not_found" | "project_no_access";
}

export const Action = ctorsUnion({
  ProjectStateAction: (action: ProjectState.Action) => ({
    action,
  }),
  GeneralAction: (action: General.Action) => ({
    action,
  }),
  FilesAction: (action: Files.Action) => ({
    action,
  }),
  LogAction: (action: Log.Action) => ({
    action,
  }),
  MaterialListAction: (action: MaterialList.Action) => ({
    action,
  }),
  DataResponseRecieved: (
    payload:
      | {
          readonly type: "metaProduct";
          readonly data: Project.MetaProductQuery;
        }
      | {
          readonly type: "materialProduct";
          readonly queryState: RequestGenerator.QueryState<ML.MaterialTables>;
        }
      | {
          readonly type: "projectStatus";
          readonly data: Project.ProjectStatusQuery | undefined;
        }
  ) => ({ payload }),
  ProjectRecieved: (project: Project.Project | undefined) => ({
    project,
  }),
  UpdateResponseRecieved: (payload: {
    readonly type: "recalculation";
    readonly patchIndexes: ReadonlyArray<number>;
  }) => ({ payload }),
  NoOp: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  projectId: string,
  location: Routes.ProjectLocation,
  sharedState: SharedState.SharedState,
  prevState: State | undefined
): readonly [State, Cmd<Action>?] {
  const recalcuationNeeded = false;

  if (prevState?.type === "ready" && !recalcuationNeeded && projectId === prevState.projectState.project.id) {
    return initPages(
      sharedState,
      {
        ...prevState,
        type: "ready",
        location: location,
      },
      { type: "prev-state", prevState: prevState.projectState }
    );
  } else {
    const projectStatusCmd = graphQLQueryWithAuth(sharedState.activeUser)<
      GQLOps.Project_ProjectStatusQuery,
      GQLOps.Project_ProjectStatusQueryVariables,
      Action
    >(Project.queryStatus, { projectId: projectId }, sharedState.market.name, (data) =>
      Action.DataResponseRecieved({ type: "projectStatus", data })
    );

    const metaProductCmd = sharedState.graphQLProductQuery<
      Project.MetaProductQuery,
      Project.MetaProductQueryVariables,
      Action
    >(
      Project.queryMetaProduct,
      {
        productId: clientConfig.promaster_meta_id,
      },
      (data) => Action.DataResponseRecieved({ type: "metaProduct", data })
    );

    const materialResult = RequestGenerator.startQuery(
      sharedState.activeUser,
      sharedState.market.name,
      sharedState.graphQLProductQuery,
      ML.getMaterialsYield(sharedState.market),
      (queryState) => Action.DataResponseRecieved({ type: "materialProduct", queryState })
    );
    const materialTables = materialResult.type === "done" ? materialResult.response : undefined;
    const materialTablesCmd = materialResult.type === "next" ? materialResult.cmd : undefined;

    return [
      {
        type: "init-data",
        projectId: projectId,
        location: location,
        metaProduct: undefined,
        materialTables: materialTables,
        projectStatus: undefined,
      },
      Cmd.batch([projectStatusCmd, metaProductCmd, materialTablesCmd]),
    ];
  }
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ProjectStateAction": {
      if (state.type !== "ready" || !state.projectState) {
        return [state];
      }
      const [projectState, cmd, sharedStateAction] = ProjectState.update(
        action.action,
        state.projectState,
        sharedState
      );
      if (state.projectState.project.locked !== projectState.project.locked && !projectState.project.locked) {
        return initPages(
          sharedState,
          {
            type: "ready",
            location: state.location,
            generalState: undefined,
            filesState: undefined,
            logState: undefined,
            materialListState: undefined,
            projectStatus: state.projectStatus,
          },
          {
            type: "new-state",
            project: projectState.project,
            metaProduct: projectState.metaProduct,
            materialTables: projectState.materialTables,
          }
        );
      }
      return [
        {
          ...state,
          projectState,
        },
        Cmd.map(Action.ProjectStateAction, cmd),
        sharedStateAction,
      ];
    }
    case "GeneralAction": {
      if (state.type !== "ready" || !state.generalState || !state.projectState) {
        return [state];
      }
      const [generalState, cmd, sharedStateAction] = General.update(
        action.action,
        state.generalState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          generalState,
        },
        Cmd.map(Action.GeneralAction, cmd),
        sharedStateAction,
      ];
    }

    case "FilesAction": {
      if (state.type !== "ready" || !state.filesState || !state.projectState) {
        return [state];
      }
      const [filesState, cmd, sharedStateAction] = Files.update(
        action.action,
        state.filesState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          filesState,
        },
        Cmd.map(Action.FilesAction, cmd),
        sharedStateAction,
      ];
    }

    case "LogAction": {
      if (state.type !== "ready" || !state.logState || !state.projectState) {
        return [state];
      }
      const [logState, cmd, sharedStateAction] = Log.update(
        action.action,
        state.logState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          logState,
        },
        Cmd.map(Action.LogAction, cmd),
        sharedStateAction,
      ];
    }

    case "MaterialListAction": {
      if (state.type !== "ready" || !state.materialListState || !state.projectState) {
        return [state];
      }
      const [materialListState, cmd, sharedStateAction] = MaterialList.update(
        action.action,
        state.materialListState,
        state.projectState,
        sharedState
      );
      return [
        {
          ...state,
          materialListState,
        },
        Cmd.map(Action.MaterialListAction, cmd),
        sharedStateAction,
      ];
    }

    case "DataResponseRecieved": {
      if (state.type !== "init-data") {
        return [state];
      }
      let newState = state;
      switch (action.payload.type) {
        case "metaProduct":
          newState = {
            ...newState,
            metaProduct: action.payload.data,
          };
          break;
        case "materialProduct": {
          const queryResult = RequestGenerator.nextQuery(
            sharedState.activeUser,
            sharedState.market.name,
            sharedState.graphQLProductQuery,
            action.payload.queryState,
            (queryState) => Action.DataResponseRecieved({ type: "materialProduct", queryState })
          );
          if (queryResult.type === "done") {
            newState = {
              ...newState,
              materialTables: queryResult.response,
            };
          } else {
            return [state, queryResult.cmd];
          }
          break;
        }
        case "projectStatus": {
          const projectStatus = action.payload.data?.projectStatus;

          if (!projectStatus?.exists) {
            return [{ type: "error", message: "project_not_found" }];
          }

          if (!projectStatus?.access) {
            return [{ type: "error", message: "project_no_access" }];
          }

          newState = {
            ...newState,
            projectStatus: action.payload.data?.projectStatus,
          };

          break;
        }
        default:
          exhaustiveCheck(action.payload);
      }
      if (!newState.materialTables || !newState.metaProduct || !newState.projectStatus) {
        return [newState];
      } else if (newState.projectStatus.exists && newState.projectStatus.access) {
        return [
          {
            type: "init-project",
            location: newState.location,
            metaProduct: newState.metaProduct,
            materialTables: newState.materialTables,
            projectStatus: newState.projectStatus,
            project: undefined,
          },
          graphQLQueryWithAuth(sharedState.activeUser)<
            GQLOps.Project_ProjectQuery,
            GQLOps.Project_ProjectQueryVariables,
            Action
          >(Project.query, { projectId: newState.projectId }, sharedState.market.name, (data) =>
            Action.ProjectRecieved(data.project || undefined)
          ),
        ];
      } else {
        return [{ type: "error", message: "project_not_found" }];
      }
    }

    case "ProjectRecieved": {
      if (state.type !== "init-project") {
        return [state];
      }
      if (!action.project) {
        return [{ type: "error", message: "project_not_found" }];
      }
      return initPages(
        sharedState,
        {
          type: "ready",
          location: state.location,
          generalState: undefined,
          filesState: undefined,
          logState: undefined,
          materialListState: undefined,
          projectStatus: state.projectStatus,
        },
        {
          type: "new-state",
          project: action.project,
          metaProduct: state.metaProduct,
          materialTables: state.materialTables,
        }
      );
    }

    case "UpdateResponseRecieved": {
      if (state.type !== "init-update") {
        return [state];
      }
      let newState = state;
      switch (action.payload.type) {
        case "recalculation": {
          const patchIndexes = action.payload.patchIndexes;
          const remaining = newState.remainingRecalculationPatches.filter(
            (p) => !patchIndexes.some((patch) => patch === p)
          );
          newState = {
            ...newState,
            remainingRecalculationPatches: remaining,
          };
          break;
        }
        default:
          exhaustiveCheck(action.payload.type);
      }
      if (newState.remainingRecalculationPatches.length > 0) {
        return [newState];
      }
      return initPages(
        sharedState,
        {
          type: "ready",
          location: newState.location,
          generalState: undefined,
          filesState: undefined,
          logState: undefined,
          materialListState: undefined,
          projectStatus: newState.projectStatus,
        },
        {
          type: "new-state",
          project: newState.project,
          metaProduct: newState.metaProduct,
          materialTables: newState.materialTables,
        }
      );
    }

    case "NoOp": {
      return [state];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function initPages(
  sharedState: SharedState.SharedState,
  prevState: Omit<ReadyState, "projectState">,
  projectStateInput:
    | { readonly type: "prev-state"; readonly prevState: ProjectState.State }
    | {
        readonly type: "new-state";
        readonly project: Project.Project;
        readonly metaProduct: Project.MetaProductQuery;
        readonly materialTables: ML.MaterialTables;
      }
): readonly [State, Cmd<Action>?] {
  const [projectState, projectStateCmdUnmapped] =
    projectStateInput.type === "prev-state"
      ? [projectStateInput.prevState, undefined]
      : ProjectState.init(
          sharedState,
          projectStateInput.project,
          projectStateInput.metaProduct,
          projectStateInput.materialTables
        );
  const projectStateCmd = projectStateCmdUnmapped && Cmd.map(Action.ProjectStateAction, projectStateCmdUnmapped);
  const state = {
    ...prevState,
    projectState: projectState,
  };

  switch (state.location.type) {
    case "General": {
      const [generalState, generalAction] = General.init(
        state.location,
        sharedState,
        state?.generalState,
        state.projectState.project.id
      );
      const generalLocationCmd = Cmd.map(Action.GeneralAction, generalAction);
      return [
        {
          ...state,
          generalState,
        },
        Cmd.batch<Action>([projectStateCmd, generalLocationCmd]),
      ];
    }

    case "Files": {
      const [filesState, filesAction] = Files.init(state.location, sharedState, state?.filesState, state.projectState);
      const filesLocationCmd = Cmd.map(Action.FilesAction, filesAction);
      return [
        {
          ...state,
          filesState,
        },
        Cmd.batch<Action>([projectStateCmd, filesLocationCmd]),
      ];
    }

    case "Log": {
      const [logState, logAction] = Log.init(state.location, sharedState, state?.logState, state.projectState);
      const logLocationCmd = Cmd.map(Action.LogAction, logAction);
      return [
        {
          ...state,
          logState,
        },
        Cmd.batch<Action>([projectStateCmd, logLocationCmd]),
      ];
    }

    case "MaterialList": {
      const [materialListState, materiallistAction] = MaterialList.init(
        state.location,
        sharedState,
        state?.materialListState,
        state.projectState
      );
      const materiallistLocationCmd = Cmd.map(Action.MaterialListAction, materiallistAction);
      return [
        {
          ...state,
          materialListState,
        },
        Cmd.batch<Action>([projectStateCmd, materiallistLocationCmd]),
      ];
    }

    default: {
      return exhaustiveCheck(state.location, true);
    }
  }
}
