import { Quantities } from "./quanitity";
import { QuantityUnion, Units, UnitTypes, Unit } from "./units";

type ValueOf<T> = T[keyof T];

export function convertTo<
  T extends UnitTypes,
  U extends ValueOf<Extract<QuantityUnion, Record<T["type"], T>>>,
  TNum extends number | undefined
>(value: TNum, fromUnit: T, toUnit: U): TNum {
  if (value === undefined) {
    return value;
  }
  if (fromUnit.unitName === toUnit.unitName) {
    return value;
  }

  // TODO: Make this more generic
  let factor = 1;
  if (fromUnit.type === Units.CubicMeterPerHour.type && toUnit.type === Units.LiterPerSecond.type) {
    factor = 1 / 3.6;
  } else if (fromUnit.type === Units.LiterPerSecond.type && toUnit.type === Units.CubicMeterPerHour.type) {
    factor = 3.6;
  }
  return ((value as unknown as number) * factor) as unknown as TNum;
}

export function findUnit<T extends Quantities>(unit: string | undefined, quantity: T): ValueOf<T["units"]> | undefined {
  const unitKeys = Object.keys(quantity.units);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const key = unitKeys.find((key) => (quantity.units as any)[key].unitName === unit);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return key ? (quantity.units as any)[key] : undefined;
}

export function getUnitName(unit: Unit): string {
  const u = Units[unit];
  return u?.unitName || "";
}
