import * as GraphQL from "graphql";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export interface GraphQLResponse<TData = {}, TExtensions = {}> {
  readonly data?: TData;
  readonly errors?: ReadonlyArray<GraphQL.GraphQLError>;
  readonly extensions?: TExtensions;
}

// -- COMMANDS

export type GraphqlFetchCmd<A> = { readonly home: typeof home; readonly type: "Dummy" } | FetchGraphl<A, {}, {}, {}>;

export type FetchResult<TData, TExtensions = {}> = SuccessResponse<TData, TExtensions> | ErrorResponse;

export type SuccessResponse<TData, TExtensions> = {
  readonly type: "SuccessResponse";
  readonly response: GraphQLResponse<TData, TExtensions>;
};

export type ErrorResponse = {
  readonly type: "ErrorResponse";
  readonly message: string;
};

export interface FetchGraphl<A, TData = {}, TVariables = {}, TExtensions = {}> {
  readonly home: typeof home;
  readonly type: "FetchGraphl";
  readonly headers: { readonly [header: string]: string };
  readonly operation: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly gotResponse?: (response: FetchResult<TData, TExtensions>) => A;
}

/**
 * Fetch any graphql without using the cache.
 */
export function fetchGraphl<A, TData = {}, TVariables = {}, TExtensions = {}>(
  headers: { readonly [header: string]: string },
  operation: GraphQL.DocumentNode,
  variables: TVariables,
  gotResponse?: (response: FetchResult<TData, TExtensions>) => A
): FetchGraphl<A, TData, TVariables, TExtensions> {
  return {
    home: "graphql-fetch",
    type: "FetchGraphl",
    headers,
    operation,
    variables,
    gotResponse,
  };
}

export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: GraphqlFetchCmd<A1>): GraphqlFetchCmd<A2> {
  switch (cmd.type) {
    case "Dummy": {
      return cmd;
    }
    case "FetchGraphl": {
      const { gotResponse } = cmd;
      return {
        ...cmd,
        gotResponse: gotResponse && ((success) => actionMapper(gotResponse(success))),
      } as FetchGraphl<A2>;
    }
    default:
      return exhaustiveCheck(cmd, true);
  }
}
