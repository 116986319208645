export interface ClientConfig {
  readonly env: string;
  readonly dsn: string | undefined;
  readonly promaster_meta_id: string;
  readonly promaster_m3_meta_id: string;
  readonly promaster_marker: string;
  readonly oidc_login_url: string;
  readonly oidc_client_id: string;
  readonly image_service_url: string;
  readonly oidc_post_logout_url?: string;
}
