import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { SharedState, Routes } from "@revit-order/client-infra";
import { UserSettings } from "@revit-order/shared";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import * as ProjectState from "../../project-state";

// STATE

export interface MetaTables {}

export interface State {
  readonly expandedItems: ReadonlySet<string>;
  readonly openLightbox: {
    readonly isOpen: boolean;
    readonly imgUrl: string;
  };
}

export const Action = ctorsUnion({
  ToggleExpanded: (id: string) => ({ id }),
  OpenLightbox: (isOpen: boolean, imgUrl: string) => ({ isOpen, imgUrl }),
  SetHideZeroQuantityRows: (hideZeroQuantityRows: boolean, categoryName: ReadonlyArray<string>) => ({
    hideZeroQuantityRows,
    categoryName,
  }),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined,
  _projectState: ProjectState.State
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const state: State = prevState || {
    expandedItems: new Set<string>(),
    openLightbox: { isOpen: false, imgUrl: "" },
  };
  return [state];
}

export function update(
  action: Action,
  state: State,
  _projectState: ProjectState.State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ToggleExpanded": {
      const newExpanded = new Set<string>(state.expandedItems);
      if (newExpanded.has(action.id)) {
        newExpanded.delete(action.id);
      } else {
        newExpanded.add(action.id);
      }
      return [{ ...state, expandedItems: newExpanded }];
    }

    case "OpenLightbox": {
      return [{ ...state, openLightbox: { isOpen: action.isOpen, imgUrl: action.imgUrl } }];
    }

    case "SetHideZeroQuantityRows": {
      return [
        state,
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          UserSettings.setHideZeroQuantityRows(
            sharedState.userSettings,
            action.hideZeroQuantityRows,
            action.categoryName
          )
        ),
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
