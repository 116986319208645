import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { gql } from "graphql-tag";
import { SharedState, Routes, graphQLQueryWithAuth } from "@revit-order/client-infra";
import * as GQLOps from "../../../../generated/generated-operations";
import * as ProjectState from "../../project-state";

export interface State {
  readonly expandedItems: ReadonlySet<string>;
  readonly downloading: boolean;
  readonly response: GQLOps.Log_ProjectQuery | undefined;
}

export const Action = ctorsUnion({
  DownloadLog: () => ({}),
  DownloadLogResponse: (res: GQLOps.Log_ProjectQuery) => ({ res }),
  ToggleExpanded: (id: string) => ({ id }),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined,
  _projectState: ProjectState.State
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  // Always re-download to avoid having to sync with other project state changes
  return [{ downloading: false, response: undefined, expandedItems: prevState?.expandedItems || new Set() }];
}

export function update(
  action: Action,
  state: State,
  projectState: ProjectState.State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "DownloadLog": {
      if (state.downloading || projectState.projectIsBusy) {
        return [state];
      }
      const query = gql`
        query Log_project($projectId: ID!) {
          project(id: $projectId) {
            id
            materialLists {
              id
              sortNo
              name
              changes {
                importLog {
                  fileName
                  logItems
                }
                zoomRuleLogItems
                packageRuleLogItems
              }
            }
          }
        }
      `;
      const input: GQLOps.Log_ProjectQueryVariables = {
        projectId: projectState.project.id,
      };
      const cmd = graphQLQueryWithAuth(sharedState.activeUser)<
        GQLOps.Log_ProjectQuery,
        GQLOps.Log_ProjectQueryVariables,
        Action
      >(query, input, sharedState.market.name, (res) => Action.DownloadLogResponse(res));
      return [{ ...state, downloading: true, response: undefined }, cmd];
    }
    case "DownloadLogResponse": {
      return [{ ...state, downloading: false, response: action.res }];
    }
    case "ToggleExpanded": {
      const newExpanded = new Set<string>(state.expandedItems);
      if (newExpanded.has(action.id)) {
        newExpanded.delete(action.id);
      } else {
        newExpanded.add(action.id);
      }
      return [{ ...state, expandedItems: newExpanded }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
