import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@revit-order/client-infra";
import { Project, Texts, UserSettings } from "@revit-order/shared";
import { ExportResponse } from "@revit-order/shared/src/crm";
import * as State from "./state";
import * as ProjectState from "../../project-state";
import { Action } from "./state";
import { Textfield, Alert, Button, ConfirmRemoveButton, Icon, Spinner, Toolbar, withTw } from "../../../../elements";

const FormRow = withTw("div", "flex flex-row justify-between w-340 items-center h-36");
const FormContainer = withTw("div", "flex flex-col space-y-8");

const texts = Texts.texts;

const ProjectAction = ProjectState.Action;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly crmExportResponse: ExportResponse | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, projectState, sharedState, crmExportResponse, dispatch, dispatchProject } = props;
  const { project, projectIsBusy } = projectState;
  const translate = sharedState.translate;
  const isReadonly = Project.isProjectReadOnly(project);

  const hasMaterials = project.materialLists.some((l) => l.materials.length > 0);

  return (
    <div className="flex flex-col space-y-16">
      <Toolbar>
        <ConfirmRemoveButton
          disabled={Project.isProjectReadOnly(project)}
          regularButton={true}
          onClick={() => dispatchProject(ProjectAction.RemoveProject())}
          confirmMessage={translate(texts.confirm_delete_project)}
          removeText={translate(texts.remove_project)}
          cancelText={translate(Texts.texts.cancel)}
          iconMessage={translate(texts.remove_project)}
        />

        <Button
          type="secondary"
          disabled={projectIsBusy}
          onClick={() => dispatchProject(ProjectAction.DuplicateProject())}
          label={translate(texts.duplicate_project)}
        />

        {!!sharedState.crmParams && (
          <Button
            type="secondary"
            label={translate(texts.export_to_crm)}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(false))}
            disabled={projectIsBusy || !hasMaterials}
          />
        )}

        {!!sharedState.crmParams && UserSettings.selectShowAdvanced(sharedState.userSettings) && (
          <Button
            type="secondary"
            label={"Download CRM envelope"}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(true))}
            disabled={projectIsBusy || !hasMaterials}
          />
        )}

        {project.locked && (
          <Button
            type={"secondary"}
            disabled={!project.owned}
            label={translate(texts.unlock_project)}
            onClick={() => dispatch(Action.UnlockProject("confirm"))}
          />
        )}

        {!project.locked && (
          <Button
            type={"secondary"}
            disabled={!project.owned}
            label={translate(texts.lock_project)}
            onClick={() => {
              dispatchProject(ProjectState.Action.UpdateProjectLockState(true));
            }}
          />
        )}

        {projectIsBusy ? <Spinner /> : undefined}
      </Toolbar>

      {state.confirmUnlock ? (
        <Alert type="danger">
          <span>{translate(texts.unlock_project_note)}</span>
          <Toolbar align="right">
            <Button
              type="danger"
              label={translate(texts.unlock_project)}
              onClick={() => {
                dispatch(Action.UnlockProject("done"));
                dispatchProject(ProjectState.Action.UpdateProjectLockState(false));
              }}
            />
            <Button
              type="secondary"
              label={translate(texts.cancel)}
              onClick={() => dispatch(Action.UnlockProject("done"))}
            />
          </Toolbar>
        </Alert>
      ) : undefined}

      {crmExportResponse && crmExportResponse.status === 200 ? (
        <Alert type="success">
          <span>{translate(texts.sucessfully_exported_to_crm)}</span>
        </Alert>
      ) : crmExportResponse && crmExportResponse.status !== 200 ? (
        <Alert type="danger">
          <p>{translate(texts.failed_to_export_crm_message)}</p>
          <p>{crmExportResponse.message}</p>
        </Alert>
      ) : undefined}

      <FormContainer>
        <FormRow>
          <FormLabel label={translate(texts.name)} />
          <Textfield
            value={project.name || ""}
            readOnly={isReadonly}
            onChange={(v) => dispatchProject(ProjectState.Action.UpdateProject({ name: v }))}
            debounce={true}
            debounceTime={500}
          />
        </FormRow>
      </FormContainer>
    </div>
  );
}

function FormLabel({ label, info }: { readonly label: string; readonly info?: string }): JSX.Element {
  return (
    <div className="flex items-center">
      <span>{label}</span>
      {info && <Icon className="ml-4" icon="info-circle" message={info} />}
    </div>
  );
}
