import * as R from "ramda";
import { v4 as uuid } from "uuid";
import { Project } from "..";
import { MaterialTables } from "../material-list";
import { createErrorMsg, LogItem, Message, ParseResult, XlsxRow } from "./types";

export function parseXlsxDuctInsulationSchedule(
  materialTables: MaterialTables,
  rows: ReadonlyArray<XlsxRow>
): ParseResult {
  const messages: Array<Message> = [];
  const log: Array<LogItem> = [];

  const insulationPieces: Array<{
    readonly lengthMm: number;
    readonly insulation: MaterialTables["tables"]["insulationTable"][number];
  }> = [];
  const numHeaderRows = 2;
  for (let i = numHeaderRows; i < rows.length; i++) {
    const [ductSizeValue, lengthValue, thicknessValue] = rows[i].slice(1);
    if (ductSizeValue === undefined && lengthValue === undefined && thicknessValue === undefined) {
      continue;
    }

    const lengthM = parseNumberWithUnit(lengthValue);
    if (lengthM === undefined) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_length", { row: i + 1 }));
      continue;
    }
    const lengthMm = lengthM * 1000;
    if (lengthMm < 0.0001) {
      continue;
    }

    const ductSizeMm = parseNumber(ductSizeValue);
    if (ductSizeMm === undefined) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_duct_size", { row: i + 1 }));
      continue;
    }

    const thicknessMm = parseNumberWithUnit(thicknessValue);
    if (thicknessMm === undefined) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_insulation_thickness", { row: i + 1 }));
      continue;
    }

    const insulations = materialTables.tables.insulationTable.filter(
      (row) => row.diameter === ductSizeMm && row.insulation_thickness === thicknessMm
    );
    if (insulations.length === 0) {
      messages.push(
        createErrorMsg("xlsx_import_no_insulation_data_found", { duct_size: ductSizeMm, thickness: thicknessMm })
      );
      continue;
    }

    insulationPieces.push(...insulations.map((insulation) => ({ lengthMm, insulation })));
  }

  const materials = [];
  const groupedByItemNumber = R.values(R.groupBy((piece) => piece.insulation.item_number || "", insulationPieces));
  for (const group of groupedByItemNumber) {
    const { quantity, item_number, diameter, insulation_thickness } = group[0].insulation;
    if (quantity === null || !Number.isFinite(quantity) || !item_number) {
      messages.push(createErrorMsg("xlsx_import_insulation_table_data_error"));
      continue;
    }
    const lengthSumMm = group.reduce((sofar, piece) => sofar + piece.lengthMm, 0);
    const quantityCalculated = lengthSumMm / quantity;
    const quantityRounded = Math.ceil(quantityCalculated);
    materials.push(
      Project.createMaterial(uuid(), "standard", materialTables.sortNos.get(item_number), item_number, quantityRounded)
    );
    log.push({
      type: "insulation_schedule",
      ductSize: diameter || 0,
      thickness: insulation_thickness || 0,
      totalLengthMm: lengthSumMm,
      itemNumber: item_number,
      lengthPerItemMm: quantity,
      quantityCalculated: quantityCalculated,
      quantityAdded: quantityRounded,
    });
  }

  return {
    materials: materials,
    messages,
    log,
  };
}

const numberRe = /^[\d.]*/;
function parseNumberWithUnit(numberWithUnit: string | number | undefined): number | undefined {
  const match = (numberRe.exec(numberWithUnit?.toString() || "") || [])[0];
  if (!match) {
    return undefined;
  }
  const num = Number.parseFloat(match);
  if (!Number.isFinite(num)) {
    return undefined;
  }
  return num;
}

function parseNumber(value: string | number | undefined): number | undefined {
  if (typeof value === "number") {
    return Number.isFinite(value) ? value : undefined;
  } else if (typeof value === "string") {
    const float = Number.parseFloat(value);
    return Number.isFinite(float) ? float : undefined;
  } else {
    return undefined;
  }
}
