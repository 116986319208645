import { Texts } from "@revit-order/shared";
import { texts } from "@revit-order/shared/src/lang-texts";
import * as React from "react";
import { Icon, Spinner, withTw } from ".";

export interface BrowseFilesProps {
  readonly translate: Texts.TranslateFn;
  readonly onFiles?: (files: ReadonlyArray<File>) => void;
  readonly accept?: string;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly showSpinner?: boolean;
}

export type StyledLabelProps = {
  readonly disabled: boolean;
};

export const StyledLabel = withTw(
  "label",
  "cursor-pointer btn btn-primary text-xs btn-tiny",
  ({ disabled }: StyledLabelProps) => (disabled ? "btn-disabled" : "")
);

export function BrowseFiles({
  translate,
  onFiles,
  accept,
  disabled = false,
  className,
  showSpinner,
}: BrowseFilesProps): JSX.Element {
  const msgParts = translate(texts.drag_and_drop_files_note("__button__"))
    .split("__button__")
    .map((p) => p.trim());
  return (
    <div
      className={
        "flex-col space-y-32 text-sm text-center rounded-8 text-gray-400 p-32 border-dotted border-4 border-gray-200 bg-gray-50" +
        (className || "")
      }
      onDragOver={ (ev) => ev.preventDefault()}
      onDrop={(ev) => {
        ev.preventDefault();
        if (disabled) {
          return;
        }
        const items = ev.dataTransfer.items;
        const files: Array<File> = [];
        for (let i = 0; i < Math.min(10, items.length || 0); i++) {
          const item = items[i];
          if (item.kind === "file") {
            const file = item.getAsFile();
            if (file) {
              files.push(file);
            }
          }
        }
        if (files.length > 0 && onFiles) {
          onFiles(files);
        }
      }}
    >
      <Icon icon="upload" size="4x" />
      <div className="flex-row space-x-8 text-center h-32">
        {showSpinner ? (
          <Spinner debounce={false} />
        ) : (
          <>
            <span>{msgParts[0]}</span>
            <input
              id="browse_files"
              type="file"
              multiple
              accept={accept}
              hidden
              disabled={disabled}
              onChange={(e) => {
                const files: Array<File> = [];
                for (let i = 0; i < (e.target?.files?.length || 0); i++) {
                  const file = e.target.files?.item(i);
                  if (file) {
                    files.push(file);
                  }
                }
                if (files.length > 0 && onFiles) {
                  onFiles(files);
                }
              }}
            />
            <StyledLabel htmlFor="browse_files" disabled={disabled}>
              <span className="whitespace-nowrap">{translate(texts.browse_files)}</span>
            </StyledLabel>
            <span>{msgParts[1]}</span>
          </>
        )}
      </div>
    </div>
  );
}
