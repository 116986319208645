import React from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Dispatch } from "@typescript-tea/core";
import { Routes, SharedState } from "@revit-order/client-infra";
import { Texts, Project } from "@revit-order/shared";
import { TranslateFn } from "@revit-order/shared/src/lang-texts";
import * as GQLOps from "../../generated/generated-operations";
import * as State from "./state";
import { Action, SortOrder } from "./state";
import { withTw, Button, LinkButton, ConfirmRemoveButton, Spinner, Icon } from "../../elements";
import { TabButton } from "../../elements/tab-button";
import { Textfield } from "../../elements/text-field";

const ThLeft = withTw("th", "text-left pr-20 select-none items-center cursor-pointer max-h-max");

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const { translate } = sharedState;
  const projectList = state.projectListResponse?.projectList;

  return (
    <div>
      <Button
        className="mb-20 mt-8 btn-nav.active"
        disabled={state.isCreatingProject}
        label={translate(Texts.texts.new_project)}
        onClick={() => {
          dispatch(Action.CreateNewProject());
        }}
      />
      <nav className="mb-20">
        <TabButton
          active={state.location.type === "ProjectList"}
          label={translate(Texts.texts.my_projects)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(Routes.MainLocation.ProjectList(Routes.ProjectListLocation.ProjectList()))
          )}
        />
        <TabButton
          active={state.location.type === "AllProjectsList"}
          label={translate(Texts.texts.all_projects)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.ProjectList(Routes.ProjectListLocation.AllProjectsList())
            )
          )}
        />
      </nav>
      <ProjectList
        projects={projectList?.projects}
        translate={translate}
        activeProjectList={state.location}
        dispatch={dispatch}
        sortOrder={state.sortOrder}
        searchProjectList={state.searchProjectList}
        disableButtons={state.isCreatingProject}
      />
      {projectList && projectList.pages.length > 1 ? (
        <Pager
          current={projectList.currentPage}
          prev={projectList.previousPage || undefined}
          next={projectList.nextPage || undefined}
          pages={projectList.pages}
          translate={translate}
          dispatch={dispatch}
        />
      ) : undefined}
    </div>
  );
}

function Pager({
  current,
  prev,
  next,
  pages,
  translate,
  dispatch,
}: {
  readonly current: GQLOps.ProjectList_PageFragment;
  readonly prev: GQLOps.ProjectList_PageFragment | undefined;
  readonly next: GQLOps.ProjectList_PageFragment | undefined;
  readonly pages: ReadonlyArray<GQLOps.ProjectList_PageFragment>;
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
}): JSX.Element {
  return (
    <div className="flex flex-row mt-16 space-x-8">
      <LinkButton
        key={"prev"}
        label={translate(Texts.texts.previous)}
        disabled={!prev}
        onClick={() => dispatch(Action.GotoPage(prev?.cursor || ""))}
      />
      {pages.map((page, pageIndex) => {
        const prevPageIndex = pageIndex > 0 ? pages[pageIndex - 1].page : undefined;
        return (
          <React.Fragment key={page.page}>
            {prevPageIndex !== undefined && page.page !== prevPageIndex + 1 ? (
              <span className={"select-none"}>…</span>
            ) : undefined}
            <LinkButton
              active={page.page === current.page}
              label={page.page.toString()}
              onClick={() => dispatch(Action.GotoPage(page.cursor))}
              extraPadding={true}
            />
          </React.Fragment>
        );
      })}
      <LinkButton
        key={"next"}
        label={translate(Texts.texts.next)}
        disabled={!next}
        onClick={() => dispatch(Action.GotoPage(next?.cursor || ""))}
      />
    </div>
  );
}

function ProjectList({
  projects,
  translate,
  activeProjectList,
  dispatch,
  sortOrder,
  searchProjectList,
  disableButtons,
}: {
  readonly projects: ReadonlyArray<GQLOps.ProjectList_ProjectFragment> | undefined;
  readonly translate: Texts.TranslateFn;
  readonly activeProjectList: Routes.ProjectListLocation;
  readonly dispatch: Dispatch<State.Action>;
  readonly sortOrder: SortOrder;
  readonly searchProjectList: string | undefined;
  readonly disableButtons: boolean;
}): JSX.Element {
  let columns: string[] = [];

  switch (activeProjectList.type) {
    case "ProjectList":
      columns = ["name", "created", "last-edited", "locked", "project-buttons"];
      break;

    case "AllProjectsList":
      columns = ["name", "created", "last-edited", "created-by", "locked", "project-buttons"];
      break;

    default:
      break;
  }

  if (!projects) {
    return <Spinner />;
  }

  const tableRowHeaders = [];
  for (const column of columns) {
    const columnData = getColumnData(translate, column);
    const sortOrderIsASC = sortOrder.value === "ASC";
    if (!columnData) {
      tableRowHeaders.push(<th key={column} />);
    } else {
      tableRowHeaders.push(
        <ThLeft
          key={column}
          className={columnData.className}
          onClick={() => dispatch(Action.SortOrder(columnData.key, sortOrderIsASC ? "DESC" : "ASC"))}
        >
          {columnData.text}
          {sortOrder.key === columnData.key ? (
            <>
              <Icon
                icon={sortOrderIsASC ? columnData.iconASC : columnData.iconDESC}
                className="mx-8 h-12 w-12 inline-block"
              />
              {sortOrderIsASC ? "(asc)" : "(desc)"}
            </>
          ) : null}
        </ThLeft>
      );
    }
  }

  const emptyColumnIsNeeded = tableRowHeaders.length === columns.length;
  const searchColSpan = Math.max(tableRowHeaders.length - columns.length, 1);
  tableRowHeaders.push(
    <td key="search" colSpan={searchColSpan}>
      <Textfield
        className="max-w-340"
        value={searchProjectList || ""}
        onChange={(input) => dispatch(Action.SearchProjectList(input))}
        debounce={true}
        debounceTime={700}
        placeholder={translate(Texts.texts.search)}
      />
    </td>
  );

  const tableBody: JSX.Element[] = [];

  projects.forEach((p) => {
    const rowColumns = [];
    for (const column of columns) {
      switch (column) {
        case "name":
          rowColumns.push(
            <td key={column}>
              <a
                href={Routes.buildUrl(
                  Routes.RootLocation.MainLocation(Routes.MainLocation.Project(p.id, Routes.ProjectLocation.General()))
                )}
              >
                {p.name || translate(Texts.texts.no_name)}
              </a>
            </td>
          );
          break;
        case "created":
          rowColumns.push(<td key={column}>{new Date(p.created!).toLocaleString()}</td>);
          break;
        case "created-by":
          rowColumns.push(<td key={column}>{p.owner}</td>);
          break;
        case "last-edited":
          rowColumns.push(<td key={column}>{new Date(p.edited!).toLocaleString()}</td>);
          break;
        case "locked":
          rowColumns.push(<td key={column}>{p.locked ? translate(Texts.texts.locked) : ""}</td>);
          break;
        case "project-buttons":
          rowColumns.push(
            <td key={column}>
              <div className="flex flex-row space-x-16">
                <Icon
                  icon="copy"
                  disabled={disableButtons}
                  onClick={() => dispatch(Action.DuplicateProject(p.name, p.id))}
                  message={translate(Texts.texts.duplicate_project_note)}
                />
                {p.owned && (
                  <ConfirmRemoveButton
                    disabled={disableButtons || Project.isProjectReadOnly(p)}
                    onClick={() => dispatch(Action.RemoveProject(p.id))}
                    confirmMessage={translate(Texts.texts.confirm_delete_project)}
                    removeText={translate(Texts.texts.remove_project)}
                    cancelText={translate(Texts.texts.cancel)}
                    iconMessage={translate(Texts.texts.remove_project)}
                  />
                )}
              </div>
            </td>
          );
          break;
        case "permissions":
          rowColumns.push(
            <td key={column}>{translate(Texts.texts.permission_name(p.permissions as Project.Permissions))}</td>
          );
          break;

        default:
          rowColumns.push(<td key={column}></td>);
      }
    }
    if (emptyColumnIsNeeded) {
      rowColumns.push(<td key="search" />);
    }
    tableBody.push(<tr key={p.id}>{...rowColumns}</tr>);
  });

  return (
    <table>
      <thead>
        <tr>{...tableRowHeaders}</tr>
      </thead>
      <tbody>
        {projects.length !== 0 ? (
          tableBody
        ) : (
          <tr>
            <td>{translate(Texts.texts.no_projects_found)}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function getColumnData(
  translate: TranslateFn,
  column: string
):
  | {
      readonly key: SortOrder["key"];
      readonly className: string;
      readonly text: string;
      readonly iconASC: IconName;
      readonly iconDESC: IconName;
    }
  | undefined {
  switch (column) {
    case "name":
      return {
        key: "NAME",
        className: "w-1/4",
        text: translate(Texts.texts.name),
        iconASC: "sort-alpha-up",
        iconDESC: "sort-alpha-down-alt",
      };
    case "created":
      return {
        key: "CREATED",
        className: "w-1/6",
        text: translate(Texts.texts.created),
        iconASC: "sort-numeric-up",
        iconDESC: "sort-numeric-down-alt",
      };
    case "created-by":
      return {
        key: "CREATED BY",
        className: "w-1/6",
        text: translate(Texts.texts.created_by),
        iconASC: "sort-alpha-up",
        iconDESC: "sort-alpha-down-alt",
      };
    case "last-edited":
      return {
        key: "LAST EDITED",
        className: "w-1/6",
        text: translate(Texts.texts.last_edited),
        iconASC: "sort-numeric-up",
        iconDESC: "sort-numeric-down-alt",
      };
    case "locked":
      return {
        key: "LOCKED",
        className: "w-1/16",
        text: translate(Texts.texts.locked),
        iconASC: "sort-amount-up-alt",
        iconDESC: "sort-amount-down",
      };
    case "permissions":
      return {
        key: "PERMISSIONS",
        className: "w-1/16",
        text: translate(Texts.texts.permissions),
        iconASC: "sort-amount-up-alt",
        iconDESC: "sort-amount-down",
      };
    default:
      return undefined;
  }
}
