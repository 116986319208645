import gql from "graphql-tag";

export const schema = gql`
  extend type Query {
    project(id: ID!): Project
    projectStatus(id: ID!): ProjectStatus
  }

  extend type Mutation {
    createProject(input: CreateProjectInput!): CreateProjectPayload!
    removeProject(input: RemoveProjectInput!): RemoveProjectPayload!
    updateProject(input: UpdateProjectInput!): UpdateProjectPayload!
    duplicateProject(input: DuplicateProjectInput!): DuplicateProjectPayload!
    updateProjectLockState(input: UpdateProjectLockStateInput!): UpdateProjectLockStatePayload!
  }

  type ProjectStatus {
    id: ID!
    exists: Boolean!
    access: Boolean!
  }

  type Project {
    id: ID!
    name: String
    locked: Boolean!
    owned: Boolean!
    permissions: String!
    created: Date
    edited: Date
    owner: String
    materialLists: [MaterialList!]!
    materialFiles: MaterialFiles
  }

  input CreateProjectInput {
    id: ID!
  }

  type CreateProjectPayload {
    project: Project!
  }

  input RemoveProjectInput {
    id: ID!
  }

  type RemoveProjectPayload {
    id: ID!
  }

  input UpdateProjectInput {
    id: ID!
    name: String
  }

  type UpdateProjectPayload {
    id: ID!
  }

  input DuplicateProjectInput {
    oldId: ID!
    newId: ID!
    newName: String
  }

  type DuplicateProjectPayload {
    id: ID!
  }

  input UpdateProjectLockStateInput {
    id: ID!
    locked: Boolean!
  }

  type UpdateProjectLockStatePayload {
    id: ID!
    locked: Boolean!
    permissions: String!
  }
`;
