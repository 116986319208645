import { Permissions } from "../project";
import { KeyParams } from "./normalized-text";

export type TextTypes = TextKey | Dummy;

export type TextKey = {
  readonly type: "TextKey";
  readonly key: string;
  readonly params: KeyParams;
};

export type Dummy = {
  readonly type: "Dummy";
  readonly key: string;
  readonly params: KeyParams;
};

export const key = (key: string, params: KeyParams = {}): TextKey => ({
  type: "TextKey",
  key,
  params,
});

export type TextKeyName = keyof typeof texts;

export const texts = {
  show_advanced: key("show_advanced"),
  air_flow: key("air_flow"),
  project_list: key("project_list"),
  project: key("project"),
  logout: key("logout"),
  not_found_404: key("not_found_404"),
  unlock_project: key("unlock_project"),
  unlock_project_note: key("unlock_project_note"),
  cancel: key("cancel"),
  name: key("name"),
  remove_project: key("remove_project"),
  lock_project: key("lock_project"),
  project_created: key("project_created"),
  permissions: key("permissions"),
  confirm_delete_project: key("confirm_delete_project"),
  new_project: key("new_project"),
  my_projects: key("my_projects"),
  all_projects: key("all_projects"),
  search: key("search"),
  no_projects_found: key("no_projects_found"),
  locked: key("locked"),
  no_name: key("no_name"),
  user_settings: key("user_settings"),
  true: key("true"),
  false: key("false"),
  read: key("read"),
  write: key("write"),
  permission_name: (permission: Permissions) => key(`permission_${permission}`),
  next: key("next"),
  previous: key("previous"),
  save: key("save"),
  general: key("general"),
  material_files: key("material_files"),
  material_list: key("material_list"),
  position: key("position"),
  pos: key("pos"),
  item_number: key("item_number"),
  quantity: key("quantity"),
  margin: key("margin"),
  material_list_empty: key("material_list_empty"),
  created: key("created"),
  created_by: key("created_by"),
  last_edited: key("last_edited"),
  go_back: key("go_back"),
  go_back_to_project_list: key("go_back_to_project_list"),
  material_category: (categoryName: string) => key(`material_category_${categoryName}`),
  sucessfully_exported_to_crm: key("sucessfully_exported_to_crm"),
  failed_to_export_crm_message: key("failed_to_export_crm_message"),
  export_to_crm: key("export_to_crm"),
  project_delete_message: key("project_delete_message"),
  project_no_access_message: key("project_no_access_message"),
  project_not_found: key("project_not_found"),
  too_many_selections_message: key("too_many_selections_message"),
  itemName: (itemNumber: string) => key(`itemName_${itemNumber}`),
  itemDescription: (itemNumber: string) => key(`itemDescription_${itemNumber}`),
  item_number_required: key("item_number_required"),
  item_has_been_removed: key("item_has_been_removed"),
  new_items_available: key("new_items_available"),
  display_product_images: key("display_product_images"),
  upload_files: key("upload_files"),
  file_type: key("file_type"),
  file_name: key("file_name"),
  files_required_upload_message: key("files_required_upload_message"),
  drag_and_drop_files_note: (buttonTag: string) => key(`drag_and_drop_files_note`, { browse_button: buttonTag }),
  browse_files: key("browse_files"),
  current_files: key("current_files"),
  download_file: key("download_file"),
  file_type_name: (fileType: string) => key(`file_type_name_${fileType}`),
  not_added: key("not_added"),
  unknown_file_type: key("unknown_file_type"),
  download_all_files: key("download_all_files"),
  xlsx_import_messages_header: key(`xlsx_import_messages_header`),
  xlsx_zoom_log_header: key(`xlsx_zoom_log_header`),
  xlsx_import_success: key("xlsx_import_success"),
  xlsx_import_failed: key("xlsx_import_failed"),
  xlsx_import_not_a_valid_quantity: (row: string) => key("xlsx_import_not_a_valid_quantity", { row }),
  xlsx_import_no_materials_imported: key("xlsx_import_no_materials_imported"),
  xlsx_import_missing_files: key("xlsx_import_missing_files"),
  xlsx_import_not_a_valid_duct_size: (row: string) => key("xlsx_import_not_a_valid_duct_size", { row }),
  xlsx_import_not_a_valid_length: (row: string) => key("xlsx_import_not_a_valid_length", { row }),
  xlsx_import_not_a_valid_insulation_thickness: (row: string) =>
    key("xlsx_import_not_a_valid_insulation_thickness", { row }),
  xlsx_import_no_insulation_data_found: (ductSize: string, thickness: string) =>
    key("xlsx_import_no_insulation_data_found", { duct_size: ductSize, thickness }),
  xlsx_import_insulation_table_data_error: key("xlsx_import_insulation_table_data_error"),
  xlsx_import_not_a_valid_diameter: (row: string) => key("xlsx_import_not_a_valid_diameter", { row }),
  xlsx_import_no_duct_data_found: (diameter: string) => key("xlsx_import_no_duct_data_found", { diameter }),
  xlsx_import_duct_table_data_error: key("xlsx_import_duct_table_data_error"),
  xlsx_import_room_rule_table_data_error: key("xlsx_import_room_rule_table_data_error"),
  xlsx_import_room_rule_applied: (itemNumber: string, diameter: string) =>
    key("xlsx_import_room_rule_applied", { item_number: itemNumber, diameter }),
  rename_material_list: key("rename_material_list"),
  confirm_delete_material_list: key("confirm_delete_material_list"),
  remove_material_list: key("remove_material_list"),
  duplicate_material_list: key("duplicate_material_list"),
  add_custom_item: key("add_custom_item"),
  copy_of_project: (projectName: string) => key("copy_of_project", { project_name: projectName }),
  duplicate_project: key("duplicate_project"),
  duplicate_project_note: key("duplicate_project_note"),
  confirm_reset_material_list: key("confirm_reset_material_list"),
  reset_material_list: key("reset_material_list"),
  hide_zero_quantity_rows: key("hide_zero_quantity_rows"),
  expand_all_categories: key("expand_all_categories"),
  collapse_all_categories: key("collapse_all_categories"),
  enter_quantity_multiplier: key("enter_quantity_multiplier"),
  multiply: key("multiply"),
  multiply_quantities: key("multiply_quantities"),
  add_new_items: key("add_new_items"),
  enable_zoom_rule: key("enable_zoom_rule"),
  enable_zoom_rule_import_note: key("enable_zoom_rule_import_note"),
  import_log: key("import_log"),
  applied_zoom_rule: key("applied_zoom_rule"),
  quantity_added: key("quantity_added"),
  duct_size: key("duct_size"),
  thickness: key("thickness"),
  total_length_in_xlsx_mm: key("total_length_in_xlsx_mm"),
  length_per_item_mm: key("length_per_item_mm"),
  calculated_quantity: key("calculated_quantity"),
  added_quantity: key("added_quantity"),
  no_zoom_rules_has_been_applied: key("no_zoom_rules_has_been_applied"),
  duct_item: key("duct_item"),
  item_number_changed: key("item_number_changed"),
  size: key("size"),
  rule_adjustment: key("rule_adjustment"),
  quantity_before: key("quantity_before"),
  quantity_after: key("quantity_after"),
  duct_size_heading: (ductSize: number) => key("duct_size_heading", { duct_size: ductSize.toString() }),
  zoom_rule_changes: key("zoom_rule_changes"),
  remove_custom_material: key("remove_custom_material"),
  apply_zoom_rule: key("apply_zoom_rule"),
  zoom_rule_item_note: key("zoom_rule_item_note"),
  quantity_calculations: key("quantity_calculations"),
  duct_package: key("duct_package"),
  duct_package_rule_not_applied: key("duct_package_rule_not_applied"),
  duct_package_info: key("duct_package_info"),
  duct_package_diameters: key("duct_package_diameters"),
  ducts_per_package: key("ducts_per_package"),
};
