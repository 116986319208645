import * as R from "ramda";
import { v4 as uuid } from "uuid";
import { Project } from "..";
import { MaterialTables } from "../material-list/types";
import { createErrorMsg, LogItem, Message, ParseResult, XlsxRow } from "./types";

interface XlsxDuctRow {
  readonly diameterMm: number;
  readonly lengthMm: number;
  readonly quantity: number;
}

export function parseXlsxDuctSchedule(materialTables: MaterialTables, rows: ReadonlyArray<XlsxRow>): ParseResult {
  const messages: Array<Message> = [];

  const xlsxDuctRows: Array<XlsxDuctRow> = [];
  const numHeaderRows = 2;
  for (let i = numHeaderRows; i < rows.length; i++) {
    const [diameterMm, lengthMm, maybeQuantity] = rows[i];
    if (diameterMm === undefined && lengthMm === undefined) {
      continue;
    }
    if (typeof diameterMm !== "number" || !Number.isFinite(diameterMm)) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_diameter", { row: i + 1 }));
      continue;
    }
    if (typeof lengthMm !== "number" || !Number.isFinite(lengthMm)) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_length", { row: i + 1 }));
      continue;
    }
    // Default to 1 to be backwards compatible
    const quantity = maybeQuantity === undefined ? 1 : maybeQuantity;
    if (typeof quantity !== "number" || !Number.isFinite(quantity)) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_quantity", { row: i + 1 }));
      continue;
    }
    xlsxDuctRows.push({ diameterMm, lengthMm, quantity });
  }

  const replaceResult = importDuctReplaceRule(materialTables, xlsxDuctRows);
  const standardResult = importDuctStandardRule(materialTables, replaceResult.remainingRows);

  const materials = [...replaceResult.materials, ...standardResult.materials];
  messages.push(...replaceResult.messages, ...standardResult.messages);
  const log = [...replaceResult.log, ...standardResult.log];

  return {
    materials: materials,
    messages,
    log,
  };
}

function importDuctReplaceRule(
  materialTables: MaterialTables,
  rows: ReadonlyArray<XlsxDuctRow>
): {
  readonly materials: ReadonlyArray<Project.Material>;
  readonly messages: ReadonlyArray<Message>;
  readonly log: ReadonlyArray<LogItem>;
  readonly remainingRows: ReadonlyArray<XlsxDuctRow>;
} {
  const remainingRows = [];
  const ducts = [];
  for (const row of rows) {
    const { diameterMm, lengthMm, quantity } = row;
    const duct = materialTables.tables.ductTable.find(
      (r) => r.item_number && r.diameter === diameterMm && r.quantity === lengthMm && r.import_rule === "short_duct"
    );
    if (!duct) {
      remainingRows.push(row);
      continue;
    }
    ducts.push({ itemNumber: duct.item_number!, diameterMm, lengthMm, quantity });
  }

  const log: Array<LogItem> = [];
  const materials = [];
  const groupedByItemNumber = R.values(R.groupBy((d) => d.itemNumber, ducts));
  for (const group of groupedByItemNumber) {
    const { itemNumber, diameterMm, lengthMm } = group[0];
    const quantity = group.reduce((sofar, d) => sofar + d.quantity, 0);
    materials.push(
      Project.createMaterial(uuid(), "standard", materialTables.sortNos.get(itemNumber), itemNumber, quantity)
    );
    log.push({
      type: "duct_schedule",
      ductSize: diameterMm,
      totalLengthMm: lengthMm * quantity,
      itemNumber: itemNumber,
      lengthPerItemMm: lengthMm,
      quantityCalculated: quantity,
      quantityAdded: quantity,
    });
  }

  return {
    materials,
    messages: [],
    log,
    remainingRows,
  };
}

function importDuctStandardRule(
  materialTables: MaterialTables,
  rows: ReadonlyArray<XlsxDuctRow>
): {
  readonly materials: ReadonlyArray<Project.Material>;
  readonly messages: ReadonlyArray<Message>;
  readonly log: ReadonlyArray<LogItem>;
} {
  const messages: Array<Message> = [];
  const log: Array<LogItem> = [];

  const ductPieces: Array<{
    readonly lengthMm: number;
    readonly duct: MaterialTables["tables"]["ductTable"][number];
    readonly pieceQuantity: number;
  }> = [];
  for (const { diameterMm, lengthMm, quantity } of rows) {
    const ducts = materialTables.tables.ductTable.filter(
      (row) => row.diameter === diameterMm && row.import_rule === "regular_duct"
    );
    if (ducts.length === 0) {
      messages.push(createErrorMsg("xlsx_import_no_duct_data_found", { diameter: diameterMm }));
      continue;
    }

    ductPieces.push(...ducts.map((duct) => ({ lengthMm: lengthMm, duct, pieceQuantity: quantity })));
  }

  const materials = [];
  const groupedByItemNumber = R.values(R.groupBy((piece) => piece.duct.item_number || "", ductPieces));
  for (const group of groupedByItemNumber) {
    const { diameter, quantity, item_number } = group[0].duct;
    if (
      quantity === null ||
      !Number.isFinite(quantity) ||
      !item_number ||
      diameter === null ||
      !Number.isFinite(diameter)
    ) {
      messages.push(createErrorMsg("xlsx_import_duct_table_data_error"));
      continue;
    }
    const lengthSumMm = group.reduce((sofar, piece) => sofar + piece.lengthMm * piece.pieceQuantity, 0);
    const quantityCalculated = lengthSumMm / quantity;
    const quantityRounded = Math.ceil(quantityCalculated);
    materials.push(
      Project.createMaterial(uuid(), "standard", materialTables.sortNos.get(item_number), item_number, quantityRounded)
    );
    log.push({
      type: "duct_schedule",
      ductSize: diameter || 0,
      totalLengthMm: lengthSumMm,
      itemNumber: item_number,
      lengthPerItemMm: quantity,
      quantityCalculated: quantityCalculated,
      quantityAdded: quantityRounded,
    });
  }

  return {
    materials,
    log,
    messages,
  };
}
