import { v4 as uuid } from "uuid";
import { Project } from "..";
import { MaterialTables } from "../material-list/types";
import { createErrorMsg, createWarningMsg, LogItem, Message, ParseResult, XlsxRow } from "./types";

export function parseXlsxSimpleList(
  materialTables: MaterialTables,
  rows: ReadonlyArray<XlsxRow>,
  itemNumberColumns: ReadonlyArray<number>,
  quantityColumn: number
): ParseResult {
  const messages: Array<Message> = [];
  const materials: Array<Project.Material> = [];
  const log: Array<LogItem> = [];

  const numHeaderRows = 2;
  for (let i = numHeaderRows; i < rows.length; i++) {
    const row = rows[i];

    const quantity = row[quantityColumn];
    if (quantity === "" || quantity === undefined) {
      continue;
    }
    if (typeof quantity !== "number" || !Number.isFinite(quantity)) {
      messages.push(createErrorMsg("xlsx_import_not_a_valid_quantity", { row: i + 1 }));
      continue;
    }

    const itemNumbers = itemNumberColumns
      .map((col) => valueToItemNumber(row[col]))
      .filter((value) => value.length > 3) as ReadonlyArray<string>;
    if (itemNumbers.length === 0) {
      continue;
    }

    materials.push(
      ...itemNumbers.map((itemNumber) =>
        Project.createMaterial(uuid(), "standard", materialTables.sortNos.get(itemNumber), itemNumber, quantity)
      )
    );
    log.push(
      ...itemNumbers.map(
        (itemNumber): LogItem => ({
          type: "item_list",
          itemNumber: itemNumber,
          quantityAdded: quantity,
        })
      )
    );
  }

  if (materials.length === 0) {
    messages.push(createWarningMsg("xlsx_import_no_materials_imported"));
  }

  return {
    materials: materials,
    messages,
    log,
  };
}

function valueToItemNumber(value: number | string | undefined): string {
  if (typeof value === "number" && !Number.isFinite(value)) {
    return "";
  } else {
    return (value || "").toString();
  }
}
