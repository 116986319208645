import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@revit-order/client-infra";
import { selectShowAdvanced, selectShowImages } from "@revit-order/shared/src/user-settings";
import { texts } from "@revit-order/shared/src/lang-texts";
import { Checkbox, H2, Label } from "../../elements";
import * as State from "./state";
import { Action } from "./state";
// import { SharedStateAction } from "./state";

const SettingLabel = (props: { readonly text: string }): JSX.Element => {
  return (
    <div className="flex-grow">
      <Label>{props.text}</Label>
    </div>
  );
};

export function View(props: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const [clicks, setClicks] = React.useState(0);
  const translate = props.sharedState.translate;
  const userSettings = props.sharedState.userSettings;
  const dispatch = props.dispatch;
  return (
    <div>
      <div className="mb-32">
        <a className={"hover:cursor-pointer"} onClick={() => dispatch(Action.GoBack())}>
          {translate(texts.go_back)}
        </a>
      </div>

      <div>
        <H2 onClick={() => setClicks(clicks + 1)}>{translate(texts.user_settings)}</H2>
      </div>

      <div className="inline-flex flex-col space-y-16">
        <div className="inline-flex">
          <SettingLabel text={translate(texts.display_product_images)} />
          <Checkbox checked={selectShowImages(userSettings)} onChange={(val) => dispatch(Action.SetShowImages(val))} />
        </div>
        {clicks >= 7 && (
          <div className="inline-flex">
            <SettingLabel text={translate(texts.show_advanced)} />
            <Checkbox
              checked={selectShowAdvanced(userSettings)}
              onChange={(val) => dispatch(Action.SetShowAdvanced(val))}
            />
          </div>
        )}
      </div>
    </div>
  );
}
