import gql from "graphql-tag";
import { User } from "..";
import * as GqlOps from "../generated/generated-operations";
import { defaultLanguage, LanguageCode } from "../lang-texts";

export type MarketTable = GqlOps.MarketTableFragment["Market"];
export type MarketQuery = GqlOps.GetMarketsQuery;
export type MarketQueryVariables = GqlOps.GetMarketsQueryVariables;

export const marketModulesFragment = gql`
  fragment marketModules on Modules {
    custom_tables {
      ...marketTable
    }
  }

  fragment marketTable on Module_custom_tables {
    Market {
      market_name
      language
      order_type
      material_list_product
      shop_language_code
      m3_language_code
    }
  }
`;

export const marketQuery = gql`
  query getMarkets($productId: ID!) {
    product(id: $productId) {
      modules {
        ...marketModules
      }
    }
  }
  ${marketModulesFragment}
`;

export const defaultMarket: Market = {
  name: "Global",
  language: defaultLanguage,
  orderType: "",
  materialProductId: "e8969747-e3c6-4743-95a5-e6b429c466fb",
  shopLanguageCode: "en",
  m3LanguageCode: "EN",
};

export interface Market {
  readonly name: string;
  readonly language: LanguageCode;
  readonly orderType: string;
  readonly materialProductId: string;
  readonly shopLanguageCode: string;
  readonly m3LanguageCode: string;
}

export function getMarkets(marketTable: MarketTable, activeUser: User.ActiveUser): ReadonlyArray<Market> {
  const markets = marketTable
    .map(
      (m): Market => ({
        name: m.market_name || "",
        language: (m.language || defaultLanguage) as LanguageCode,
        orderType: m.order_type || "",
        materialProductId: m.material_list_product || "",
        shopLanguageCode: m.shop_language_code || "en",
        m3LanguageCode: m.m3_language_code || "EN",
      })
    )
    .filter((m) => !!m.name)
    .filter((m) => {
      const emailParts = activeUser.email.split("@");
      const emailHost = emailParts[emailParts.length - 1];
      if (m.name === "Norway" && !(emailHost === "divid.se" || emailHost === "systemair.no" || emailHost === "systemair.com")) {
        return false;
      } else {
        return true;
      }
    });
  if (markets.length > 0) {
    return markets;
  } else {
    return [defaultMarket];
  }
}

export function getMarketOrDefault(marketTable: MarketTable, marketName: string, activeUser: User.ActiveUser): Market {
  const markets = getMarkets(marketTable, activeUser);
  return markets.find((m) => m.name === marketName) || markets[0] || defaultMarket;
}

export function getMarket(
  marketTable: MarketTable,
  marketName: string,
  activeUser: User.ActiveUser
): Market | undefined {
  const markets = getMarkets(marketTable, activeUser);
  return markets.find((m) => m.name === marketName);
}
