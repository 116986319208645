export const allFileTypes = [
  "air_terminal_schedule" as const,
  "duct_accessory_schedule" as const,
  "duct_fitting_schedule" as const,
  "duct_insulation_schedule" as const,
  "duct_schedule" as const,
  "flex_duct_schedule" as const,
  "mechanical_equipment_schedule" as const,
];

export type MaterialFileType = typeof allFileTypes[number];

export function getFileType(fileName: string): MaterialFileType | undefined {
  const fileNameLc = fileName.toLocaleLowerCase();
  const extension = getExtension(fileNameLc);
  if (extension !== "xlsx" && extension !== "xls") {
    return undefined;
  }
  if (fileNameLc.includes("air terminal schedule")) {
    return "air_terminal_schedule";
  } else if (fileNameLc.includes("duct accessory schedule")) {
    return "duct_accessory_schedule";
  } else if (fileNameLc.includes("duct fitting schedule")) {
    return "duct_fitting_schedule";
  } else if (fileNameLc.includes("duct insulation schedule")) {
    return "duct_insulation_schedule";
  } else if (fileNameLc.includes("duct schedule") && !fileNameLc.includes("flex duct schedule")) {
    return "duct_schedule";
  } else if (fileNameLc.includes("flex duct schedule")) {
    return "flex_duct_schedule";
  } else if (fileNameLc.includes("mechanical equipment schedule")) {
    return "mechanical_equipment_schedule";
  } else {
    return undefined;
  }
}

export function getMime(format: string): string {
  if (format === "xlsx") {
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  } else if (format === "xls") {
    return "application/vnd.ms-excel";
  } else {
    return "application/octet-stream";
  }
}

function getExtension(fileName: string): string | undefined {
  const parts = fileName.split(".");
  if (parts.length < 2) {
    return undefined;
  } else {
    return parts.pop()!;
  }
}
