import { Cmd } from "@typescript-tea/core";
import { NavigationEffectManager, SharedState } from "@revit-order/client-infra";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Quantities, UnitTypes } from "@revit-order/shared/src/units";
import {
  setShowAdvanced,
  setUnit,
  setShowImages,
  setHideZeroQuantityRows,
} from "@revit-order/shared/src/user-settings";

export type State = {};

export function init(_sharedState: SharedState.SharedState): readonly [State, Cmd<Action>?] {
  return [{}];
}

export const Action = ctorsUnion({
  SetCodesVisibility: (showCodes: boolean) => ({ showCodes }),
  SetUnits: (quantity: Quantities, unit: Partial<UnitTypes["unitName"]>) => ({ quantity, unit }),
  GoBack: () => ({}),
  SetShowAdvanced: (showAdvanced: boolean) => ({ showAdvanced }),
  SetShowImages: (showImages: boolean) => ({ showImages }),
  SetHideZeroQuantityRows: (hideZeroQuantityRows: boolean, categoryNames: ReadonlyArray<string>) => ({
    hideZeroQuantityRows,
    categoryNames,
  }),
  Dummy: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "SetCodesVisibility": {
      //return [state, undefined, SharedState.SharedStateAction.SetShowCodes(action.showCodes)];
      return [state];
    }

    case "SetUnits": {
      return [
        state,
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          setUnit(sharedState.userSettings, action.quantity, action.unit)
        ),
      ];
    }

    case "GoBack": {
      return [state, NavigationEffectManager.back(1)];
    }

    case "SetShowAdvanced": {
      return [
        state,
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          setShowAdvanced(sharedState.userSettings, action.showAdvanced)
        ),
      ];
    }

    case "SetShowImages": {
      return [
        state,
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(setShowImages(sharedState.userSettings, action.showImages)),
      ];
    }

    case "SetHideZeroQuantityRows": {
      return [
        state,
        undefined,
        SharedState.SharedStateAction.UpdateUserSettings(
          setHideZeroQuantityRows(sharedState.userSettings, action.hideZeroQuantityRows, action.categoryNames)
        ),
      ];
    }

    case "Dummy": {
      //return [state, undefined, SharedState.SharedStateAction.SetShowCodes(false)];
      return [state];
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
