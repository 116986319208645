import * as R from "ramda";
import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@revit-order/client-infra";
import { ExportResponse } from "@revit-order/shared/src/crm";
import { Texts, MaterialList, MaterialListXlsxImport } from "@revit-order/shared";
import * as GQLOps from "../../../../generated/generated-operations";
import * as State from "./state";
import * as ProjectState from "../../project-state";
import { Spinner, H3, H4, Expander, H5 } from "../../../../elements";

const texts = Texts.texts;

type MaterialList = NonNullable<GQLOps.Log_ProjectQuery["project"]>["materialLists"][number];

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly crmExportResponse: ExportResponse | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const {
    sharedState,
    projectState,
    state: { response, downloading },
  } = props;
  const { translate } = sharedState;

  React.useEffect(() => {
    if (!projectState.projectIsBusy) {
      props.dispatch(State.Action.DownloadLog());
    }
  }, [projectState.projectIsBusy, projectState.project, projectState.applyZoomRuleWhenImporting]);

  if (projectState.projectIsBusy || downloading || !response) {
    return <Spinner debounce={false} />;
  }

  return (
    <div>
      <H3>{translate(texts.applied_zoom_rule)}</H3>
      <div className="flex flex-col space-y-8">
        {props.state.response?.project?.materialLists.map((ml) => (
          <AppliedZoomRules
            key={ml.id}
            translate={translate}
            dispatch={props.dispatch}
            materialList={ml}
            expandedItems={props.state.expandedItems}
          />
        ))}
      </div>
      <H3>{translate(texts.import_log)}</H3>
      <div className="flex flex-col space-y-8">
        {props.state.response?.project?.materialLists.map((ml) => (
          <ImportLog
            key={ml.id}
            translate={translate}
            dispatch={props.dispatch}
            materialList={ml}
            expandedItems={props.state.expandedItems}
          />
        ))}
      </div>
    </div>
  );
}

function AppliedZoomRules({
  translate,
  dispatch,
  materialList,
  expandedItems,
}: {
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
  readonly materialList: MaterialList;
  readonly expandedItems: ReadonlySet<string>;
}): JSX.Element {
  const zoomLog = materialList.changes.zoomRuleLogItems.map((i) => JSON.parse(i) as MaterialList.ZoomRuleLog);
  const expandKey = `${materialList.id}_zoom`;
  return (
    <Expander
      header={materialList.name}
      closed={expandedItems.has(expandKey)}
      onToggleClosed={() => dispatch(State.Action.ToggleExpanded(expandKey))}
    >
      <div className="flex flex-col space-y-32 max-w-3xl pt-8 ml-8">
        <ZoomRuleLog translate={translate} log={zoomLog} />
      </div>
    </Expander>
  );
}

function ImportLog({
  translate,
  dispatch,
  materialList,
  expandedItems,
}: {
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
  readonly materialList: MaterialList;
  readonly expandedItems: ReadonlySet<string>;
}): JSX.Element | null {
  const fileNames = R.uniq([...materialList.changes.importLog.map((r) => r.fileName)]);
  const fileLog = fileNames.map((fileName) => {
    const log = R.unnest(
      materialList.changes.importLog
        .filter((l) => l.fileName === fileName)
        .map((l) => l.logItems.map((i) => JSON.parse(i) as MaterialListXlsxImport.LogItem))
    );
    return { fileName, log };
  });
  const packageLog = materialList.changes.packageRuleLogItems.map((i) => JSON.parse(i) as MaterialList.PackageRuleLog);

  const expandKey = `${materialList.id}_import`;
  if (fileLog.length === 0) {
    return null;
  }
  return (
    <Expander
      header={materialList.name}
      closed={expandedItems.has(expandKey)}
      onToggleClosed={() => dispatch(State.Action.ToggleExpanded(expandKey))}
    >
      <div className="flex flex-col space-y-32 max-w-3xl pt-8 ml-8">
        {fileLog.map(({ fileName, log }) => (
          <div key={fileName}>
            <H4>{fileName}</H4>
            <ItemListLog
              translate={translate}
              log={log.filter((l) => l.type === "item_list") as ReadonlyArray<MaterialListXlsxImport.ItemListLog>}
            />
            <InsulationLog
              translate={translate}
              log={
                log.filter(
                  (l) => l.type === "insulation_schedule"
                ) as ReadonlyArray<MaterialListXlsxImport.InsulationLog>
              }
            />
            <DuctLog
              translate={translate}
              log={log.filter((l) => l.type === "duct_schedule") as ReadonlyArray<MaterialListXlsxImport.DuctLog>}
              packageLog={packageLog}
            />
          </div>
        ))}
      </div>
    </Expander>
  );
}

function ItemListLog({
  translate,
  log,
}: {
  readonly translate: Texts.TranslateFn;
  readonly log: ReadonlyArray<MaterialListXlsxImport.ItemListLog>;
}): JSX.Element | null {
  if (log.length === 0) {
    return null;
  }
  return (
    <table>
      <thead>
        <tr>
          <th>{translate(texts.item_number)}</th>
          <th>{translate(texts.quantity_added)}</th>
        </tr>
      </thead>
      <tbody>
        {log.map((m, i) => (
          <tr key={i}>
            <td>{m.itemNumber}</td>
            <td>{m.quantityAdded}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function InsulationLog({
  translate,
  log,
}: {
  readonly translate: Texts.TranslateFn;
  readonly log: ReadonlyArray<MaterialListXlsxImport.InsulationLog>;
}): JSX.Element | null {
  if (log.length === 0) {
    return null;
  }
  return (
    <table>
      <thead>
        <tr>
          <th>{translate(texts.item_number)}</th>
          <th>{translate(texts.duct_size)}</th>
          <th>{translate(texts.thickness)}</th>
          <th>{translate(texts.total_length_in_xlsx_mm)}</th>
          <th>{translate(texts.length_per_item_mm)}</th>
          <th>{translate(texts.calculated_quantity)}</th>
          <th>{translate(texts.added_quantity)}</th>
        </tr>
      </thead>
      <tbody>
        {[...log]
          .sort((a, b) => a.ductSize - b.ductSize)
          .map((m, i) => (
            <tr key={i}>
              <td>{m.itemNumber}</td>
              <td>{m.ductSize}</td>
              <td>{m.thickness}</td>
              <td>{renderNumber(m.totalLengthMm)}</td>
              <td>{m.lengthPerItemMm}</td>
              <td>{renderNumber(m.quantityCalculated)}</td>
              <td>{m.quantityAdded}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

function DuctLog({
  translate,
  log,
  packageLog,
}: {
  readonly translate: Texts.TranslateFn;
  readonly log: ReadonlyArray<MaterialListXlsxImport.DuctLog>;
  readonly packageLog: ReadonlyArray<MaterialList.PackageRuleLog>;
}): JSX.Element | null {
  if (log.length === 0) {
    return null;
  }
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{translate(texts.item_number)}</th>
            <th>{translate(texts.duct_size)}</th>
            <th>{translate(texts.total_length_in_xlsx_mm)}</th>
            <th>{translate(texts.length_per_item_mm)}</th>
            <th>{translate(texts.calculated_quantity)}</th>
            <th>{translate(texts.added_quantity)}</th>
          </tr>
        </thead>
        <tbody>
          {[...log]
            .sort((a, b) => a.ductSize - b.ductSize)
            .map((m, i) => (
              <tr key={i}>
                <td>{m.itemNumber}</td>
                <td>{m.ductSize}</td>
                <td>{renderNumber(m.totalLengthMm)}</td>
                <td>{m.lengthPerItemMm}</td>
                <td>{renderNumber(m.quantityCalculated)}</td>
                <td>{m.quantityAdded}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <PackageRuleLog translate={translate} log={packageLog} />
    </>
  );
}

function PackageRuleLog({
  translate,
  log,
}: {
  readonly translate: Texts.TranslateFn;
  readonly log: ReadonlyArray<MaterialList.PackageRuleLog>;
}): JSX.Element | null {
  return (
    <div className="mt-8">
      <H5>{translate(texts.duct_package)}</H5>
      {log.length === 0 && translate(texts.duct_package_rule_not_applied)}
      {[...log].map((m, i) => (
        <div key={i}>
          <table>
            <thead>
              <tr>
                <th>{translate(texts.duct_package_info)}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{translate(texts.duct_package_diameters)}</td>
                <td>{m.ductDiameters.join(", ")}</td>
              </tr>
              <tr>
                <td>{translate(texts.item_number)}</td>
                <td>{m.packageItemNumber}</td>
              </tr>
              <tr>
                <td>{translate(texts.ducts_per_package)}</td>
                <td>{m.quantityPerPackage}</td>
              </tr>
            </tbody>
          </table>
          <table className="mt-16">
            <thead>
              <tr>
                <th>{translate(texts.item_number_changed)}</th>
                <th>{translate(texts.rule_adjustment)}</th>
                <th>{translate(texts.quantity_before)}</th>
                <th>{translate(texts.quantity_after)}</th>
              </tr>
            </thead>
            <tbody>
              {m.changes.map((c, i) => (
                <tr key={i}>
                  <td>{c.itemNumber}</td>
                  <td>{c.quantityAfter - c.quantityBefore}</td>
                  <td>{c.quantityBefore}</td>
                  <td>{c.quantityAfter}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

function ZoomRuleLog({
  translate,
  log,
}: {
  readonly translate: Texts.TranslateFn;
  readonly log: ReadonlyArray<MaterialList.ZoomRuleLog>;
}): JSX.Element | null {
  if (log.length === 0) {
    return <div>{translate(texts.no_zoom_rules_has_been_applied)}</div>;
  }
  return (
    <>
      {[...log]
        .sort((a, b) => a.ductSize - b.ductSize)
        .map((m, i) => (
          <div key={i}>
            <H4>{translate(texts.duct_size_heading(m.ductSize))}</H4>
            <table>
              <thead>
                <tr>
                  <th>{translate(texts.duct_item)}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translate(texts.size)}</td>
                  <td>{m.ductSize}</td>
                </tr>
                <tr>
                  <td>{translate(texts.item_number)}</td>
                  <td>{m.ductItemNumber}</td>
                </tr>
                <tr>
                  <td>{translate(texts.quantity)}</td>
                  <td>{m.ductQuantity}</td>
                </tr>
              </tbody>
            </table>
            <table className="mt-16">
              <thead>
                <tr>
                  <th>{translate(texts.item_number_changed)}</th>
                  <th>{translate(texts.rule_adjustment)}</th>
                  <th>{translate(texts.quantity_before)}</th>
                  <th>{translate(texts.quantity_after)}</th>
                </tr>
              </thead>
              <tbody>
                {m.changes.map((c, i) => (
                  <tr key={i}>
                    <td>{c.itemNumber}</td>
                    <td>{c.quantityAfter - c.quantityBefore}</td>
                    <td>{c.quantityBefore}</td>
                    <td>{c.quantityAfter}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </>
  );
}

function renderNumber(n: number): string {
  return (Math.round(n * 100) / 100).toString();
}
