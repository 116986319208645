import { Cmd } from "@typescript-tea/core";
import { ctorsUnion, CtorsUnion } from "ctors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import gql from "graphql-tag";
import {
  NavigationEffectManager as Navigation,
  graphQLProductQueryWithAuth,
  SharedState,
  Routes,
  Route,
  graphQLQueryWithAuth,
  OidcEffectManager,
  SessionStorage,
} from "@revit-order/client-infra";
import { Texts, UserSettings, Markets, User } from "@revit-order/shared";
import { setUser } from "../sentry";
import * as GQLOps from "../generated/generated-operations";
import { clientConfig } from "../client-config";
import { userManagerSettings } from "../user-manager-settings";
import * as Main from "../main";

const sessionStorageKey = "revit-order-settings";

export const rootQuery = gql`
  query metaProduct($metaProductId: ID!, $m3MetaProductId: ID!, $language: String!) {
    metaProduct: product(id: $metaProductId) {
      key
      modules {
        ...LangText_ModulesTexts
        ...marketModules
      }
    }
    m3MetaProduct: product(id: $m3MetaProductId) {
      key
      modules {
        ...LangText_ModulesTexts
      }
    }
  }
  ${Texts.LangText_ModulesTextsFragment}
  ${Markets.marketModulesFragment}
`;

export type State =
  | ErrorState
  | LoadingUserSettingsState
  | LoadingDataState
  | WaitingForUserSessionState
  | LoggedInState
  | LoggedOutState;

export type LoadingUserSettingsState = {
  readonly type: "LoadingUserSettingsState";
  readonly activeUser: User.ActiveUser;
  readonly originalUrl: string;
  readonly userSettings: UserSettings.UserSettings;
};

export type LoadingDataState = {
  readonly type: "LoadingDataState";
  readonly activeUser: User.ActiveUser;
  readonly originalUrl: string;
  readonly userSettings: UserSettings.UserSettings;
};

export type LoggedInState = {
  readonly type: "LoggedInState";
  readonly activeUser: User.ActiveUser;
  readonly metaProductResponse: GQLOps.MetaProductQuery | undefined;
  readonly mainState: Main.State | undefined;
  readonly urlMatch: Route.UrlMatch<Routes.RootLocation> | undefined;
  readonly userSettings: UserSettings.UserSettings;
  readonly translate: Texts.TranslateFn;
  readonly crmParams: SharedState.CrmParams | undefined;
};

export type WaitingForUserSessionState = {
  readonly type: "WaitingForUserSessionState";
  readonly urlPath: string;
  readonly urlQuery: string;
};

export type LoggedOutState = {
  readonly type: "LoggedOutState";
};

type RedirectState = { readonly redirectUrl: string | undefined };

export type ErrorState = {
  readonly type: "ErrorState";
  readonly reason: string;
};

export const Action = ctorsUnion({
  UrlChanged: (url: Navigation.Url) => ({ url }),
  UrlRequested: (urlRequest: Navigation.UrlRequest) => ({ urlRequest }),
  UserSettingsRecieved: (response: GQLOps.GetUserSettingsQuery) => ({
    response,
  }),
  MetaProductRecieved: (response: GQLOps.MetaProductQuery) => ({
    response,
  }),
  UserSessionChanged: (user: OidcEffectManager.User | undefined) => ({ user }),
  AccessTokenRefreshed: (user: OidcEffectManager.User) => ({ user }),
  DispatchMain: (action: Main.Action) => ({ action }),
  SessionStorageRecieved: (data: string) => ({ data }),
  NoOp: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(url: Navigation.Url): readonly [State, Cmd<Action>?] {
  const initialUrl = url.path + (url.query ?? "");
  const urlMatch = Routes.parseUrl(initialUrl);

  if (
    urlMatch === undefined ||
    (urlMatch.location.type !== "LoginCallback" && urlMatch.location.type !== "LoggedOut")
  ) {
    // Since this is the init() function we never have a user in our state at this point,
    // so the only thing we can do is to try to login which will either result in a user being
    // found directly (becuase we were already have a token in local storage), or a redirect to the login server
    // If we are already logged in we will have our user session subscription triggered.
    // If we are nog logged in then we will be redirected to the login server.
    // Use the current url as the state to save in the redirect round-trip
    const redirectState: RedirectState = { redirectUrl: initialUrl };
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.login(userManagerSettings, redirectState),
    ];
  }

  if (urlMatch.location.type === "LoginCallback") {
    // We got the login callback, let's process it and if successful the subscription will get a user session
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.processSigninCallback(userManagerSettings),
    ];
  }

  // User logged out and was redirected to our application
  if (urlMatch.location.type === "LoggedOut") {
    return [{ type: "LoggedOutState" }];
  }

  // Should never get here
  return exhaustiveCheck(urlMatch.location, true);
}

export function update(action: Action, state: State): readonly [State, Cmd<Action>?] {
  if (state.type === "ErrorState") {
    return [state];
  }
  switch (action.type) {
    case "AccessTokenRefreshed": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const { user } = action;
      const activeUser = User.buildActiveUser(user, user.access_token);
      if (!User.isValidUser(activeUser)) {
        return [
          {
            ...state,
            type: "ErrorState",
            reason: activeUser.reason,
          },
        ];
      }
      return [{ ...state, activeUser: activeUser }];
    }
    case "UserSessionChanged": {
      const { user } = action;
      switch (state.type) {
        case "LoadingDataState":
        case "LoadingUserSettingsState": {
          // If we got here then there was some error in the login flow
          return [
            {
              type: "ErrorState",
              reason: `Error Invalid Action UserSessionChanged during state ${state.type}`,
            },
          ];
        }
        case "LoggedInState": {
          // If we have no user then set state as logged out
          if (user === undefined) {
            return [{ type: "LoggedOutState" }];
          }
          return [state];
        }
        case "WaitingForUserSessionState": {
          //If we got an undefind user then there was some error in the login flow
          if (user === undefined) {
            return [{ type: "ErrorState", reason: "OIDC user is undefined" }];
          }
          const activeUser = User.buildActiveUser(user, user.access_token);

          if (!User.isValidUser(activeUser)) {
            return [
              {
                ...state,
                type: "ErrorState",
                reason: activeUser.reason,
              },
            ];
          }

          // Set active user for sentry reporting, side-effect in reducer, not nice!!
          setUser(activeUser.email);

          // There are two main cases for how we got here and what url we should go to next
          // 1. We got a callback from login, in this case we are currently at /signin-callback and originally requested url is in user.state
          // 2. There was no callback (user found in local storage), so we are still at the originally requested url which is in state.initUrl
          // In both cases we can replace the current url with the originally requested url, becuase that will trigger an UrlChanged action
          // We use the user.state to check if it was a callback with original url saved in user.state
          // Since UrlChange handles invalid url we do not need to check valid url here

          const redirectState = user.state as RedirectState;
          const originalUrl =
            redirectState && redirectState.redirectUrl ? redirectState.redirectUrl : state.urlPath + state.urlQuery;
          const graphQLQuery = graphQLQueryWithAuth(activeUser);
          const gqlCmd = graphQLQuery<GQLOps.GetUserSettingsQuery, GQLOps.GetUserSettingsQueryVariables, Action>(
            UserSettings.userSettingsQuery,
            {},
            "",
            (data) => {
              return Action.UserSettingsRecieved(data);
            }
          );
          return [
            {
              type: "LoadingUserSettingsState",
              activeUser,
              originalUrl: originalUrl,
              userSettings: {},
            },
            gqlCmd,
          ];
        }
        case "LoggedOutState": {
          // Once logged out, anything else that happens is in error
          return [{ type: "ErrorState", reason: "LoggedOutState" }];
        }
        default:
          return exhaustiveCheck(state, true);
      }
    }
    case "UrlChanged": {
      switch (state.type) {
        case "LoggedInState": {
          const urlMatch = Routes.parseUrl(action.url.path + (action.url.query ?? ""));

          const cmds = [];

          const crmParams = urlMatch === undefined ? readCrmParamsFromQuery(action.url.query || "") : undefined;
          if (crmParams) {
            cmds.push(SessionStorage.set(sessionStorageKey, JSON.stringify({ crmParams }), Action.NoOp));
          } else {
            cmds.push(
              SessionStorage.get(sessionStorageKey, (result) => {
                if (result.type !== "Err" && result.value) {
                  return Action.SessionStorageRecieved(result.value);
                } else {
                  return Action.NoOp();
                }
              })
            );
          }

          if (urlMatch === undefined) {
            // If the current location is undefined then goto the default location
            const defaultLocation = Routes.RootLocation.MainLocation(
              Routes.MainLocation.ProjectList(Routes.ProjectListLocation.ProjectList())
            );
            const defaultUrl = Routes.buildUrl(defaultLocation);

            // Safety-check that defaultUrl really has a match becuase otherwise we will be stuck in client-side redirect-loop
            const defaultMatch = Routes.parseUrl(defaultUrl);
            if (defaultMatch === undefined) {
              throw new Error("Default URL does not match a route.");
            }

            cmds.push(Navigation.replaceUrl<Action>(defaultUrl));

            return [{ ...state, crmParams }, Cmd.batch(cmds)];
          } else {
            const newState = { ...state, urlMatch };
            switch (urlMatch.location.type) {
              case "LoginCallback":
                // LoginCallback can only be triggered in init() as it starts the application
                return [{ type: "ErrorState", reason: "LoginCallback error" }];
              case "LoggedOut":
                return [{ type: "LoggedOutState" }];

              case "MainLocation": {
                const [mainState, mainCmd] = initMainState({ ...newState, urlMatch }, newState.mainState);
                cmds.push(mainCmd);
                return [{ ...newState, urlMatch, mainState }, Cmd.batch(cmds)];
              }
              default:
                return exhaustiveCheck(urlMatch.location, true);
            }
          }
        }

        default:
          // In other states this action has no relevance
          return [state];
      }
    }
    case "UrlRequested":
      switch (action.urlRequest.type) {
        case "InternalUrlRequest":
          return [state, Navigation.pushUrl(action.urlRequest.url)];
        case "ExternalUrlRequest":
          return [state, Navigation.load(Navigation.toString(action.urlRequest.url))];
        default:
          return exhaustiveCheck(action.urlRequest);
      }

    case "UserSettingsRecieved": {
      if (state.type !== "LoadingUserSettingsState") {
        return [state];
      }
      const userSettings = UserSettings.updateWithResponse(state.userSettings, action.response);
      return [
        {
          type: "LoadingDataState",
          activeUser: state.activeUser,
          originalUrl: state.originalUrl,
          userSettings: userSettings,
        },
        loadMetaProduct(state.activeUser, userSettings),
      ];
    }

    case "MetaProductRecieved": {
      if (state.type !== "LoadingDataState" && state.type !== "LoggedInState") {
        return [state];
      }
      const { activeUser } = state;
      const translate = createTranslateFn(action.response);
      if (state.type === "LoadingDataState") {
        return [
          {
            type: "LoggedInState",
            activeUser,
            metaProductResponse: action.response,
            mainState: undefined,
            urlMatch: undefined,
            userSettings: state.userSettings,
            crmParams: undefined,
            translate,
          },
          Navigation.replaceUrl(state.originalUrl),
        ];
      } else {
        return [{ ...state, metaProductResponse: action.response, translate }];
      }
    }

    case "DispatchMain": {
      if (state.type !== "LoggedInState") {
        return [state];
      }

      const [updateMainState, updateMainCmd, sharedStateAction] = Main.update(
        action.action,
        state.mainState!,
        buildSharedState(state)
      );
      const newStateWithUpdatedMainState = { ...state, mainState: updateMainState };
      const [newState, sharedCmd] = handleSharedStateAction(newStateWithUpdatedMainState, sharedStateAction);
      if (newState.type !== "LoggedInState") {
        return [newState, sharedCmd];
      }

      if (UserSettings.selectMarket(state.userSettings) === UserSettings.selectMarket(newState.userSettings)) {
        return [newState, Cmd.batch<Action>([Cmd.map(Action.DispatchMain, updateMainCmd), sharedCmd])];
      } else {
        const [newMainState, newMainStateCmd] = initMainState(newState, undefined);
        return [{ ...newState, mainState: newMainState }, Cmd.batch<Action>([newMainStateCmd, sharedCmd])];
      }
    }

    case "SessionStorageRecieved": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const json = JSON.parse(action.data);
      const crmParams = json.crmParams as SharedState.CrmParams | undefined;
      return [
        {
          ...state,
          crmParams:
            crmParams && crmParams.crmQuoteId
              ? {
                  crmQuoteId: crmParams.crmQuoteId,
                  crmQuoteLanguage: crmParams.crmQuoteLanguage,
                  crmCustomerNumber: crmParams.crmCustomerNumber,
                  crmApi: crmParams.crmApi,
                }
              : undefined,
        },
      ];
    }

    case "NoOp": {
      return [state];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

export function buildSharedState(state: LoggedInState): SharedState.SharedState {
  const language = UserSettings.selectLanguage(state.userSettings);
  const m3Languagecode = state.metaProductResponse?.metaProduct?.modules.custom_tables.Market.find(
    (m) => m.language === language
  )?.m3_language_code;
  return {
    activeUser: state.activeUser,
    translate: state.translate,
    userSettings: state.userSettings,
    graphQLProductQuery: graphQLProductQueryWithAuth(state.activeUser, clientConfig.promaster_marker),
    crmParams: state.crmParams,
    m3LanguageCode: m3Languagecode || "EN",
    market: Markets.getMarketOrDefault(
      state.metaProductResponse?.metaProduct?.modules.custom_tables.Market || [],
      UserSettings.selectMarket(state.userSettings),
      state.activeUser
    ),
  };
}

export function createTranslateFn(getTextsQuery: GQLOps.GetTextsQuery): Texts.TranslateFn {
  const translations = Texts.translationsFromProductTexts(getTextsQuery);
  return Texts.createTranslateFn(translations);
}

function handleSharedStateAction(
  state: LoggedInState,
  action: SharedState.SharedStateAction | undefined
): readonly [LoggedInState | LoggedOutState, Cmd<Action>?] {
  if (action === undefined) {
    return [state];
  }
  switch (action.type) {
    case "Logout":
      if (state.type === "LoggedInState") {
        return [{ type: "LoggedOutState" }, OidcEffectManager.logout(state.activeUser.idToken)];
      }
      return [state];

    case "SetLanguage": {
      const userSettings = UserSettings.setLanguage(state.userSettings, action.newLang);
      return [
        { ...state, userSettings },
        Cmd.batch([
          loadMetaProduct(state.activeUser, userSettings),
          createUserSettingMutationCmd(state.activeUser, userSettings, state.userSettings),
        ]),
      ];
    }

    case "SetMarket": {
      const userSettings = UserSettings.setMarket(state.userSettings, action.newMarket);
      const newState = {
        ...state,
        userSettings,
      };
      return [newState, createUserSettingMutationCmd(state.activeUser, newState.userSettings, state.userSettings)];
    }

    case "SetCrm": {
      const newState = {
        ...state,
        crmParams: action.newCrm,
      };
      return [newState, SessionStorage.set(sessionStorageKey, JSON.stringify({}), Action.NoOp)];
    }

    case "UpdateUserSettings": {
      const newState = { ...state, userSettings: action.userSettings };
      return [newState, createUserSettingMutationCmd(state.activeUser, newState.userSettings, state.userSettings)];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function loadMetaProduct(activeUser: User.ActiveUser, userSettings: UserSettings.UserSettings): Cmd<Action> {
  const graphQLProductQuery = graphQLProductQueryWithAuth(activeUser, clientConfig.promaster_marker);
  return graphQLProductQuery<GQLOps.MetaProductQuery, GQLOps.MetaProductQueryVariables, Action>(
    rootQuery,
    {
      metaProductId: clientConfig.promaster_meta_id,
      m3MetaProductId: clientConfig.promaster_m3_meta_id,
      language: UserSettings.selectLanguage(userSettings),
    },
    (data) => {
      return Action.MetaProductRecieved(data);
    }
  );
}

function createUserSettingMutationCmd(
  activeUser: User.ActiveUser,
  userSettings: UserSettings.UserSettings,
  prevUserSettings: UserSettings.UserSettings
): Cmd<Action> | undefined {
  const [gql, input] = UserSettings.createGqlMutations(userSettings, prevUserSettings);
  if (input.length > 0) {
    return graphQLQueryWithAuth(activeUser)<
      GQLOps.UpdateUserSettingsMutation,
      GQLOps.UpdateUserSettingsMutationVariables,
      Action
    >(
      gql,
      {
        input: input,
      },
      "",
      Action.NoOp
    );
  } else {
    return undefined;
  }
}

function readCrmParamsFromQuery(queryString: string): SharedState.CrmParams | undefined {
  if (!queryString.startsWith("?")) {
    return undefined;
  }
  const queryParams: { readonly [key: string]: string } = queryString
    .slice(1)
    .split("&")
    .map((pair) => {
      const [key, value] = pair.split("=");
      return [key, decodeURIComponent(value || "")];
    })
    .reduce((sofar, pair) => ({ ...sofar, [pair[0]]: pair[1] }), {});
  const crmQuoteLanguage = queryParams.crmQuoteLanguage || "en";
  const crmQuoteId = queryParams.crmQuoteId;
  const crmCustomerNumber = queryParams.crmCustomerNumber;
  const crmApi = queryParams.api === "new" ? "new" : "old";
  if (!crmQuoteId) {
    return undefined;
  }
  return {
    crmQuoteLanguage,
    crmQuoteId,
    crmCustomerNumber,
    crmApi,
  };
}

function initMainState(
  state: LoggedInState,
  prevMainState: Main.State | undefined
): readonly [Main.State | undefined, Cmd<Action> | undefined] {
  if (!state.urlMatch || state.urlMatch.location.type !== "MainLocation") {
    return [undefined, undefined];
  }
  const [mainState, mainCmd] = Main.init(state.urlMatch.location.location, prevMainState, buildSharedState(state));
  return [mainState, Cmd.map(Action.DispatchMain, mainCmd)];
}
