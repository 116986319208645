import gql from "graphql-tag";

export const schema = gql`
  type Material {
    id: ID!
    sortNo: Int!
    itemNumber: String!
    quantity: Float!
    included: Boolean!
    type: String!
    name: String
  }

  extend type Mutation {
    createMaterials(input: CreateMaterialsInput!): CreateMaterialsPayload!
    removeMaterials(input: RemoveMaterialsInput!): RemoveMaterialsPayload!
    updateMaterials(input: UpdateMaterialsInput!): UpdateMaterialsPayload!
    updateCustomMaterial(input: UpdateCustomMaterialInput!): UpdateCustomMaterialPayload!
  }

  input CreateMaterialsInput {
    materialListId: ID!
    materials: [CreateMaterialInput!]!
  }

  input CreateMaterialInput {
    id: ID!
    sortNo: Int!
    itemNumber: String!
    quantity: Float!
    included: Boolean!
    type: String!
    name: String
  }

  type CreateMaterialsPayload {
    ids: [ID!]!
  }

  input RemoveMaterialsInput {
    ids: [ID!]!
  }

  type RemoveMaterialsPayload {
    ids: [ID!]!
  }

  input UpdateMaterialsInput {
    materials: [UpdateMaterialInput!]!
  }

  input UpdateMaterialInput {
    id: ID!
    sortNo: Int
    itemNumber: String
    quantity: Float
    type: String
    name: String
    included: Boolean
  }

  type UpdateMaterialsPayload {
    ids: [ID!]!
  }

  input UpdateCustomMaterialInput {
    materialListId: ID!
    materialId: ID!
    itemNumber: String!
    marketName: String!
    language: String!
  }

  type UpdateCustomMaterialPayload {
    materialListId: ID!
    materialId: ID!
    status: String!
    updatedMaterial: Material
  }
`;
