import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { SharedState, Routes } from "@revit-order/client-infra";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import * as ProjectState from "../../project-state";

// STATE

export interface State {
  readonly confirmUnlock: boolean;
}

export const Action = ctorsUnion({
  UnlockProject: (state: "confirm" | "done") => ({ state }),
  NoOp: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined,
  _projectId: string
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    prevState || {
      confirmUnlock: false,
    },
  ];
}

export function update(
  action: Action,
  state: State,
  _projectState: ProjectState.State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "UnlockProject": {
      switch (action.state) {
        case "confirm":
          return [{ ...state, confirmUnlock: true }];
        case "done":
          return [{ ...state, confirmUnlock: false }];
        default:
          return [state];
      }
    }
    case "NoOp": {
      return [state];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
