import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@revit-order/client-infra";
import { ExportResponse } from "@revit-order/shared/src/crm";
import { MaterialFile, Project, Texts, MaterialList } from "@revit-order/shared";
import * as State from "./state";
import * as ProjectState from "../../project-state";
import {
  BrowseFiles,
  H3,
  Spinner,
  Icon,
  Button,
  Alert,
  AlertType,
  Expander,
  ConfirmRemoveButton,
  Checkbox,
} from "../../../../elements";
import * as GQLOps from "../../../../generated/generated-operations";

const texts = Texts.texts;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly crmExportResponse: ExportResponse | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { sharedState, projectState, dispatchProject, dispatch, state } = props;
  const { translate } = sharedState;
  const { filesBeingDownloaded } = state;
  const { stagedFiles, project, uploadingfiles, uploadingFilesMessages, uploadingFilesStatus } = projectState;
  const [showMessages, setShowMessages] = React.useState(false);
  const [showZoomLog, setShowZoomLog] = React.useState(false);
  const isReadOnly = Project.isProjectReadOnly(project) || projectState.projectIsBusy;
  const working = uploadingfiles || state.downloadingLog;
  const zoomRuleLog = state.logResponse?.project?.materialLists[0].changes.zoomRuleLogItems?.map(
    (i) => JSON.parse(i) as MaterialList.ZoomRuleLog
  );

  React.useEffect(() => {
    if (projectState.uploadingFilesStatus === "ok" && !state.logResponse) {
      dispatch(State.Action.DownloadLog());
    } else if (projectState.uploadingFilesStatus !== "ok") {
      dispatch(State.Action.ResetLog());
    }
  }, [projectState.uploadingFilesStatus, state.logResponse]);

  return (
    <div className="flex flex-col space-y-16 max-w-3xl">
      <Checkbox
        disabled={working}
        checked={projectState.applyZoomRuleWhenImporting}
        onChange={(c) => dispatchProject(ProjectState.Action.SetApplyZoomRuleWhenImporting(c))}
        label={translate(texts.enable_zoom_rule)}
        title={translate(texts.enable_zoom_rule_import_note)}
      />
      <div>
        <div>
          <H3>{translate(texts.upload_files)}</H3>
        </div>
        <div className="flex-col space-y-16">
          <BrowseFiles
            disabled={isReadOnly}
            translate={translate}
            accept=".xls, .xlsx"
            onFiles={(files) => stageFiles(files, dispatchProject)}
            showSpinner={working}
          />

          {stagedFiles.length > 0 && (
            <div>
              {MaterialFile.allFileTypes.length !== stagedFiles.length ? (
                <Alert type="error">{translate(texts.files_required_upload_message)}</Alert>
              ) : null}
              <table>
                <thead>
                  <tr>
                    <th>{translate(texts.file_type)}</th>
                    <th>{translate(texts.file_name)}</th>
                  </tr>
                </thead>

                <tbody>
                  {MaterialFile.allFileTypes.map((fileType) => {
                    const stagedFile = stagedFiles.find((f) => f.fileType === fileType);

                    return (
                      <tr key={stagedFile?.name || fileType} className={stagedFile ? "newRowHighlight" : ""}>
                        <td>{translate(texts.file_type_name(fileType))}</td>
                        <td>
                          {stagedFiles.find((f) => f.fileType === fileType)?.name || (
                            <span className="text-gray-400">{translate(texts.not_added)}</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tbody className="border-t-2 border-gray-100 border-solid">
                  {stagedFiles
                    .filter((f) => !f.fileType)
                    .map((stagedFile) => (
                      <tr key={stagedFile.name}>
                        <td>{translate(texts.unknown_file_type)}</td>
                        <td>{stagedFile.name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          {uploadingFilesStatus === "ok" ? (
            <Alert type="success">{translate(texts.xlsx_import_success)}</Alert>
          ) : uploadingFilesStatus === "error" ? (
            <Alert type="error">{translate(texts.xlsx_import_failed)}</Alert>
          ) : undefined}
          {uploadingFilesMessages && uploadingFilesMessages.length > 0 && (
            <Expander
              header={`${translate(texts.xlsx_import_messages_header)} (${uploadingFilesMessages.length})`}
              closed={!showMessages}
              onToggleClosed={() => setShowMessages(!showMessages)}
            >
              <ImportMessages messages={uploadingFilesMessages} translate={translate} />
            </Expander>
          )}
          {projectState.applyZoomRuleWhenImporting && projectState.uploadingFilesStatus === "ok" && zoomRuleLog && (
            <Expander
              header={`${translate(texts.xlsx_zoom_log_header)} (${zoomRuleLog.length})`}
              closed={!showZoomLog}
              onToggleClosed={() => setShowZoomLog(!showZoomLog)}
            >
              <ZoomMessages translate={translate} log={zoomRuleLog} />
            </Expander>
          )}
        </div>
      </div>
      {(project.materialFiles?.files.length || 0) > 0 && (
        <div>
          <H3>{translate(texts.current_files)}</H3>
          <div className="flex flex-row space-x-16">
            <Button
              label={translate(texts.download_all_files)}
              disabled={filesBeingDownloaded.has(State.allFilesId)}
              spinnerIcon={filesBeingDownloaded.has(State.allFilesId)}
              iconLeft="download"
              onClick={() => dispatch(State.Action.Download(State.allFilesId))}
            />
            <ConfirmRemoveButton
              regularButton={true}
              disabled={isReadOnly || working}
              onClick={() => dispatchProject(ProjectState.Action.ResetFromUploadedMaterialFiles())}
              confirmMessage={translate(texts.confirm_reset_material_list)}
              removeText={translate(texts.reset_material_list)}
              cancelText={translate(Texts.texts.cancel)}
              iconMessage={translate(texts.reset_material_list)}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>{translate(texts.file_type)}</th>
                <th>{translate(texts.file_name)}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {project.materialFiles?.files.map((file) => (
                <tr key={file.id} className={!state.existingIds.has(file.id) ? "newRowHighlight" : ""}>
                  <td>{translate(texts.file_type_name(file.type))}</td>
                  <td>{file.name}</td>
                  <td>
                    {filesBeingDownloaded.has(file.id) ? (
                      <Spinner small={true} debounce={false} />
                    ) : (
                      <Icon
                        icon={"download"}
                        onClick={() => dispatch(State.Action.Download(file.id))}
                        message={translate(texts.download_file)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

async function stageFiles(files: ReadonlyArray<File>, dispatchProject: Dispatch<ProjectState.Action>): Promise<void> {
  const okFiles = files.filter((f) => f.size < 1024 * 1024);
  if (okFiles.length === 0) {
    return;
  }

  const fileDatas = await Promise.all(okFiles.map(getFileData));

  dispatchProject(
    ProjectState.Action.StageFiles(
      okFiles
        .map((f, i) => ({ f, data: fileDatas[i] }))
        .filter(({ data }) => !!data)
        .map(({ f, data }) => ({
          data: data!,
          name: f.name,
          lastModified: f.lastModified,
          fileType: MaterialFile.getFileType(f.name),
        }))
    )
  );
}

async function getFileData(file: File): Promise<ArrayBuffer | string | undefined> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      const arrayBuffer = reader.result;
      if (arrayBuffer) {
        resolve(arrayBuffer);
      } else {
        resolve(undefined);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

function ImportMessages({
  messages,
  translate,
}: {
  readonly messages: ReadonlyArray<GQLOps.UploadMaterialFilesMessage>;
  readonly translate: Texts.TranslateFn;
}): JSX.Element | null {
  const processedMessages = processMessages(messages);
  if (processMessages.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col space-y-16 max-w-3xl">
      {processedMessages.map((m, i) => (
        <Message key={i} translate={translate} message={m} />
      ))}
    </div>
  );
}

function ZoomMessages({
  translate,
  log,
}: {
  readonly translate: Texts.TranslateFn;
  readonly log: ReadonlyArray<MaterialList.ZoomRuleLog>;
}): JSX.Element | null {
  if (log.length === 0) {
    return (
      <Alert type={"info"}>
        <div>{translate(texts.no_zoom_rules_has_been_applied)}</div>
      </Alert>
    );
  }
  return (
    <div className="flex flex-col space-y-16 max-w-3xl">
      {log.map((m, i) => (
        <Alert type={"info"} key={i}>
          {<div className="font-bold">{translate(texts.duct_size_heading(m.ductSize))}</div>}
          <div>{translate(texts.xlsx_import_room_rule_applied(m.ductItemNumber, m.ductSize.toString()))}</div>
        </Alert>
      ))}
    </div>
  );
}

function Message({
  translate,
  message,
}: {
  readonly translate: Texts.TranslateFn;
  readonly message: GQLOps.UploadMaterialFilesMessage;
}): JSX.Element {
  const messageKey = Texts.key(
    message.textKey,
    Object.fromEntries(message.params.map(({ key, value }) => [key, value]))
  );
  return (
    <Alert type={message.level as AlertType}>
      {message.fileName && <div className="font-bold">{message.fileName}</div>}
      <div>{translate(messageKey)}</div>
    </Alert>
  );
}

function processMessages(
  messages: ReadonlyArray<GQLOps.UploadMaterialFilesMessage>
): ReadonlyArray<GQLOps.UploadMaterialFilesMessage> {
  const unique = [];
  const added = new Set<string>();
  for (const m of messages) {
    const key = `${m.fileName}_${m.level}_${m.textKey}_${m.params
      .map(({ key, value }) => `${key}_${value}`)
      .sort()
      .join("_")}`;
    if (added.has(key)) {
      continue;
    }
    unique.push(m);
    added.add(key);
  }
  type Msg = GQLOps.UploadMaterialFilesMessage;
  const levelOrder: Record<string, number> = { error: 0, warning: 1, info: 2 };
  return unique
    .map((m, order): [Msg, number] => [m, order])
    .sort(([aMsg, aOrder], [bMsg, bOrder]) => {
      const aLevel = levelOrder[aMsg.level];
      const bLevel = levelOrder[bMsg.level];
      if (aLevel !== undefined && bLevel !== undefined && aLevel !== bLevel) {
        return aLevel - bLevel;
      } else {
        return aOrder - bOrder;
      }
    })
    .map(([m]) => m);
}
