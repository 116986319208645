import { MaterialType } from ".";
import { notFoundSortNo } from "../material-list";
import { Material } from "./types";

export function createMaterial(
  id: string,
  type: MaterialType,
  sortNo: number | undefined,
  itemNumber: string,
  quantity: number = 0
): Material {
  return {
    id: id,
    type: type,
    sortNo: sortNo ?? notFoundSortNo,
    itemNumber: itemNumber,
    included: false,
    quantity: quantity,
    name: null,
  };
}

export function isMaterialEqual(m1: Material, m2: Material): boolean {
  const eq: { [Key in keyof Material]: boolean } = {
    id: m1.id === m2.id,
    type: m1.type === m2.type,
    included: m1.included === m2.included,
    itemNumber: m1.itemNumber === m2.itemNumber,
    quantity: numbersEqual(m1.quantity, m2.quantity),
    sortNo: m1.sortNo === m2.sortNo,
    name: m1.name === m2.name,
  };
  return eq.id && eq.type && eq.included && eq.itemNumber && eq.quantity && eq.sortNo && eq.name;
}

function numbersEqual(n1: number | null, n2: number | null): boolean {
  if (n1 === null || n2 === null) {
    return n1 === n2;
  } else {
    return Math.abs(n1 - n2) < 0.00001;
  }
}
