import { Dispatch } from "@typescript-tea/core";
import { Routes, SharedState } from "@revit-order/client-infra";
import { texts } from "@revit-order/shared/src/lang-texts";
import React from "react";
import { Texts } from "@revit-order/shared";
import * as State from "./state";
import { H2, TabButton, Spinner, Alert } from "../../elements";
import * as General from "./parts/general";
import * as MaterialList from "./parts/material-list";
import * as Files from "./parts/files";
import * as Log from "./parts/log";

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const { translate } = sharedState;

  if (state.type === "error") {
    if (state.message === "project_not_found") {
      return (
        <Alert type="error">
          <p>{translate(texts.project_not_found)}</p>
          <BackToProjectListLink translate={translate} />
        </Alert>
      );
    } else if (state.message === "project_no_access") {
      return (
        <Alert type="error">
          <p>{translate(texts.project_no_access_message)}</p>
          <BackToProjectListLink translate={translate} />
        </Alert>
      );
    } else {
      throw new Error("Unknown error state");
    }
  }

  if (state.type !== "ready") {
    return <Spinner />;
  }

  const { projectStatus, projectState } = state;
  const { project } = projectState;

  if (!projectStatus.exists) {
    return (
      <Alert type="error">
        <p>{translate(texts.project_delete_message)}</p>
        <BackToProjectListLink translate={translate} />
      </Alert>
    );
  } else if (!projectStatus.access) {
    return (
      <Alert type="error">
        <p>{translate(texts.project_no_access_message)}</p>
        <BackToProjectListLink translate={translate} />
      </Alert>
    );
  }

  if (!project) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="mb-32">
        <BackToProjectListLink translate={translate} />
      </div>
      <div>
        <H2>{project.name || translate(texts.project)}</H2>
      </div>
      <div className="mb-20">
        <TabButton
          active={state.location.type === "General"}
          label={translate(texts.general)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.General())
            )
          )}
        />
        <TabButton
          active={state.location.type === "Files"}
          label={translate(texts.material_files)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.Files())
            )
          )}
        />
        <TabButton
          active={state.location.type === "Log"}
          label={translate(texts.quantity_calculations)}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.Log())
            )
          )}
        />
        <TabButton
          active={state.location.type === "MaterialList"}
          label={translate(texts.material_list)}
          disabled={project.materialLists.length === 0}
          url={Routes.buildUrl(
            Routes.RootLocation.MainLocation(
              Routes.MainLocation.Project(state.projectState.project.id, Routes.ProjectLocation.MaterialList())
            )
          )}
        />
      </div>
      {(() => {
        switch (state.location.type) {
          case "General":
            return state.generalState ? (
              <General.View
                dispatch={Dispatch.map(State.Action.GeneralAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                crmExportResponse={projectState.crmExportResponse}
                state={state.generalState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "Files":
            return state.filesState ? (
              <Files.View
                dispatch={Dispatch.map(State.Action.FilesAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                crmExportResponse={projectState.crmExportResponse}
                state={state.filesState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "Log":
            return state.logState ? (
              <Log.View
                dispatch={Dispatch.map(State.Action.LogAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                crmExportResponse={projectState.crmExportResponse}
                state={state.logState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          case "MaterialList":
            return state.materialListState ? (
              <MaterialList.View
                dispatch={Dispatch.map(State.Action.MaterialListAction, dispatch)}
                dispatchProject={Dispatch.map(State.Action.ProjectStateAction, dispatch)}
                crmExportResponse={projectState.crmExportResponse}
                state={state.materialListState}
                projectState={projectState}
                sharedState={sharedState}
              />
            ) : undefined;
          default:
            return <div>{translate(texts.not_found_404)}</div>;
        }
      })()}
    </div>
  );
}

function BackToProjectListLink({ translate }: { readonly translate: Texts.TranslateFn }): JSX.Element {
  const url = Routes.buildUrl(
    Routes.RootLocation.MainLocation(Routes.MainLocation.ProjectList(Routes.ProjectListLocation.ProjectList()))
  );
  const text = translate(texts.go_back_to_project_list);
  return <a href={url}>{text}</a>;
}
