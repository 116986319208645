import React from "react";
import * as R from "ramda";
import { createCategoryName } from "@revit-order/shared/src/material-list/categories";
import { getMetaData } from "@revit-order/shared/src/material-list";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@revit-order/client-infra";
import { Project, Texts, MaterialList as ML, Utils, Markets, UserSettings } from "@revit-order/shared";
import { selectHideZeroQuantityRows, selectShowImages } from "@revit-order/shared/src/user-settings";
import { ExportResponse } from "@revit-order/shared/src/crm";
import * as State from "./state";
import {
  Alert,
  Button,
  Checkbox,
  Icon,
  NumberField,
  Textfield,
  Expander,
  AcceptDeclineTextField,
  ConfirmRemoveButton,
  ExpanderTBody,
  Label,
  SmallCheckbox,
} from "../../../../elements";
import { Action } from ".";
import { ImageLightbox } from "../../../../elements/imageLightbox";
import { clientConfig } from "../../../../client-config";
import * as ProjectState from "../../project-state";

const texts = Texts.texts;

const ProjectAction = ProjectState.Action;

const categoryRowColSpan = 7;

export function View(props: {
  readonly state: State.State;
  readonly projectState: ProjectState.State;
  readonly sharedState: SharedState.SharedState;
  readonly crmExportResponse: ExportResponse | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element {
  const { state, projectState, sharedState, crmExportResponse, dispatch, dispatchProject } = props;
  const { translate } = sharedState;
  const { project, projectIsBusy, materialTables } = projectState;
  const hasMaterials = project.materialLists.some((s) => s.materials.length > 0);
  const isReadonly = Project.isProjectReadOnly(project) || projectState.projectIsBusy;

  const metaDatas = project.materialLists[0].materials.map((e) => getMetaData(materialTables, e));

  const categoryNames = R.uniq(metaDatas.map((meta) => createCategoryName(meta.group, meta.category)));

  const allChecked = categoryNames.every((e) => selectHideZeroQuantityRows(sharedState.userSettings, e));

  return (
    <div className="flex flex-col space-y-16">
      {ImageLightbox({
        mainSrc: state.openLightbox.imgUrl,
        isOpen: state.openLightbox.isOpen,
        close: () => dispatch(Action.OpenLightbox(false, "")),
      })}

      <div className="flex flex-row space-x-8">
        {sharedState.crmParams && (
          <Button
            type="secondary"
            label={translate(texts.export_to_crm)}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(false))}
            disabled={projectIsBusy || !hasMaterials}
          />
        )}

        {sharedState.crmParams && UserSettings.selectShowAdvanced(sharedState.userSettings) && (
          <Button
            type="secondary"
            label={"Download CRM envelope"}
            onClick={() => dispatchProject(ProjectState.Action.ExportToCrm(true))}
            disabled={projectIsBusy || !hasMaterials}
          />
        )}
      </div>
      {crmExportResponse && crmExportResponse.status === 200 ? (
        <Alert type="success">
          <span>{translate(texts.sucessfully_exported_to_crm)}</span>
        </Alert>
      ) : crmExportResponse && crmExportResponse.status !== 200 ? (
        <Alert type="danger">
          <p>{translate(texts.failed_to_export_crm_message)}</p>
          <p>{crmExportResponse.message}</p>
        </Alert>
      ) : undefined}

      <Checkbox
        checked={allChecked}
        label={translate(texts.hide_zero_quantity_rows)}
        onChange={(checked) => dispatch(Action.SetHideZeroQuantityRows(checked, categoryNames))}
      />
      {project.materialLists.map((ml) => (
        <MaterialList
          key={ml.id}
          materialList={ml}
          expandedItems={state.expandedItems}
          numberOfMaterialLists={project.materialLists.length}
          isReadonly={isReadonly}
          translate={translate}
          dispatch={dispatch}
          dispatchProject={dispatchProject}
          materialTables={projectState.materialTables}
          market={sharedState.market}
          userSettings={sharedState.userSettings}
          disabledCustomMaterials={projectState.disabledCustomMaterials}
        />
      ))}
    </div>
  );
}

function MaterialList({
  materialList,
  numberOfMaterialLists,
  isReadonly,
  translate,
  dispatch,
  dispatchProject,
  materialTables,
  expandedItems,
  market,
  userSettings,
  disabledCustomMaterials,
}: {
  readonly materialList: Project.MaterialList;
  readonly numberOfMaterialLists: number;
  readonly isReadonly: boolean;
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly materialTables: ML.MaterialTables;
  readonly expandedItems: ReadonlySet<string>;
  readonly market: Markets.Market;
  readonly userSettings: UserSettings.UserSettings;
  readonly disabledCustomMaterials: ReadonlySet<string>;
}): JSX.Element {
  const validationResult = ML.validate(materialTables, materialList);

  return (
    <Expander
      header={
        expandedItems.has(`${materialList.id}-rename`) ? (
          <AcceptDeclineTextField
            value={materialList.name || ""}
            onAccept={(v: string) => {
              dispatchProject(ProjectAction.UpdateMaterialList({ id: materialList.id, name: v || "" }));
              dispatch(Action.ToggleExpanded(`${materialList.id}-rename`));
            }}
            disabled={isReadonly}
            onDecline={() => dispatch(Action.ToggleExpanded(`${materialList.id}-rename`))}
          />
        ) : (
          materialList.name
        )
      }
      closed={expandedItems.has(materialList.id)}
      onToggleClosed={() => dispatch(Action.ToggleExpanded(materialList.id))}
      extraHeader={
        !expandedItems.has(`${materialList.id}-rename`) && (
          <div className="flex flex-row space-x-24 items-center">
            <Icon
              icon={"edit"}
              onClick={() => dispatch(Action.ToggleExpanded(`${materialList.id}-rename`))}
              message={translate(Texts.texts.rename_material_list)}
              disabled={isReadonly}
            />
            <ConfirmRemoveButton
              disabled={isReadonly || numberOfMaterialLists <= 1}
              oneLiner={true}
              onClick={() => dispatchProject(ProjectAction.RemoveMaterialList(materialList.id))}
              confirmMessage={translate(Texts.texts.confirm_delete_material_list)}
              removeText={translate(Texts.texts.remove_material_list)}
              cancelText={translate(Texts.texts.cancel)}
              iconMessage={translate(Texts.texts.remove_material_list)}
            />
            <Icon
              icon={"copy"}
              key={"duplicate"}
              disabled={isReadonly}
              onClick={() => dispatchProject(ProjectAction.DuplicateMaterialList(materialList.id))}
              message={translate(Texts.texts.duplicate_material_list)}
            />
          </div>
        )
      }
    >
      <div className="flex flex-row space-x-24 items-center mt-8">
        <Button
          disabled={isReadonly}
          label={translate(texts.apply_zoom_rule)}
          onClick={() => dispatchProject(ProjectAction.ApplyZoomRule(materialList.id))}
        />
        <MultiplyQuantities
          translate={translate}
          readOnly={isReadonly}
          dispatchProject={dispatchProject}
          materialListId={materialList.id}
        />
        {validationResult.errors.some((e) => e.category === "new_item_available") && (
          <Button
            disabled={isReadonly}
            label={translate(texts.add_new_items)}
            onClick={() => dispatchProject(ProjectAction.AddNewMaterialsMaterialList(materialList.id))}
          />
        )}
      </div>

      <div className="mt-8 mb-8">
        <Messages translate={translate} errors={validationResult.errors} />
      </div>

      <MaterialListTable
        isReadonly={isReadonly}
        materialList={materialList}
        dispatch={dispatch}
        dispatchProject={dispatchProject}
        translate={translate}
        materialTables={materialTables}
        validationResult={validationResult}
        market={market}
        userSettings={userSettings}
        disabledCustomMaterials={disabledCustomMaterials}
        expandedItems={expandedItems}
      />
    </Expander>
  );
}

function Messages({
  translate,
  errors,
}: {
  readonly translate: Texts.TranslateFn;
  readonly errors: ReadonlyArray<ML.ValidationError>;
}): JSX.Element {
  const alerts = [];
  const listErrors = errors.filter((e) => e.materialId === undefined && e.category);
  alerts.push(
    ...listErrors.map((e) => (
      <Alert key={e.category} type="info">
        <span>{translate(e.message)}</span>
      </Alert>
    ))
  );
  return <div className="space-y-8">{alerts}</div>;
}

function MaterialListTable({
  isReadonly,
  materialList,
  dispatch,
  dispatchProject,
  translate,
  materialTables,
  validationResult,
  market,
  userSettings,
  disabledCustomMaterials,
  expandedItems,
}: {
  readonly isReadonly: boolean;
  readonly materialList: Project.MaterialList;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly materialTables: ML.MaterialTables;
  readonly validationResult: ML.ValidationResult;
  readonly market: Markets.Market;
  readonly userSettings: UserSettings.UserSettings;
  readonly disabledCustomMaterials: ReadonlySet<string>;
  readonly expandedItems: ReadonlySet<string>;
}): JSX.Element {
  const zoomRuleAffectedIds = new Set(
    ML.getMaterialsAffectedByRoomRule(materialTables, materialList.materials).map((m) => m.id)
  );
  if (materialList.materials.length === 0) {
    return <div>{translate(texts.material_list_empty)}</div>;
  }

  const categories = ML.categorizeMaterials(materialTables, materialList.materials, true);
  const categoryExpandKeys = categories.map((c) => `${materialList.id}_${c.name}`);
  const allClosed = categoryExpandKeys.every((c) => !expandedItems.has(c));
  return (
    <table className="relative">
      <thead className="sticky top-0 bg-white ">
        <tr>
          <th className="w-52">
            <Icon
              className="mx-8"
              message={allClosed ? translate(texts.expand_all_categories) : translate(texts.collapse_all_categories)}
              icon={allClosed ? "square-plus" : "square-minus"}
              onClick={() =>
                allClosed
                  ? categoryExpandKeys.forEach((c) => dispatch(Action.ToggleExpanded(c)))
                  : categoryExpandKeys.forEach((c) => expandedItems.has(c) && dispatch(Action.ToggleExpanded(c)))
              }
            />
          </th>
          <th className="w-85">{translate(texts.position)}</th>
          <th className="w-40" />
          <th className="w-104">{translate(texts.quantity)}</th>
          {selectShowImages(userSettings) ? <th className="w-44 min-w-32"></th> : null}
          <th className="w-104">{translate(texts.item_number)}</th>
          <th>{translate(texts.name)}</th>
        </tr>
        {/* Fix border when sticky */}
        <tr className="max-h-0 border-b-4 border-gray-100" />
      </thead>
      {categories.map((category, index) => (
        <MaterialListCategory
          hideZeroQuantityRows={selectHideZeroQuantityRows(userSettings, category.name)}
          key={category.name}
          expanded={expandedItems.has(`${materialList.id}_${category.name}`)}
          market={market}
          materialTables={materialTables}
          isReadonly={isReadonly}
          materialListId={materialList.id}
          dispatch={dispatch}
          dispatchProject={dispatchProject}
          translate={translate}
          validationResult={validationResult}
          category={category}
          showImages={selectShowImages(userSettings)}
          index={index}
          disabledCustomMaterials={disabledCustomMaterials}
          zoomRuleAffectedIds={zoomRuleAffectedIds}
        />
      ))}
    </table>
  );
}

function MaterialListCategory({
  materialTables,
  isReadonly,
  materialListId,
  dispatch,
  dispatchProject,
  translate,
  validationResult,
  category,
  showImages,
  disabledCustomMaterials,
  expanded,
  zoomRuleAffectedIds,
  hideZeroQuantityRows,
}: {
  readonly market: Markets.Market;
  readonly materialTables: ML.MaterialTables;
  readonly isReadonly: boolean;
  readonly materialListId: string;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly validationResult: ML.ValidationResult;
  readonly category: ML.Category;
  readonly showImages: boolean;
  readonly index: number;
  readonly disabledCustomMaterials: ReadonlySet<string>;
  readonly expanded: boolean;
  readonly zoomRuleAffectedIds: ReadonlySet<string>;
  readonly hideZeroQuantityRows: boolean;
}): JSX.Element {
  const materials = hideZeroQuantityRows
    ? category.materials.filter((m) => m.quantity > 0 || m.included || m.type === "custom")
    : category.materials;

  const numMaterials = materials.filter((m) => m.included).length;

  return (
    <ExpanderTBody
      extraHeader={
        expanded && (
          <SmallCheckbox
            checked={hideZeroQuantityRows}
            label={translate(texts.hide_zero_quantity_rows)}
            onChange={(checked) => dispatch(Action.SetHideZeroQuantityRows(checked, [category.name]))}
          />
        )
      }
      colSpan={categoryRowColSpan}
      header={`${translate(Texts.texts.material_category(category.name))}${
        numMaterials > 0 && !expanded ? ` (${numMaterials})` : ""
      }`}
      closed={!expanded}
      onToggleClosed={() => dispatch(Action.ToggleExpanded(`${materialListId}_${category.name}`))}
    >
      {materials.map((material) => (
        <MaterialRow
          key={material.id}
          material={material}
          materialTables={materialTables}
          isReadonly={isReadonly || disabledCustomMaterials.has(material.id)}
          materialListId={materialListId}
          dispatch={dispatch}
          dispatchProject={dispatchProject}
          translate={translate}
          validationResult={validationResult}
          showImages={showImages}
          affectedByZoomRule={zoomRuleAffectedIds.has(material.id)}
        />
      ))}
      {category.name === ML.customItemsCategoryName && (
        <tr>
          <td colSpan={categoryRowColSpan}>
            <div className="flex flex-row space-x-32">
              <Button
                disabled={isReadonly}
                label={translate(texts.add_custom_item)}
                onClick={() => dispatchProject(ProjectAction.AddCustomMaterial(materialListId))}
              />
            </div>
          </td>
        </tr>
      )}
    </ExpanderTBody>
  );
}

function MaterialRow({
  material,
  materialTables,
  isReadonly,
  materialListId,
  dispatch,
  dispatchProject,
  translate,
  validationResult,
  showImages,
  affectedByZoomRule,
}: {
  readonly material: Project.Material;
  readonly materialTables: ML.MaterialTables;
  readonly isReadonly: boolean;
  readonly materialListId: string;
  readonly dispatch: Dispatch<State.Action>;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly translate: Texts.TranslateFn;
  readonly validationResult: ML.ValidationResult;
  readonly showImages: boolean;
  readonly affectedByZoomRule: boolean;
}): JSX.Element {
  const meta = ML.getMetaData(materialTables, material);
  const error = validationResult.errorsById[material.id];
  const className = "p-0";
  return (
    <tr key={material.id} className="h-40">
      <td className={className}>
        {error ? (
          <Icon
            icon={error.category === "item_has_been_removed" ? "triangle-exclamation" : "exclamation-circle"}
            colorClass={error.category === "item_has_been_removed" ? "text-warning" : "text-danger"}
            message={translate(error.message)}
          />
        ) : undefined}
      </td>
      <td className={`${className} tabular-nums`}>{material.sortNo}</td>
      <td className={className}>
        <Checkbox
          disabled={isReadonly}
          checked={material.included}
          onChange={(checked) =>
            dispatchProject(
              ProjectAction.UpdateMaterialListItem(materialListId, { id: material.id, included: checked })
            )
          }
        />
      </td>

      <td className={className}>
        <div className="flex flex-row items-center justify-between">
          {meta.readOnlyQuantity ? (
            <span className="tabular-nums">{Utils.numberToString(material.quantity, 4)}</span>
          ) : (
            <div className="flex flex-row space-x-8 items-center">
              <NumberField
                className="w-52"
                readOnly={isReadonly}
                value={material.quantity}
                errorMessage={undefined}
                decimals={4}
                onChange={(v) =>
                  dispatchProject(
                    ProjectAction.UpdateMaterialListItem(materialListId, { id: material.id, quantity: v })
                  )
                }
              />
              {affectedByZoomRule && !isReadonly && (
                <Icon
                  message={translate(texts.zoom_rule_item_note)}
                  className="text-gray-400"
                  size="xs"
                  prefix="fas"
                  icon="circle"
                />
              )}
            </div>
          )}
          {material.type === "custom" && (
            <Icon
              icon={"trash-alt"}
              disabled={isReadonly}
              onClick={() => {
                dispatchProject(ProjectAction.RemoveMaterial(materialListId, material.id));
              }}
              message={translate(texts.remove_custom_material)}
              className="mr-8"
            />
          )}
        </div>
      </td>

      {showImages ? (
        <td className={className}>
          {material.itemNumber && (
            <img
              src={`${clientConfig.image_service_url}?itemNumber=${material.itemNumber}&maxWidth=50&maxHeight=50&format=jpg`}
              className="h-24 pr-8"
              style={{ cursor: "pointer" }}
              alt={""}
              onClick={() => {
                dispatch(
                  Action.OpenLightbox(
                    true,
                    `${clientConfig.image_service_url}?itemNumber=${material.itemNumber}&maxWidth=1000&maxHeight=1000&format=jpg`
                  )
                );
              }}
            />
          )}
        </td>
      ) : null}
      {material.type === "custom" ? (
        <td className={className}>
          <Textfield
            isRequiredMessage={translate(texts.item_number_required)}
            readOnly={isReadonly}
            value={material.itemNumber || ""}
            onChange={(v) => dispatchProject(ProjectAction.UpdateCustomMaterial(materialListId, material.id, v))}
            className="mr-8"
          />
        </td>
      ) : (
        <td className={`${className} tabular-nums`}>{ML.getItemNumber(material)}</td>
      )}

      {material.type === "custom" ? (
        <td className={className}>
          <Textfield
            readOnly={isReadonly}
            className="mr-8"
            value={material.name || ""}
            onChange={(v) =>
              dispatchProject(ProjectAction.UpdateMaterialListItem(materialListId, { id: material.id, name: v }))
            }
          />
        </td>
      ) : (
        <ItemNameTd className={className} translate={translate} material={material} />
      )}
    </tr>
  );
}

function ItemNameTd({
  className,
  material,
  translate,
}: {
  readonly className: string;
  readonly material: Project.Material;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  return (
    <td className={className}>
      <div className="flex flex-row justify-between items-center">
        <div>
          <div>{ML.getItemName(translate, material)}</div>
          <div className="text-neutral-700">{ML.getItemDescription(translate, material)}</div>
        </div>
      </div>
    </td>
  );
}

function MultiplyQuantities({
  materialListId,
  translate,
  readOnly,
  dispatchProject,
}: {
  readonly materialListId: string;
  readonly translate: Texts.TranslateFn;
  readonly readOnly: boolean;
  readonly dispatchProject: Dispatch<ProjectState.Action>;
}): JSX.Element | null {
  const [showInput, setShowInput] = React.useState(false);
  const [multiple, setMultiple] = React.useState<number | undefined>(undefined);
  return (
    <div className="flex flex-row space-x-8 items-center">
      {showInput && !readOnly ? (
        <>
          <Label>{translate(texts.enter_quantity_multiplier)}</Label>
          <div className="max-w-64">
            <NumberField
              readOnly={readOnly}
              value={multiple}
              onChange={(v) => setMultiple(v)}
              decimals={0}
              showSpinButton={true}
              step={1}
            />
          </div>
          <Button
            label={translate(texts.cancel)}
            onClick={() => {
              setShowInput(false);
              setMultiple(undefined);
            }}
            type="secondary"
          />
          <Button
            label={translate(texts.multiply)}
            disabled={!multiple || multiple < 2}
            onClick={() => {
              setShowInput(false);
              setMultiple(undefined);
              multiple && dispatchProject(ProjectAction.MultiplyMaterialQuantities(materialListId, multiple));
            }}
          />
        </>
      ) : (
        <Button label={translate(texts.multiply_quantities)} onClick={() => setShowInput(true)} disabled={readOnly} />
      )}
    </div>
  );
}
