import * as GraphQL from "graphql";
import { Dispatch, EffectManager } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GraphQLSimpleFetch from "graphql-simple-fetch";
import { GraphqlFetchCmd, GraphQLResponse, mapCmd } from "./graphql-fetch-cmd";
import { home } from "./home";
import { mapSub } from "./graphql-cache-sub";

export function createEffectManager(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub,
    setup: () => () => undefined,
    onEffects,
    onSelfAction,
  };
}

export interface State<_ignoredA> {}

export function onEffects<A>(
  dispatchApp: Dispatch<A>,
  dispatchSelf: never,
  cmds: ReadonlyArray<GraphqlFetchCmd<A>>,
  _subs: never,
  state: State<A>
): State<A> {
  // Handle commands
  let newState: State<A> = state;
  for (const cmd of cmds) {
    newState = handleCommand(dispatchApp, dispatchSelf, cmd, newState);
  }
  return state;
}

export function onSelfAction<A>(
  _dispatchApp: Dispatch<A>,
  _dispatchSelf: Dispatch<never>,
  _action: never,
  state: State<A>
): State<A> {
  return state;
}

function handleCommand<A>(
  dispatchApp: Dispatch<A>,
  _dispatchSelf: Dispatch<never>,
  cmd: GraphqlFetchCmd<A>,
  state: State<A>
): State<A> {
  switch (cmd.type) {
    case "Dummy": {
      return state;
    }
    case "FetchGraphl": {
      try {
        fetchWithHeadersPromise(cmd.operation, cmd.variables, cmd.headers).then((response) => {
          if (cmd.gotResponse) {
            dispatchApp(cmd.gotResponse({ type: "SuccessResponse", response }));
          }
        });
      } catch (e) {
        if (cmd.gotResponse) {
          dispatchApp(cmd.gotResponse({ type: "ErrorResponse", message: e.message }));
        }
      }
      return state;
    }
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}

async function fetchWithHeadersPromise<TData = {}, TVariables = {}, TExtensions = {}>(
  query: GraphQL.DocumentNode,
  variables: TVariables | undefined,
  headers: { readonly [header: string]: string }
): Promise<GraphQLResponse<TData, TExtensions>> {
  // If it is not a string, then get the string from the AST
  const queryString = typeof query === "string" ? query : GraphQL.print(query);
  const response = await GraphQLSimpleFetch.rawRequest<TData>(
    "/graphql",
    {
      credentials: "same-origin",
      headers: headers,
    },
    queryString,
    variables as unknown as {
      readonly [key: string]: unknown;
    }
  );
  return response as unknown as GraphQLResponse<TData, TExtensions>;
}
