import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export interface IconProps {
  readonly onClick?: () => void;
  readonly icon: IconName;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly colorClass?: string;
  readonly message?: string;
  readonly size?: SizeProp;
  readonly prefix?: "fal" | "far" | "fas";
}

export function Icon({
  disabled,
  onClick,
  colorClass,
  className,
  message,
  icon,
  size = "lg",
  prefix = "fal",
}: IconProps): React.ReactElement<IconProps> {
  const divClassName = `
    ${disabled ? "text-gray-400" : onClick ? "cursor-pointer" : ""} 
    ${colorClass ?? ""} 
    ${className ?? ""}`;
  return (
    <div
      className={divClassName}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      title={message}
    >
      <FontAwesomeIcon icon={[prefix, icon]} size={size} />
    </div>
  );
}
