import { DocumentNode } from "graphql";

export type GraphQlQueryFn = <TData, TVariable>(
  query: DocumentNode,
  variables: TVariable
) => Promise<TData | undefined>;

export type Row<T> = Omit<T, "__typename" | "builtin_id" | "sort_no">;
export type RowWithSortNo<T> = Omit<T, "__typename" | "builtin_id">;
export type Table<T> = ReadonlyArray<T>;

export type NoNull<T> = {
  readonly [P in keyof T]: Exclude<T[P], null>;
};

export function isNoNull<T>(row: NoNull<T> | T): row is NoNull<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(row as {}).every((key) => (row as any)[key] !== null);
}
