// Getting a user from the OIDC token is managed in this file
// We need to handle creating a User from the token in the Client and Server
// The token looks a little different but the information in it is the same

export type ActiveUser = {
  readonly externalId: string;
  readonly userName: string;
  readonly email: string;
  readonly idToken: string;
  readonly accessToken: string;
  readonly isAdUser?: boolean;
  readonly claims: { readonly [key: string]: string };
};

export interface UntypedToken {
  readonly [key: string]: string;
}
export type DecodedToken = UntypedToken;

export type ActiveUserError = {
  readonly type: "error";
  readonly reason: string;
};

interface ClientToken {
  readonly id_token: string;
  readonly session_state?: unknown;
  readonly access_token: string;
  readonly token_type: string;
  readonly scope: string;
  readonly profile: unknown;
  readonly expires_at: number;
  readonly state: unknown;
  readonly expires_in: number | undefined;
  readonly expired: boolean | undefined;
  readonly scopes: readonly string[];
}
type ServerToken = { readonly [key: string]: string };

export function buildActiveUser(token: ClientToken | ServerToken, accessToken: string): ActiveUser | ActiveUserError {
  if (isClientToken(token)) {
    const claims = token.profile as UntypedToken;

    if (!isAdUser(claims)) {
      return {
        type: "error",
        reason: "You do not have access to this application",
      };
    }

    return {
      externalId: claims.sub,
      userName: claims["name"],
      email: claims["email"],
      accessToken: accessToken,
      isAdUser: claims["sys_sso_ad_user"]?.toString() === "true",
      claims: claims,
      idToken: token.id_token,
    };
  } else {
    if (!isAdUser(token)) {
      return {
        type: "error",
        reason: "You do not have access to this application",
      };
    }
    return {
      externalId: token["sub"],
      userName: token["name"],
      email: token["email"],
      accessToken: accessToken,
      isAdUser: token["sys_sso_ad_user"]?.toString() === "true",
      claims: token,
      idToken: "",
    };
  }
}

export function isValidUser(activeUser: ActiveUser | ActiveUserError): activeUser is ActiveUser {
  if ((activeUser as ActiveUserError).type !== undefined) {
    return false;
  }

  return true;
}

function isAdUser(claims: { readonly [key: string]: string }): boolean {
  return claims["sys_sso_ad_user"]?.toString() === "true";
}

function isClientToken(token: ClientToken | ServerToken): token is ClientToken {
  if ((token as ClientToken).profile !== undefined) {
    return true;
  }

  return false;
}
