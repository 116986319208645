import * as React from "react";
import { Select } from "./base-elements";

export type SelectOption<T> = T & { readonly id: string; readonly name: string };

export interface SelectProps<T> {
  readonly value: SelectOption<T>;
  readonly options: ReadonlyArray<SelectOption<T>>;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly errorMessage?: string;
  readonly onChange: (value: SelectOption<T>) => void;
}

export function Selector<T>(props: SelectProps<T>): React.ReactElement<SelectProps<T>> {
  const { options, value, onChange, disabled, readOnly, errorMessage } = props;
  const selectOptions = !value || !options.some((o) => o.id === value.id) ? [value, ...options] : options;
  const isDisabled = disabled || options.length <= 1;
  return readOnly ? (
    <span>{value.name}</span>
  ) : (
    <Select
      isValid={!errorMessage}
      title={errorMessage}
      value={value?.id}
      onChange={(e) => {
        if (!isDisabled) {
          const value = options.find((o) => o.id === e.target.value);
          if (value) {
            onChange(value);
          }
        }
      }}
      disabled={isDisabled}
    >
      {selectOptions.map((option) => (
        <option className="bg-white" key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  );
}
