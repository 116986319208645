import React, { useState } from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState, createBlobUrlCreator, Routes } from "@revit-order/client-infra";
import { Markets, Texts, UserSettings, appInfo, User } from "@revit-order/shared";
import { clientConfig } from "../client-config";
//import { Select } from "../components/elements";
//import * as GQLOps from "../generated/generated-operations";
import * as State from "./state";
import { Button, Icon, Selector } from "../elements";
import { Action } from "./state";

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const translate = sharedState.translate;
  //  const currentLanguage = sharedState.lang.code;
  const [showInfo, setShowInfo] = useState(false);

  const logoImage = state?.metaProduct?.product?.modules.images.image.find((i) => i.name === "systemair-logo");
  const logoUrl =
    logoImage && logoImage.image && logoImage.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(logoImage.image, logoImage.file_name)
      : undefined;

  return (
    <div className="w-full flex flex-row space-x-40 justify-between border-b p-8">
      <a
        href={Routes.buildUrl(
          Routes.RootLocation.MainLocation(Routes.MainLocation.ProjectList(Routes.ProjectListLocation.ProjectList()))
        )}
      >
        {logoUrl ? <img src={logoUrl} className="max-h-24" /> : undefined}
      </a>

      <div className="flex flex-row justify-end space-x-8 items-center ">
        <div className="flex flex-row space-x-8 max-h-24">
          <div className="leading-[30px]">{sharedState.activeUser.userName}</div>

          <MarketSettings
            marketTable={state?.metaProduct?.product?.modules?.custom_tables.Market}
            languageTable={state?.metaProduct?.product?.modules?.languages}
            dispatch={dispatch}
            userSettings={sharedState.userSettings}
            activeUser={sharedState.activeUser}
          />
          <a href={Routes.buildUrl(Routes.RootLocation.MainLocation(Routes.MainLocation.UserSettings()))}>
            <Button type={"primary"} label={translate(Texts.texts.user_settings)} />
          </a>
          <Button
            type={"secondary"}
            label={translate(Texts.texts.logout)}
            onClick={() => dispatch(State.Action.Logout())}
          />
        </div>

        <div className="flex flex-row" onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? (
            <div className="flex flex-col">
              <small>Tag: {appInfo.tag}</small>
              <small>PipelineId: {appInfo.pipelineId}</small>
              <small>Marker: {clientConfig.promaster_marker}</small>
            </div>
          ) : (
            <Icon icon="info-circle" colorClass="text-primary" />
          )}
        </div>
      </div>
    </div>
  );
}

function MarketSettings({
  marketTable,
  languageTable,
  dispatch,
  userSettings,
  activeUser,
}: {
  readonly marketTable: Markets.MarketTable | undefined;
  readonly languageTable: Texts.LanguageTable | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly userSettings: UserSettings.UserSettings;
  readonly activeUser: User.ActiveUser;
}): JSX.Element {
  if (!marketTable || !languageTable) {
    return <div />;
  }

  const language = UserSettings.selectLanguage(userSettings);
  const market = UserSettings.selectMarket(userSettings);
  const langugesOptions = Texts.getLanguages(languageTable).map((l) => ({
    id: l,
    name: l,
    language: l,
  }));
  const marketsOptions = Markets.getMarkets(marketTable, activeUser).map((m) => ({
    id: m.name,
    name: m.name,
    market: m,
  }));

  return (
    <>
      <Selector
        options={marketsOptions}
        value={marketsOptions.find((o) => o.id === market) || marketsOptions[0]}
        onChange={(o) => dispatch(Action.ChangeMarket(o.market.name))}
      />
      <Selector
        options={langugesOptions}
        value={langugesOptions.find((o) => o.id === language) || langugesOptions[0]}
        onChange={(o) => dispatch(Action.ChangeLanguage(o.language))}
      />
    </>
  );
}
