import { Project, MaterialList, Material } from "../types";

export function addMaterialList(project: Project, materialList: MaterialList): Project {
  return {
    ...project,
    materialLists: [...project.materialLists, materialList],
  };
}

export function removeMaterialList(project: Project, materialListId: string): Project {
  return {
    ...project,
    materialLists: project.materialLists.filter((s) => s.id !== materialListId),
  };
}

export function replaceMaterialList(project: Project, materialList: MaterialList): Project {
  return {
    ...project,
    materialLists: project.materialLists.map((s) => (s.id === materialList.id ? materialList : s)),
  };
}

export function addMaterials(project: Project, materialListId: string, materials: ReadonlyArray<Material>): Project {
  const materialList = project.materialLists.find((s) => s.id === materialListId);
  if (!materialList) {
    return project;
  }
  const newMaterialList: MaterialList = {
    ...materialList,
    materials: [...materialList.materials, ...materials].sort((a, b) => a.sortNo - b.sortNo),
  };
  return {
    ...project,
    materialLists: project.materialLists.map((s) => (s.id === materialListId ? newMaterialList : s)),
  };
}

export function removeMaterials(project: Project, materialListId: string, materialIds: ReadonlyArray<string>): Project {
  const materialList = project.materialLists.find((s) => s.id === materialListId);
  if (!materialList) {
    return project;
  }
  const newMaterialList: MaterialList = {
    ...materialList,
    materials: materialList.materials.filter((m) => !materialIds.some((idToRemove) => m.id === idToRemove)),
  };
  return {
    ...project,
    materialLists: project.materialLists.map((s) => (s.id === materialListId ? newMaterialList : s)),
  };
}

export function replaceMaterials(
  project: Project,
  materialListId: string,
  materials: ReadonlyArray<Material>
): Project {
  const materialList = project.materialLists.find((s) => s.id === materialListId);
  if (!materialList) {
    return project;
  }
  const newMaterialList: MaterialList = {
    ...materialList,
    materials: materialList.materials
      .map((m) => {
        const updatedMaterial = materials.find((p) => p.id === m.id);
        return updatedMaterial ? updatedMaterial : m;
      })
      .sort((a, b) => a.sortNo - b.sortNo),
  };
  return {
    ...project,
    materialLists: project.materialLists.map((s) => (s.id === materialListId ? newMaterialList : s)),
  };
}
