import { graphQLMutationWithAuth, patchResponse, SharedState, variablesFromPatch } from "@revit-order/client-infra";
import { Cmd } from "@typescript-tea/core";
import { Project } from "@revit-order/shared";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function UpdateMaterialList(
  action: ReturnType<typeof Action.UpdateMaterialList>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const materialList = state.project.materialLists.find((s) => s.id === action.patch.id);
  if (!materialList) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const updatedProject = Project.replaceMaterialList(state.project, patchResponse(materialList, action.patch));
  const updateMaterialListCmd = graphQLMutation<
    GQLOps.ProjectState_UpdateMaterialListMutation,
    GQLOps.ProjectState_UpdateMaterialListMutationVariables,
    Action
  >(M.updateMaterialListMutation, variablesFromPatch(action.patch.id, action.patch), Action.NoOp);
  return [{ ...state, project: updatedProject }, updateMaterialListCmd];
}

export function DuplicateMaterialList(
  action: ReturnType<typeof Action.DuplicateMaterialList>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const materialList = state.project.materialLists.find((s) => s.id === action.materialListId);
  if (!materialList || state.projectIsBusy) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const duplicateMaterialListCmd = graphQLMutation<
    GQLOps.ProjectState_DuplicateMaterialListMutation,
    GQLOps.ProjectState_DuplicateMaterialListMutationVariables,
    Action
  >(
    M.duplicateMaterialListMutation,
    {
      input: {
        id: materialList.id,
      },
    },
    (res) => Action.DuplicateMaterialListPostAction(res, materialList.id)
  );
  return [{ ...state, projectIsBusy: true }, duplicateMaterialListCmd];
}

export function DuplicateMaterialListPostAction(
  action: ReturnType<typeof Action.DuplicateMaterialListPostAction>,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;
  const newMaterialList = action.newMaterialList.duplicateMaterialList.materialList;
  const updatedProject = Project.addMaterialList(project, newMaterialList);
  return [
    {
      ...state,
      project: updatedProject,
      projectIsBusy: false,
    },
  ];
}

export function RemoveMaterialList(
  action: ReturnType<typeof Action.RemoveMaterialList>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const materialList = state.project.materialLists.find((s) => s.id === action.materialListId);
  if (!materialList) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const updatedProject = Project.removeMaterialList(state.project, action.materialListId);
  return [
    { ...state, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_RemoveMaterialListMutation,
      GQLOps.ProjectState_RemoveMaterialListMutationVariables,
      Action
    >(
      M.removeMaterialListMutation,
      {
        input: {
          id: action.materialListId,
        },
      },
      Action.NoOp
    ),
  ];
}

export function MultiplyMaterialQuantities(
  action: ReturnType<typeof Action.MultiplyMaterialQuantities>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (
    !state.project.materialLists.some((s) => s.id === action.materialListId) ||
    state.projectIsBusy ||
    action.multiplier < 2
  ) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  return [
    { ...state, projectIsBusy: true },
    graphQLMutation<
      GQLOps.ProjectState_MultiplyQuantitiesMaterialListMutation,
      GQLOps.ProjectState_MultiplyQuantitiesMaterialListMutationVariables,
      Action
    >(
      M.multiplyQuantitiesMaterialListMutation,
      {
        input: {
          id: action.materialListId,
          multiplier: action.multiplier,
        },
      },
      (res) => Action.MultiplyMaterialQuantitiesPostAction(res)
    ),
  ];
}

export function MultiplyMaterialQuantitiesPostAction(
  action: ReturnType<typeof Action.MultiplyMaterialQuantitiesPostAction>,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...state,
      projectIsBusy: false,
      project: {
        ...state.project,
        materialLists: state.project.materialLists.map((ml) =>
          ml.id === action.response.multiplyQuantitiesMaterialList.materialList.id
            ? action.response.multiplyQuantitiesMaterialList.materialList
            : ml
        ),
      },
    },
  ];
}

export function ApplyZoomRule(
  action: ReturnType<typeof Action.ApplyZoomRule>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (!state.project.materialLists.some((s) => s.id === action.materialListId) || state.projectIsBusy) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  return [
    { ...state, projectIsBusy: true },
    graphQLMutation<
      GQLOps.ProjectState_ApplyZoomRuleMaterialListMutation,
      GQLOps.ProjectState_ApplyZoomRuleMaterialListMutationVariables,
      Action
    >(
      M.applyZoomRuleMaterialListMutation,
      {
        input: {
          id: action.materialListId,
        },
      },
      (res) => Action.ApplyZoomRulePostAction(res)
    ),
  ];
}

export function ApplyZoomRulePostAction(
  action: ReturnType<typeof Action.ApplyZoomRulePostAction>,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...state,
      projectIsBusy: false,
      project: {
        ...state.project,
        materialLists: state.project.materialLists.map((ml) =>
          ml.id === action.response.applyZoomRuleMaterialList.materialList.id
            ? action.response.applyZoomRuleMaterialList.materialList
            : ml
        ),
      },
    },
  ];
}

export function AddNewMaterialsMaterialList(
  action: ReturnType<typeof Action.AddNewMaterialsMaterialList>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (!state.project.materialLists.some((s) => s.id === action.materialListId) || state.projectIsBusy) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  return [
    { ...state, projectIsBusy: true },
    graphQLMutation<
      GQLOps.ProjectState_AddNewMaterialsMaterialListMutation,
      GQLOps.ProjectState_AddNewMaterialsMaterialListMutationVariables,
      Action
    >(
      M.addNewMaterialsMaterialListMutation,
      {
        input: {
          id: action.materialListId,
        },
      },
      (res) => Action.AddNewMaterialsMaterialListPostAction(res)
    ),
  ];
}

export function AddNewMaterialsMaterialListPostAction(
  action: ReturnType<typeof Action.AddNewMaterialsMaterialListPostAction>,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...state,
      projectIsBusy: false,
      project: {
        ...state.project,
        materialLists: state.project.materialLists.map((ml) =>
          ml.id === action.response.addNewMaterialsMaterialList.materialList.id
            ? action.response.addNewMaterialsMaterialList.materialList
            : ml
        ),
      },
    },
  ];
}
