import React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as Main from "../main";
import { Button, H1, Spinner } from "../elements";
import { ErrorBoundary } from "./error-boundary";

interface Props {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
}

export function view({ state, dispatch }: Props): JSX.Element {
  if (state.type === "ErrorState") {
    return (
      <div className="text-sm">
        <div className="max-w-screen-md mx-auto mt-104">
          <H1>An error occurred</H1>
          <p>{state.reason}</p>
          <p>Press the button below to reload the page and try again.</p>
          <div>
            <Button large={true} onClick={() => window.location.reload()} iconLeft="refresh" label="Reload" />
          </div>
        </div>
      </div>
    );
  }

  if (state.type === "LoadingDataState" || state.type === "LoadingUserSettingsState") {
    return <Spinner />;
  }

  if (state.type === "WaitingForUserSessionState") {
    return <Spinner />;
  }

  if (state.type === "LoggedOutState") {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12">
        <div className="font-bold text-lg">You've been logged out</div>
        <Button
          label="Sign in"
          onClick={() => {
            window.location.assign(window.location.origin);
          }}
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <ErrorBoundary>
        {state.mainState ? (
          <Main.View
            dispatch={Dispatch.map(State.Action.DispatchMain, dispatch)}
            state={state.mainState}
            sharedState={State.buildSharedState(state)}
          />
        ) : (
          <div />
        )}
      </ErrorBoundary>
    </div>
  );
}
