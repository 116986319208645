import * as R from "ramda";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Project } from "..";
import { allFileTypes, MaterialFileType } from "../material-file";
import { createErrorMsg, LogItem, Message, ParseResult, XlsxFile } from "./types";
import { parseXlsxSimpleList } from "./parse-xlsx-simple-list";
import { parseXlsxDuctInsulationSchedule } from "./parse-xlsx-duct-insulation-schedule";
import { parseXlsxDuctSchedule } from "./parse-xlsx-duct-schedule";
import { MaterialTables, collapseQuantitiesAndSortMaterials } from "../material-list";

export type ImportMaterialListResult<T extends XlsxFile = XlsxFile> =
  | ImportMaterialListResultOk<T>
  | ImportMaterialListResultError;

export type ImportMessage = Message & { readonly fileName: string };

export type ImportLogItem = LogItem & { readonly fileName: string };

export interface ImportMaterialListResultOk<T extends XlsxFile = XlsxFile> {
  readonly type: "ok";
  readonly importedFiles: ReadonlyArray<T>;
  readonly messages: ReadonlyArray<ImportMessage>;
  readonly materials: ReadonlyArray<Project.Material>;
  readonly log: ReadonlyArray<ImportLogItem>;
}

export interface ImportMaterialListResultError {
  readonly type: "error";
  readonly messages: ReadonlyArray<ImportMessage>;
}

export function importMaterialList<T extends XlsxFile = XlsxFile>(
  materialTables: MaterialTables,
  files: ReadonlyArray<T>
): ImportMaterialListResult<T> {
  const maybeFilesToImport = allFileTypes.map((fileType) => files.find((f) => f.materialFile.type === fileType));
  if (maybeFilesToImport.some((f) => f === undefined)) {
    return {
      type: "error",
      messages: [{ ...createErrorMsg("xlsx_import_missing_files"), fileName: "" }],
    };
  }
  const filesToImport = maybeFilesToImport as ReadonlyArray<T>;
  const results = filesToImport.map((file) => {
    const { messages, materials, log } = parseFile(materialTables, file);
    return {
      file,
      messages,
      materials,
      log,
    };
  });
  const messages = R.unnest(
    results.map((result) => result.messages.map((m) => ({ ...m, fileName: result.file.materialFile.name })))
  );
  const log = R.unnest(
    results.map((result) => result.log.map((l) => ({ ...l, fileName: result.file.materialFile.name })))
  );
  const materialsIncluded = R.unnest(results.map((result) => result.materials)).map((m) => ({ ...m, included: true }));
  const materials = collapseQuantitiesAndSortMaterials(materialsIncluded);
  if (messages.some((m) => m.level === "error")) {
    return {
      type: "error",
      messages: messages,
    };
  } else {
    return {
      type: "ok",
      importedFiles: filesToImport,
      materials: materials,
      messages,
      log,
    };
  }
}

function parseFile(materialTables: MaterialTables, file: XlsxFile): ParseResult {
  const materialFileType = file.materialFile.type as MaterialFileType;
  switch (materialFileType) {
    case "air_terminal_schedule":
      return parseXlsxSimpleList(materialTables, file.rows, [0, 1, 2], 3);
    case "duct_accessory_schedule":
      return parseXlsxSimpleList(materialTables, file.rows, [0], 1);
    case "duct_fitting_schedule":
      return parseXlsxSimpleList(materialTables, file.rows, [0, 1, 2, 3], 4);
    case "duct_insulation_schedule":
      return parseXlsxDuctInsulationSchedule(materialTables, file.rows);
    case "duct_schedule":
      return parseXlsxDuctSchedule(materialTables, file.rows);
    case "flex_duct_schedule":
      return parseXlsxSimpleList(materialTables, file.rows, [0], 1);
    case "mechanical_equipment_schedule":
      return parseXlsxSimpleList(materialTables, file.rows, [0, 1], 2);
    default: {
      return exhaustiveCheck(materialFileType, true);
    }
  }
}
