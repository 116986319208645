import * as React from "react";
import { Icon } from "./icon";

interface Props {
  readonly header: React.ReactNode;
  readonly extraHeader?: React.ReactNode;
  readonly closedContent?: React.ReactNode;
  readonly children?: React.ReactNode;
  readonly closed?: boolean;
  readonly onToggleClosed: () => void;
}

export function Expander(props: Props): React.ReactElement<Props> {
  const { header, extraHeader, children, closed, onToggleClosed, closedContent } = props;
  const closable = closed !== undefined;
  return (
    <div className="flex flex-col space-y-8">
      {closable ? (
        <div className="flex items-center space-x-24">
          <a onClick={() => onToggleClosed()}>
            <div className="text-md cursor-pointer">
              <div className="flex flex-row space-x-8 items-center">
                {closed ? (
                  <Icon className="w-16 text-base" icon="chevron-right" size={"sm"} />
                ) : (
                  <Icon className="w-16 text-base" icon="chevron-down" size={"sm"} />
                )}
                {typeof header === "string" ? <span>{header}</span> : null}
              </div>
            </div>
          </a>
          {typeof header !== "string" ? header : null}
          {extraHeader}
        </div>
      ) : (
        <div className="text-md">
          <div className="flex flex-row space-x-8">
            <span>{header}</span>
            {extraHeader}
          </div>
        </div>
      )}
      {!closed && children}
      {closed && closedContent}
    </div>
  );
}

export function ExpanderTBody(props: Props & { readonly colSpan?: number }): React.ReactElement<Props> {
  const { header, extraHeader, children, closed, onToggleClosed, closedContent, colSpan } = props;
  const closable = closed !== undefined;
  return (
    <tbody>
      {closable ? (
        <tr>
          <td colSpan={colSpan} className="border-none">
            <div className="flex items-center space-x-24">
              <a>
                <div className="text-sm cursor-pointer">
                  <div className="flex flex-row space-x-8 items-center">
                    {closed ? (
                      <span onClick={() => onToggleClosed()}>
                        <Icon className="mx-8 w-16 text-base" icon="chevron-right" size={"sm"} />
                      </span>
                    ) : (
                      <span onClick={() => onToggleClosed()}>
                        <Icon className="mx-8 w-16 text-base" icon="chevron-down" size={"sm"} />
                      </span>
                    )}
                    {typeof header === "string" ? <span onClick={() => onToggleClosed()}>{header}</span> : null}
                  </div>
                </div>
              </a>
              {typeof header !== "string" ? header : null}
              {extraHeader}
            </div>
          </td>
        </tr>
      ) : (
        <tr>
          <td className="text-sm" colSpan={colSpan}>
            <div className="flex flex-row space-x-8">
              <span>{header}</span>

              {extraHeader}
            </div>
          </td>
        </tr>
      )}
      {!closed && children}
      {closed && closedContent}
      {!closed && (
        <tr key="last">
          <td className="border-none h-8"></td>
        </tr>
      )}
    </tbody>
  );
}
