import * as Gql from "../generated/generated-operations";

export type MaterialList = Omit<Gql.MaterialList, "__typename" | "changes">;
export type Project = Omit<Gql.Project, "__typename" | "materialLists"> & {
  readonly materialLists: ReadonlyArray<MaterialList>;
};
export type Material = Omit<Gql.Material, "__typename">;
export type MaterialFile = Omit<Gql.MaterialFile, "__typename">;
export type Permissions = "read" | "read-write";
export type ProjectStatus = {
  readonly id: Gql.ProjectStatus["id"];
  readonly exists: Gql.ProjectStatus["exists"];
  readonly access: Gql.ProjectStatus["access"];
};

export type MaterialType = "standard" | "custom";
