import { IconName } from "@fortawesome/fontawesome-svg-core";
import * as React from "react";
import { Icon, withTw } from ".";

export interface ButtonProps {
  readonly label: string;
  readonly title?: string;
  readonly type?: "primary" | "secondary" | "danger" | "nav";
  readonly onClick?: (e: React.MouseEvent) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly iconLeft?: IconName;
  readonly iconRight?: IconName;
  readonly spinnerIcon?: boolean;
  readonly large?: boolean;
}

export type StyledButtonProps = {
  readonly buttontype: "primary" | "secondary" | "danger" | "nav";
  readonly disabled: boolean;
  readonly large: boolean;
};

export const StyledButton = withTw(
  "button",
  "cursor-pointer btn",
  ({ large }: StyledButtonProps) => (large ? "text-sm" : "text-xs btn-tiny"),
  ({ buttontype }: StyledButtonProps) => {
    switch (buttontype) {
      case "primary":
        return "btn-primary";
      case "secondary":
        return "btn-secondary";
      case "danger":
        return "btn-danger";
      case "nav":
        return "btn-nav";
      default:
        return "";
    }
  },
  ({ disabled }: StyledButtonProps) => (disabled ? "btn-disabled" : "")
);

export function Button({
  label,
  title,
  type = "primary",
  onClick,
  disabled = false,
  className,
  iconLeft,
  iconRight,
  spinnerIcon,
  large = false,
}: ButtonProps): JSX.Element {
  return (
    <div className={className}>
      <StyledButton
        title={title}
        buttontype={type}
        disabled={disabled}
        large={large}
        onClick={(e) => {
          if (!disabled && onClick) {
            onClick(e);
          }
        }}
      >
        {(iconLeft || spinnerIcon) && (
          <Icon
            colorClass={" "}
            prefix="fal"
            className={spinnerIcon ? "fa-spin mr-8" : "font-normal mr-8"}
            icon={spinnerIcon ? "spinner-third" : (iconLeft as IconName)}
          />
        )}
        {label && <span className="whitespace-nowrap">{label}</span>}
        {iconRight && (
          <Icon colorClass={" "} prefix="fal" className={spinnerIcon ? "fa-spin ml-8" : "ml-8"} icon={iconRight} />
        )}
      </StyledButton>
    </div>
  );
}
