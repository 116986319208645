import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { gql } from "graphql-tag";
import { SharedState, Routes, HttpFetch, graphQLQueryWithAuth } from "@revit-order/client-infra";
import * as ProjectState from "../../project-state";
import * as GQLOps from "../../../../generated/generated-operations";

export const allFilesId = "all";

export interface State {
  readonly filesBeingDownloaded: ReadonlySet<string>;
  readonly existingIds: ReadonlySet<string>;
  readonly downloadingLog: boolean;
  readonly logResponse: GQLOps.Files_ProjectQuery | undefined;
}

export const Action = ctorsUnion({
  Download: (fileId: string) => ({ fileId }),
  FileDownloaded: (fileId: string) => ({ fileId }),
  DownloadLog: () => ({}),
  ResetLog: () => ({}),
  DownloadLogResponse: (res: GQLOps.Files_ProjectQuery) => ({ res }),
  NoOp: () => ({}),
});

export type Action = CtorsUnion<typeof Action>;

export function init(
  _location: Routes.ProjectLocation,
  _sharedState: SharedState.SharedState,
  prevState: State | undefined,
  projectState: ProjectState.State
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...(prevState || {
        filesBeingDownloaded: new Set(),
        downloadingLog: false,
        logResponse: undefined,
      }),
      existingIds: new Set(projectState.project.materialFiles?.files.map((f) => f.id)),
    },
  ];
}

export function update(
  action: Action,
  state: State,
  projectState: ProjectState.State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ResetLog": {
      return [{ ...state, logResponse: undefined }];
    }
    case "DownloadLog": {
      if (state.downloadingLog || projectState.projectIsBusy) {
        return [state];
      }
      const query = gql`
        query Files_project($projectId: ID!) {
          project(id: $projectId) {
            id
            materialLists {
              id
              sortNo
              name
              changes {
                zoomRuleLogItems
                packageRuleLogItems
              }
            }
          }
        }
      `;
      const input: GQLOps.Log_ProjectQueryVariables = {
        projectId: projectState.project.id,
      };
      const cmd = graphQLQueryWithAuth(sharedState.activeUser)<
        GQLOps.Log_ProjectQuery,
        GQLOps.Log_ProjectQueryVariables,
        Action
      >(query, input, sharedState.market.name, (res) => Action.DownloadLogResponse(res));
      return [{ ...state, downloadingLog: true, logResponse: undefined }, cmd];
    }
    case "DownloadLogResponse": {
      return [{ ...state, downloadingLog: false, logResponse: action.res }];
    }
    case "Download": {
      const fileUrl =
        action.fileId === allFilesId
          ? projectState.project.materialFiles?.downloadAllUrl
          : projectState.project.materialFiles?.files.find((f) => f.id === action.fileId)?.url;
      if (!fileUrl) {
        return [state];
      }
      return [
        { ...state, filesBeingDownloaded: new Set([...state.filesBeingDownloaded, action.fileId]) },
        HttpFetch.fetchOneSaveAsWithAuth(sharedState.activeUser)(
          {},
          fileUrl,
          () => Action.FileDownloaded(action.fileId),
          () => Action.FileDownloaded(action.fileId)
        ),
      ];
    }
    case "FileDownloaded": {
      const newFilesBeingDownloaded = new Set(state.filesBeingDownloaded);
      newFilesBeingDownloaded.delete(action.fileId);
      return [{ ...state, filesBeingDownloaded: newFilesBeingDownloaded }];
    }
    case "NoOp": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
