import { graphQLMutationWithAuth, InputPatch, SharedState } from "@revit-order/client-infra";
import { Cmd } from "@typescript-tea/core";
import { v4 as uuid } from "uuid";
import { MaterialList as ML, Project } from "@revit-order/shared";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function UpdateMaterialListItem(
  action: ReturnType<typeof Action.UpdateMaterialListItem>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;

  const materialList = project.materialLists.find((s) => s.id === action.materialListId);
  if (!materialList) {
    return [state];
  }
  const material = materialList.materials.find((m) => m.id === action.patch.id);
  if (!material) {
    return [state];
  }

  const updatedMaterial: Project.Material = { ...material, ...action.patch };
  const materialMeta = ML.getMetaData(state.materialTables, updatedMaterial);

  const materialsInGroup = materialList.materials.filter((material) => {
    const meta = ML.getMetaData(state.materialTables, material);
    return (
      state.materialTables.items[material.itemNumber]?.type &&
      meta.singleGroupSelection &&
      material.included &&
      meta.group === materialMeta.group &&
      meta.category === materialMeta.category
    );
  });
  const otherMaterials = materialsInGroup
    .filter((m) => m.id !== updatedMaterial.id)
    .map((m) => ({ ...m, included: false }));
  const updatedProject = Project.replaceMaterials(project, action.materialListId, [updatedMaterial, ...otherMaterials]);
  return [
    { ...state, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_UpdateMaterialsMutation,
      GQLOps.ProjectState_UpdateMaterialsMutationVariables,
      Action
    >(M.updateMaterialsMutation, {
      input: {
        materials: [
          action.patch as InputPatch<Project.Material>,
          ...otherMaterials.map((m) => ({ id: m.id, included: m.included } as InputPatch<Project.Material>)),
        ],
      },
    }),
  ];
}

export function UpdateCustomMaterial(
  action: ReturnType<typeof Action.UpdateCustomMaterial>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;
  if (project.locked) {
    return [state, undefined];
  }

  const materialList = project.materialLists.find((s) => s.id === action.materialListId);
  if (!materialList) {
    return [state];
  }
  const material = materialList.materials.find((m) => m.id === action.materialId);
  if (!material) {
    return [state];
  }

  if (material.itemNumber === action.itemNumber) {
    return [state];
  }

  const updatedMaterial: Project.Material = { ...material, itemNumber: action.itemNumber };
  const updatedProject = Project.replaceMaterials(project, action.materialListId, [updatedMaterial]);

  const disabledCustomMaterials = new Set<string>(state.disabledCustomMaterials);
  disabledCustomMaterials.add(action.materialId);

  return [
    { ...state, disabledCustomMaterials: disabledCustomMaterials, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_UpdateCustomMaterialMutation,
      GQLOps.ProjectState_UpdateCustomMaterialMutationVariables,
      Action
    >(
      M.updateCustomMaterialMutation,
      {
        input: {
          materialListId: action.materialListId,
          materialId: action.materialId,
          itemNumber: action.itemNumber,
          marketName: sharedState.market.name,
          language: sharedState.m3LanguageCode,
        },
      },
      (mutationData) => Action.ReceivedUpdatedCustomMaterial(mutationData)
    ),
  ];
}

export function AddCustomMaterial(
  action: ReturnType<typeof Action.AddCustomMaterial>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;

  const materialList = project.materialLists.find((s) => s.id === action.materialListId);
  if (!materialList) {
    return [state];
  }
  const material: Project.Material = {
    ...Project.createMaterial(uuid(), "custom", Math.max(...materialList.materials.map((m) => m.sortNo)) + 1, ""),
    included: true,
    quantity: 1,
  };
  const createMaterialCmd = graphQLMutation<
    GQLOps.ProjectState_CreateMaterialsMutation,
    GQLOps.ProjectState_CreateMaterialsMutationVariables,
    Action
  >(M.createMaterialsMutation, {
    input: {
      materialListId: action.materialListId,
      materials: [material],
    },
  });
  const updatedProject = Project.addMaterials(project, action.materialListId, [material]);
  return [{ ...state, project: updatedProject }, createMaterialCmd];
}

export function RemoveMaterial(
  action: ReturnType<typeof Action.RemoveMaterial>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;

  const materialList = project.materialLists.find((s) => s.id === action.materialListId);
  if (!materialList) {
    return [state];
  }
  const material = materialList.materials.find((m) => m.id === action.materialId);
  if (!material) {
    return [state];
  }
  const updatedProject = Project.removeMaterials(project, action.materialListId, [material.id]);
  return [
    { ...state, project: updatedProject },
    graphQLMutation<
      GQLOps.ProjectState_RemoveMaterialsMutation,
      GQLOps.ProjectState_RemoveMaterialsMutationVariables,
      Action
    >(M.removeMaterialsMutation, {
      input: {
        ids: [material.id],
      },
    }),
  ];
}

export function ReceivedUpdatedCustomMaterial(
  action: ReturnType<typeof Action.ReceivedUpdatedCustomMaterial>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;
  const { materialListId, materialId, status, updatedMaterial } = action.mutation.updateCustomMaterial;
  const disabledCustomMaterials = new Set<string>(state.disabledCustomMaterials);
  disabledCustomMaterials.delete(materialId);
  const materialList = project.materialLists.find((s) => s.id === materialListId);
  if (!materialList) {
    return [{ ...state, disabledCustomMaterials }];
  }
  if (status !== "success") {
    return [{ ...state, disabledCustomMaterials }];
  }
  const currentMaterial = materialList.materials.find((m) => m.id === updatedMaterial?.id);
  if (!currentMaterial) {
    return [{ ...state, disabledCustomMaterials }];
  }
  const materialsToReplace: Project.Material = {
    ...currentMaterial,
    name: updatedMaterial?.name ?? currentMaterial.name,
  };
  const updatedProject = Project.replaceMaterials(project, materialListId, [materialsToReplace]);
  return [{ ...state, disabledCustomMaterials, project: updatedProject }];
}
