import { gql } from "graphql-tag";
import { GraphQlUtils, Project } from "..";
import * as GqlOps from "../generated/generated-operations";

export type ProjectQuery = GqlOps.Project_ProjectQuery;
export type ProjectQueryVariables = GqlOps.Project_ProjectQueryVariables;
export type MetaProductQuery = GqlOps.ProjectMetaProductQuery;
export type MetaProductQueryVariables = GqlOps.ProjectMetaProductQueryVariables;
export type ProjectStatusQuery = GqlOps.Project_ProjectStatusQuery;
export type ProjectStatusQueryVariables = GqlOps.Project_ProjectStatusQueryVariables;

export const queryStatus = gql`
  query Project_projectStatus($projectId: ID!) {
    projectStatus(id: $projectId) {
      id
      exists
      access
    }
  }
`;

export const query = gql`
  query Project_project($projectId: ID!) {
    project(id: $projectId) {
      ...Project_project
    }
  }

  fragment Project_project on Project {
    id
    created
    edited
    locked
    name
    owned
    owner
    permissions
    materialLists {
      ...Project_materialList
    }
    materialFiles {
      downloadAllUrl
      files {
        ...Project_materialFiles
      }
    }
  }

  fragment Project_materialList on MaterialList {
    id
    sortNo
    name
    materials {
      ...Project_material
    }
  }

  fragment Project_material on Material {
    id
    sortNo
    itemNumber
    quantity
    included
    type
    name
  }

  fragment Project_materialFiles on MaterialFile {
    id
    name
    format
    type
    uploaded
    lastModified
    url
  }
`;

export const queryMetaProduct = gql`
  query projectMetaProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        ...project_Modules
      }
    }
  }

  fragment project_Modules on Modules {
    custom_tables {
      Market {
        market_name
      }
    }
  }
`;

export async function getProject(
  graphQlQuery: GraphQlUtils.GraphQlQueryFn,
  projectId: string
): Promise<Project.Project> {
  const projectResponse = await graphQlQuery<Project.ProjectQuery, Project.ProjectQueryVariables>(Project.query, {
    projectId,
  });

  if (!projectResponse?.project) {
    throw new Error(`Failed to query project: ${projectId}`);
  }
  return projectResponse.project;
}
