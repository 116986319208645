import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import { HttpEffectManager, Routes, SharedState } from "@revit-order/client-infra";
import * as Project from "../pages/project";
import * as UserSettings from "../pages/user-settings";
import * as ProjectList from "../pages/project-list";
import * as Header from "../header";

export type State = {
  readonly location: Routes.MainLocation;
  readonly userSettingsState: UserSettings.State | undefined;
  readonly projectListState: ProjectList.State | undefined;
  readonly projectState: Project.State | undefined;
  readonly headerState: Header.State | undefined;
  readonly waitingForResponse: boolean;
  readonly errorResponse: boolean;
};

export const Action = ctorsUnion({
  DispatchUserSettings: (action: UserSettings.Action) => ({ action }),
  DispatchProjectList: (action: ProjectList.Action) => ({ action }),
  DispatchProject: (action: Project.Action) => ({ action }),
  DispatchHeader: (action: Header.Action) => ({ action }),
  HttpStateChanged: (httpState: HttpEffectManager.HttpState) => ({ httpState }),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  location: Routes.MainLocation,
  prevState: State | undefined,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  const cmds: Array<Cmd<Action> | undefined> = [];
  let state: State = {
    location: location,
    projectState: undefined,
    projectListState: undefined,
    userSettingsState: sharedState.userSettings,
    headerState: undefined,
    waitingForResponse: false,
    errorResponse: false,
  };

  const [headerState, headerCmd] = Header.init(prevState?.headerState, sharedState);
  state = {
    ...state,
    headerState: headerState,
  };
  cmds.push(Cmd.map(Action.DispatchHeader, headerCmd));

  switch (location.type) {
    case "UserSettings": {
      // Always init on url change so it gets the new url
      const [newUserSettingsState, newUserSettingsCmd] = UserSettings.init(sharedState);
      state = {
        ...state,
        userSettingsState: newUserSettingsState,
      };
      cmds.push(Cmd.map(Action.DispatchUserSettings, newUserSettingsCmd));
      break;
    }

    case "ProjectList": {
      // Always init on url change so it gets the new url
      const [newProjectListState, newProjectListCmd] = ProjectList.init(location.location, sharedState);
      state = {
        ...state,
        projectListState: newProjectListState,
      };
      cmds.push(Cmd.map(Action.DispatchProjectList, newProjectListCmd));
      break;
    }

    case "Project": {
      // Always init on url change so it gets the new url
      const [newProjectState, projectCmd] = Project.init(
        location.params.projectId,
        location.location,
        sharedState,
        prevState?.projectState
      );
      state = {
        ...state,
        projectState: newProjectState,
      };
      cmds.push(Cmd.map(Action.DispatchProject, projectCmd));
      break;
    }

    default:
      return exhaustiveCheck(location, true);
  }

  return [state, Cmd.batch(cmds)];
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "DispatchHeader": {
      if (!state.headerState) {
        return [state];
      }
      const [headerState, headerCmd, sharedStateUpdate] = Header.update(action.action, state.headerState, sharedState);
      return [{ ...state, headerState }, Cmd.map(Action.DispatchHeader, headerCmd), sharedStateUpdate];
    }

    case "DispatchUserSettings": {
      const [userSettingsState, userSettingsCmd, sharedStateAction] = UserSettings.update(
        action.action,
        state.userSettingsState!,
        sharedState
      );
      return [
        { ...state, userSettingsState },
        Cmd.map(Action.DispatchUserSettings, userSettingsCmd),
        sharedStateAction,
      ];
    }

    case "DispatchProjectList": {
      const [projectListState, projectListCmd, sharedStateAction] = ProjectList.update(
        action.action,
        state.projectListState!,
        sharedState
      );
      return [{ ...state, projectListState }, Cmd.map(Action.DispatchProjectList, projectListCmd), sharedStateAction];
    }

    case "DispatchProject": {
      if (!state.projectState) {
        return [state];
      }
      const [projectState, projectCmd, sharedStateAction] = Project.update(
        action.action,
        state.projectState,
        sharedState
      );
      return [{ ...state, projectState }, Cmd.map(Action.DispatchProject, projectCmd), sharedStateAction];
    }

    case "HttpStateChanged": {
      let newState = state;
      if (action.httpState === "waiting") {
        newState = {
          ...state,
          waitingForResponse: true,
        };
      }
      if (action.httpState === "idle") {
        newState = {
          ...state,
          waitingForResponse: false,
        };
      }

      return [newState];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
