import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState } from "@revit-order/client-infra";
import { Texts } from "@revit-order/shared";
import * as Header from "../header";
import * as Project from "../pages/project";
import * as ProjectList from "../pages/project-list";
import * as UserSettings from "../pages/user-settings";
import * as State from "./state";
import { Spinner } from "../elements";

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  return (
    <div className="text-xs">
      <Header.View
        dispatch={Dispatch.map(State.Action.DispatchHeader, dispatch)}
        sharedState={sharedState}
        state={state.headerState}
      />
      <div className="m-20">
        <div className="float-right">{state.waitingForResponse && <Spinner />}</div>
        <MainContent dispatch={dispatch} state={state} sharedState={sharedState} />
      </div>
    </div>
  );
}
function MainContent({
  dispatch,
  state,
  sharedState,
}: {
  readonly dispatch: Dispatch<State.Action>;
  readonly state: State.State;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const currentLocation = state.location;

  switch (currentLocation.type) {
    case "Project": {
      if (!state.projectState) {
        return <div />;
      }
      return (
        <Project.View
          dispatch={(a) => dispatch(State.Action.DispatchProject(a))}
          state={state.projectState}
          sharedState={sharedState}
        />
      );
    }

    case "ProjectList": {
      if (!state.projectListState) {
        return <div />;
      }
      return (
        <ProjectList.View
          dispatch={(a) => dispatch(State.Action.DispatchProjectList(a))}
          state={state.projectListState}
          sharedState={sharedState}
        />
      );
    }

    case "UserSettings": {
      return (
        <UserSettings.View
          dispatch={(a) => dispatch(State.Action.DispatchUserSettings(a))}
          state={state.userSettingsState!}
          sharedState={sharedState}
        />
      );
    }

    default: {
      return <div>{sharedState.translate(Texts.texts.not_found_404)}</div>;
    }
  }
}
