import * as React from "react";
import { withTw } from "./with-tw";

export interface CheckboxProps {
  readonly label?: string | React.ReactNode;
  readonly checked: boolean;
  readonly onChange?: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly name?: string;
  readonly id?: string;
  readonly error?: string;
  readonly title?: string;
}

interface WrapperProps {
  readonly checked: boolean;
}

const CheckboxWrapper = withTw("div", "form-checkbox-border top-auto", ({ checked }: WrapperProps) =>
  checked ? "form-checkbox-checked" : ""
);

interface LabelProps {
  readonly isValid: boolean;
}
const Label = withTw("label", "form-checkbox p-0", ({ isValid }: LabelProps) => (isValid ? "" : "border-danger-dark"));

export function Checkbox(props: CheckboxProps): React.ReactElement<CheckboxProps> {
  let className = "";
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <Label title={props.error || props.title} isValid={!props.error} className={"group"}>
      <input
        id={props.id}
        name={props.name}
        className={className}
        checked={props.checked}
        type="checkbox"
        disabled={props.disabled}
        onChange={() => {
          if (!props.disabled && props.onChange) {
            props.onChange(!props.checked);
          }
        }}
      />
      <CheckboxWrapper checked={props.checked}>
        {props.checked ? (
          <div key="svg" className="form-checkbox-inside p-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="check"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
              ></path>
            </svg>
          </div>
        ) : undefined}
      </CheckboxWrapper>
      {props.label && (
        <div className="ml-12 flex items-center group-hover:bg-brand-50">
          {typeof props.label === "string" ? (
            <span className="!ml-0 whitespace-nowrap">{props.label}</span>
          ) : (
            props.label
          )}
        </div>
      )}
    </Label>
  );
}

export interface SmallCheckboxProps {
  readonly label?: string | React.ReactNode;
  readonly checked: boolean;
  readonly onChange?: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly name?: string;
  readonly id?: string;
  readonly error?: string;
  readonly title?: string;
}

interface SmallWrapperProps {
  readonly checked: boolean;
}

const SmallCheckboxWrapper = withTw("div", "top-auto w-auto h-auto", ({ checked }: SmallWrapperProps) =>
  checked ? "form-checkbox-border form-checkbox-checked" : ""
);

interface SmallLabelProps {
  readonly isValid: boolean;
}
const SmallLabel = withTw("label", "form-checkbox p-0", ({ isValid }: SmallLabelProps) =>
  isValid ? "" : "border-danger-dark"
);

export function SmallCheckbox(props: SmallCheckboxProps): React.ReactElement<SmallCheckboxProps> {
  return (
    <SmallLabel title={props.error || props.title} isValid={!props.error}>
      <input
        style={{ width: "15px" }}
        id={props.id}
        name={props.name}
        checked={props.checked}
        type="checkbox"
        disabled={props.disabled}
        onChange={() => {
          if (!props.disabled && props.onChange) {
            props.onChange(!props.checked);
          }
        }}
      />
      <SmallCheckboxWrapper checked={props.checked}>
        {props.checked ? (
          <div key="svg" className="form-checkbox-inside p-0">
            <svg
              width={8}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="check"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
              ></path>
            </svg>
          </div>
        ) : undefined}
      </SmallCheckboxWrapper>
      {props.label && (
        <div className="ml-12 flex items-center group-hover:bg-brand-50">
          {typeof props.label === "string" ? (
            <span className="!ml-0 whitespace-nowrap w-4 h-4">{props.label}</span>
          ) : (
            props.label
          )}
        </div>
      )}
    </SmallLabel>
  );
}

/* <div className={`p-4 mb-8 hover:cursor-pointer`} onClick={onClick}>
<svg
  fill="#000"
  width="16"
  height="16"
  viewBox="0 0 512 512"
  className={`inline mr-4 ml-4 mb-6 ${checked ? "opacity-100" : "opacity-0"}`}
>
  <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
</svg>
<span className={`${active ? "border-b-4" : ""} border-neutral-700 text-sm text-neutral-700`}>{label}</span>
</div>
);
} */
