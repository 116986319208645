import * as R from "ramda";
import gql from "graphql-tag";
import { v4 as uuid } from "uuid";
import { GraphQlUtils, Project, Texts, Markets } from "..";
import * as GQLOps from "../generated/generated-operations";
import * as Query from "../query";
import {
  Category,
  MaterialTables,
  MetaData,
  customItemsCategoryName,
  unknownItem,
  Item,
  notFoundSortNo,
  Duct,
} from "./types";
import { createCategories, createCategoryName, getSortOrder } from "./categories";

export const materialQuery = gql`
  query MaterialList_materialProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        custom_tables {
          ...MaterialList_materialTables
        }
      }
    }
  }
  fragment MaterialList_materialTables on Module_custom_tables {
    Duct {
      sort_no
      item_number
      quantity
      diameter
      import_rule
      import_rule_param
    }
    Insulation {
      sort_no
      item_number
      diameter
      insulation_thickness
      quantity
    }
    StandardItem {
      sort_no
      item_number
      item_name
      default_quantity
      category
    }
    ZoomRule {
      duct_diameter
      min_quantity
      max_quantity
      item_1_itemnumber
      item_1_quantity
      item_2_itemnumber
      item_2_quantity
      item_3_itemnumber
      item_3_quantity
      quantity_reduction_duct
    }
    Categories {
      sort_no
      group
      category
    }
  }
`;

export async function getMaterials(
  market: Markets.Market,
  graphQlProductQuery: GraphQlUtils.GraphQlQueryFn
): Promise<MaterialTables> {
  const materialProductResponse = await graphQlProductQuery<
    GQLOps.MaterialList_MaterialProductQuery,
    GQLOps.MaterialList_MaterialProductQueryVariables
  >(materialQuery, { productId: market.materialProductId });
  return createMaterialTables(materialProductResponse);
}

export function* getMaterialsYield(market: Markets.Market): Query.QueryGenerator<MaterialTables> {
  const materialProductResponse = yield* Query.graphQLProductQuery<
    GQLOps.MaterialList_MaterialProductQuery,
    GQLOps.MaterialList_MaterialProductQueryVariables
  >(materialQuery, { productId: market.materialProductId });
  return createMaterialTables(materialProductResponse);
}

export function getMetaData(materialTables: MaterialTables, material: Project.Material): MetaData {
  if (material.type === "custom") {
    return {
      group: customItemsCategoryName,
      category: undefined,
      readOnlyQuantity: false,
      material,
    };
  } else {
    const item = materialTables.items[material.itemNumber] || unknownItem;
    return {
      group: item.type,
      category: item.type === "standard" ? item.category || undefined : undefined,
      readOnlyQuantity: false,
      material,
    };
  }
}

export function categorizeMaterials(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  alwaysIncludeCustomCategory: boolean
): ReadonlyArray<Category> {
  const metaDatas = materials.map((m) => getMetaData(materialTables, m));
  const categorized = categorizeMaterialMetaData(metaDatas);
  const categorizedWithCustom =
    alwaysIncludeCustomCategory && !categorized.some((c) => c.name === customItemsCategoryName)
      ? [...categorized, { name: customItemsCategoryName, materials: [] }]
      : categorized;
  const sortedCategorized = categorizedWithCustom
    .map((c, i): [Category, number] => [c, i + 999999])
    .sort((a, b) => {
      const [aCategory, aOrder] = a;
      const [bCategory, bOrder] = b;
      const aSortNo = getSortOrder(materialTables.categories, aCategory.name, aOrder);
      const bSortNo = getSortOrder(materialTables.categories, bCategory.name, bOrder);
      return aSortNo - bSortNo;
    })
    .map(([category]) => category);
  return sortedCategorized;
}

export function getItemName(translate: Texts.TranslateFn, material: Project.Material): string {
  if (material.type === "custom") {
    return material.name || "";
  } else {
    return translate(Texts.texts.itemName(material.itemNumber), "");
  }
}

export function getItemDescription(translate: Texts.TranslateFn, material: Project.Material): string | undefined {
  if (material.type === "custom") {
    return undefined;
  } else {
    return translate(Texts.texts.itemDescription(material.itemNumber), "") || undefined;
  }
}

export function getItemNumber(material: Project.Material): string {
  return material.itemNumber;
}

export function addMissingMaterials(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  const standardMaterials = materialTables.tables.standardItemTable
    .filter((r) => !!r.item_number)
    .map((r) => ({
      ...Project.createMaterial(
        uuid(),
        "standard",
        materialTables.sortNos.get(r.item_number || ""),
        r.item_number || "",
        r.default_quantity ?? 1
      ),
      included: (r.default_quantity || 0) > 0,
    }));
  const ductMaterials = [...materialTables.tables.ductTable, ...materialTables.tables.insulationTable]
    .filter((r) => !!r.item_number)
    .map((r) =>
      Project.createMaterial(
        uuid(),
        "standard",
        materialTables.sortNos.get(r.item_number || ""),
        r.item_number || "",
        0
      )
    );
  const existingMaterials = new Set(materials.map((m) => m.itemNumber));
  const newMaterials = collapseQuantitiesAndSortMaterials([
    ...materials,
    ...standardMaterials.filter((m) => !existingMaterials.has(m.itemNumber)),
    ...ductMaterials.filter((m) => !existingMaterials.has(m.itemNumber)),
  ]);
  return newMaterials;
}

export function getMissingMaterials(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  const withNewMaterials = addMissingMaterials(materialTables, materials);
  const currentItems = new Set(materials.map((m) => m.itemNumber));
  const missingMaterials = withNewMaterials.filter((m) => !currentItems.has(m.itemNumber));
  return missingMaterials;
}

export function collapseQuantitiesAndSortMaterials(
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  // Rows with the same item numbers will be combined into one row with the quantities added together
  const newMaterials = R.values(R.groupBy((m) => m.itemNumber, materials))
    .map((group) => ({
      ...group[0],
      quantity: group.reduce((sofar, m) => sofar + m.quantity, 0),
      included: group.some((m) => m.included),
    }))
    .sort((a, b) => a.sortNo - b.sortNo);
  return newMaterials;
}

function categorizeMaterialMetaData(metaDatas: ReadonlyArray<MetaData>): ReadonlyArray<Category> {
  const groups = R.groupBy<MetaData>((item) => createCategoryName(item.group, item.category), metaDatas);
  const categoryNames = R.uniq(metaDatas.map((meta) => createCategoryName(meta.group, meta.category)));
  const categories = categoryNames.map((name) => {
    return {
      name: name,
      materials: (groups[name] || []).map((p) => p.material),
    };
  });
  return categories;
}

function createMaterialTables(
  materialProductResponse: GQLOps.MaterialList_MaterialProductQuery | undefined
): MaterialTables {
  const categoriesTable = materialProductResponse?.product?.modules.custom_tables.Categories || [];
  const ductTable = materialProductResponse?.product?.modules.custom_tables.Duct as ReadonlyArray<Duct>;
  const insulationTable = materialProductResponse?.product?.modules.custom_tables.Insulation;
  const standardItemTable = materialProductResponse?.product?.modules.custom_tables.StandardItem;
  const zoomRuleTable = materialProductResponse?.product?.modules.custom_tables.ZoomRule;
  if (!ductTable || !insulationTable || !standardItemTable || !zoomRuleTable) {
    throw new Error("Failed to query product data");
  }
  const items: { [itemNumber: string]: Item } = {};
  ductTable.forEach((i) => (items[i.item_number || ""] = { type: "duct", ...i }));
  insulationTable.forEach((i) => (items[i.item_number || ""] = { type: "insulation", ...i }));
  standardItemTable.forEach((i) => (items[i.item_number || ""] = { type: "standard", ...i }));
  const sortNos = [...ductTable, ...insulationTable, ...standardItemTable].reduce((sofar, item) => {
    if (item.item_number) {
      sofar.set(item.item_number, item.sort_no ?? notFoundSortNo);
    }
    return sofar;
  }, new Map<string, number>());

  return {
    tables: {
      ductTable,
      insulationTable,
      standardItemTable,
      zoomRuleTable,
    },
    items,
    sortNos,
    categories: createCategories(categoriesTable),
  };
}
