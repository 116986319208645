import { v4 as uuid } from "uuid";
import { graphQLMutationWithAuth, SharedState } from "@revit-order/client-infra";
import { Cmd } from "@typescript-tea/core";
import { MaterialFile } from "@revit-order/shared";
import { Action, StagedFile, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function StageFiles(
  action: ReturnType<typeof Action.StageFiles>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (state.projectIsBusy) {
    return [state];
  }
  const newTypes = new Set(action.stagedFiles.map((f) => f.fileType));
  const newNames = new Set(action.stagedFiles.map((f) => f.name));
  const newStagedFiles = [
    ...state.stagedFiles.filter((f) => !newTypes.has(f.fileType) && !newNames.has(f.name)),
    ...action.stagedFiles,
  ];
  if (MaterialFile.allFileTypes.every((fileType) => newStagedFiles.some((f) => f.fileType === fileType))) {
    return [
      {
        ...state,
        stagedFiles: [],
        uploadingfiles: true,
        projectIsBusy: true,
        uploadingFilesStatus: undefined,
        uploadingFilesMessages: undefined,
      },
      createUploadCommand(sharedState, state.project.id, newStagedFiles, state.applyZoomRuleWhenImporting),
    ];
  } else {
    return [
      { ...state, stagedFiles: newStagedFiles, uploadingFilesStatus: undefined, uploadingFilesMessages: undefined },
    ];
  }
}

export function ResetFromUploadedMaterialFiles(
  _action: ReturnType<typeof Action.ResetFromUploadedMaterialFiles>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  if (state.projectIsBusy) {
    return [state];
  }
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  return [
    {
      ...state,
      stagedFiles: [],
      uploadingfiles: true,
      projectIsBusy: true,
      uploadingFilesStatus: undefined,
      uploadingFilesMessages: undefined,
    },
    graphQLMutation<
      GQLOps.ProjectState_ResetFromUploadedMaterialFilesMutation,
      GQLOps.ProjectState_ResetFromUploadedMaterialFilesMutationVariables,
      Action
    >(
      M.resetFromUploadedMaterialFiles,
      {
        input: {
          marketName: sharedState.market.name,
          projectId: state.project.id,
          disableZoomRule: !state.applyZoomRuleWhenImporting,
        },
      },
      (response) => Action.CreatedListFromFilesResponse(response.resetFromUploadedMaterialFiles)
    ),
  ];
}

export function CreatedListFromFilesResponse(
  action: ReturnType<typeof Action.CreatedListFromFilesResponse>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...state,
      uploadingfiles: false,
      projectIsBusy: false,
      uploadingFilesStatus: action.response.okResult
        ? "ok"
        : action.response.errorResult?.messages
        ? "error"
        : undefined,
      uploadingFilesMessages: action.response.errorResult?.messages || action.response.okResult?.messages || undefined,
      stagedFiles: [],
      project: action.response.okResult
        ? {
            ...state.project,
            materialFiles: {
              downloadAllUrl: action.response.okResult.downloadAllUrl,
              files: action.response.okResult.materialFiles,
            },
            materialLists: [action.response.okResult?.materialList],
          }
        : state.project,
    },
  ];
}

export function SetApplyZoomRuleWhenImporting(
  action: ReturnType<typeof Action.SetApplyZoomRuleWhenImporting>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  return [
    {
      ...state,
      applyZoomRuleWhenImporting: action.applyZoomRule,
      uploadingFilesStatus: undefined,
      uploadingFilesMessages: undefined,
    },
  ];
}

function createUploadCommand(
  sharedState: SharedState.SharedState,
  projectId: string,
  stagedFiles: ReadonlyArray<StagedFile>,
  applyZoomRuleWhenImporting: boolean
): Cmd<Action> | undefined {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const fileInputs: Array<GQLOps.MaterialFileInput> = [];
  for (const fileType of MaterialFile.allFileTypes) {
    const stagedFile = stagedFiles.find((f) => f.fileType === fileType);
    if (!stagedFile) {
      return undefined;
    }
    fileInputs.push({
      contents: new File([stagedFile.data], stagedFile.name),
      format: "xlsx",
      id: uuid(),
      lastModified: stagedFile.lastModified,
      name: stagedFile.name,
      type: fileType,
      uploaded: Date.now(),
    });
  }
  const input: GQLOps.ProjectState_UploadMaterialFilesMutationVariables["input"] = {
    marketName: sharedState.market.name,
    projectId: projectId,
    files: fileInputs,
    disableZoomRule: !applyZoomRuleWhenImporting,
  };
  return graphQLMutation<
    GQLOps.ProjectState_UploadMaterialFilesMutation,
    GQLOps.ProjectState_UploadMaterialFilesMutationVariables,
    Action
  >(M.uploadMaterialFilesMutation, { input }, (response) =>
    Action.CreatedListFromFilesResponse(response.uploadMaterialFiles)
  );
}
