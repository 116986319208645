/* eslint-disable functional/no-class */
/* eslint-disable functional/no-this-expression */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import * as Sentry from "@sentry/react";
import { Button, H1 } from "../elements";
import * as State from "./state";

interface Props {}

interface State {
  readonly hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount(): void {
    window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
      this.reportError(event.reason);
    });

    window.onerror = (_message: string, _source: string, _lineno: number, _colno: number, error: Error | undefined) => {
      this.reportError(error);
      return true;
    };
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    this.reportError(error);
    this.setState({ hasError: true }); // You can also log the error to an error reporting service
  }

  reportError(error: Error | undefined | null): void {
    if (this.state.hasError) {
      return;
    }

    // Handle the case where there is no error object
    // Error may be null or undefined if the error happens in a script loaded from external site because details
    // of errors in external scripts are secret
    if (!error) {
      // Error from outside React Compontent, for example an error in the javascript for the website menu,
      // these errors should not crash our application, just return and do not show an error
      return;
    }

    if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
      Sentry.captureException(error);
    } else {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-sm">
          <div className="max-w-screen-md mx-auto mt-104">
            <H1>An error occurred</H1>
            <p>
              We have detected an error and reported it directly to the software development team. We are so sorry about
              the inconvenience.
            </p>
            <p>Press the button below to reload the page and try again.</p>
            <div>
              <Button large={true} onClick={() => window.location.reload()} iconLeft="refresh" label="Reload" />
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
