import { v4 as uuid } from "uuid";
import {
  graphQLMutationWithAuth,
  NavigationEffectManager,
  Routes,
  SharedState,
  variablesFromPatch,
} from "@revit-order/client-infra";
import { Project, Texts } from "@revit-order/shared";
import { Cmd } from "@typescript-tea/core";
import { Action, State } from "../state";
import * as GQLOps from "../../../../generated/generated-operations";
import * as M from "../mutations";

export function RemoveProject(
  _: ReturnType<typeof Action.RemoveProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;

  return [
    state,
    graphQLMutation<
      GQLOps.ProjectState_RemoveProjectMutation,
      GQLOps.ProjectState_RemoveProjectMutationVariables,
      Action
    >(
      M.removeProjectMutation,
      {
        input: {
          id: project.id,
        },
      },
      () => Action.RemoveProjectResponse()
    ),
  ];
}

export function RemoveProjectResponse(
  __: ReturnType<typeof Action.RemoveProjectResponse>,
  state: State,
  _: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const newUrl = Routes.buildUrl(
    Routes.RootLocation.MainLocation(Routes.MainLocation.ProjectList(Routes.ProjectListLocation.ProjectList()))
  );
  return [state, NavigationEffectManager.pushUrl(newUrl)];
}

export function UpdateProject(
  action: ReturnType<typeof Action.UpdateProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;

  return [
    { ...state, project: { ...project, ...action.patch } },
    graphQLMutation<
      GQLOps.ProjectState_UpdateProjectMutation,
      GQLOps.ProjectState_UpdateProjectMutationVariables,
      Action
    >(M.updateProjectMutation, variablesFromPatch(project.id, action.patch), Action.NoOp),
  ];
}

export function UpdateProjectLockState(
  action: ReturnType<typeof Action.UpdateProjectLockState>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;

  if (state.project.locked === action.locked) {
    return [state];
  }
  if (action.locked) {
    return [
      { ...state, project: { ...project, locked: action.locked, permissions: "read" } },
      graphQLMutation<
        GQLOps.ProjectState_UpdateProjectLockStateMutation,
        GQLOps.ProjectState_UpdateProjectLockStateMutationVariables,
        Action
      >(M.updateProjectLockStateMutation, { input: { id: project.id, locked: action.locked } }, (response) =>
        Action.ProjectUnlockResponse(
          response.updateProjectLockState.locked,
          response.updateProjectLockState.permissions as Project.Permissions
        )
      ),
    ];
  } else {
    return [
      state,
      graphQLMutation<
        GQLOps.ProjectState_UpdateProjectLockStateMutation,
        GQLOps.ProjectState_UpdateProjectLockStateMutationVariables,
        Action
      >(M.updateProjectLockStateMutation, { input: { id: project.id, locked: action.locked } }, (response) =>
        Action.ProjectUnlockResponse(
          response.updateProjectLockState.locked,
          response.updateProjectLockState.permissions as Project.Permissions
        )
      ),
    ];
  }
}

export function DuplicateProject(
  _action: ReturnType<typeof Action.DuplicateProject>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser, sharedState.market.name);
  const { project } = state;
  return [
    { ...state, projectIsBusy: true },
    graphQLMutation<
      GQLOps.ProjectState_DuplicateProjectMutation,
      GQLOps.ProjectState_DuplicateProjectMutationVariables,
      Action
    >(
      M.duplicateProjectMutation,
      {
        input: {
          oldId: project.id,
          newId: uuid(),
          newName: sharedState.translate(Texts.texts.copy_of_project(project.name || "")),
        },
      },
      (response) => Action.DuplicateProjectResponse(response.duplicateProject.id)
    ),
  ];
}

export function DuplicateProjectResponse(
  action: ReturnType<typeof Action.DuplicateProjectResponse>,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const newUrl = Routes.buildUrl(
    Routes.RootLocation.MainLocation(Routes.MainLocation.Project(action.id, Routes.ProjectLocation.General()))
  );
  return [{ ...state, projectIsBusy: false }, NavigationEffectManager.pushUrl(newUrl)];
}
