import * as R from "ramda";
import { Project, Texts } from "..";

export interface ItemListLog {
  readonly type: "item_list";
  readonly itemNumber: string;
  readonly quantityAdded: number;
}

export interface InsulationLog {
  readonly type: "insulation_schedule";
  readonly ductSize: number;
  readonly thickness: number;
  readonly totalLengthMm: number;
  readonly itemNumber: string;
  readonly lengthPerItemMm: number;
  readonly quantityCalculated: number;
  readonly quantityAdded: number;
}

export interface DuctLog {
  readonly type: "duct_schedule";
  readonly ductSize: number;
  readonly totalLengthMm: number;
  readonly itemNumber: string;
  readonly lengthPerItemMm: number;
  readonly quantityCalculated: number;
  readonly quantityAdded: number;
}

export type LogItem = ItemListLog | InsulationLog | DuctLog;

export interface ParseResult {
  readonly messages: ReadonlyArray<Message>;
  readonly materials: ReadonlyArray<Project.Material>;
  readonly log: ReadonlyArray<LogItem>;
}

export type XlsxRow = ReadonlyArray<string | number | undefined>;

export interface XlsxFile {
  readonly materialFile: Project.MaterialFile;
  readonly rows: ReadonlyArray<XlsxRow>;
}

export interface Message {
  readonly level: "info" | "warning" | "error";
  readonly textKey: Texts.TextKeyName;
  readonly params: ReadonlyArray<{ readonly key: string; readonly value: string }>;
}

export function createInfoMsg(textKey: Texts.TextKeyName, params: Record<string, string | number> = {}): Message {
  return {
    level: "info",
    textKey,
    params: R.toPairs(params).map(([key, value]) => ({ key, value: value.toString() })),
  };
}

export function createWarningMsg(textKey: Texts.TextKeyName, params: Record<string, string | number> = {}): Message {
  return {
    level: "warning",
    textKey,
    params: R.toPairs(params).map(([key, value]) => ({ key, value: value.toString() })),
  };
}

export function createErrorMsg(textKey: Texts.TextKeyName, params: Record<string, string | number> = {}): Message {
  return {
    level: "error",
    textKey,
    params: R.toPairs(params).map(([key, value]) => ({ key, value: value.toString() })),
  };
}
