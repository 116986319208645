import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { saveAs } from "file-saver";
import { Cmd } from "@typescript-tea/core";
import { Crm, MaterialFile, MaterialList, Project } from "@revit-order/shared";
import { SharedState, Patch, HttpFetch } from "@revit-order/client-infra";
import * as GQLOps from "../../../generated/generated-operations";
import { MaterialActions, ProjectActions, MaterialFileActions, MaterialListActions } from "./actions";

export interface StagedFile {
  readonly data: ArrayBuffer | string;
  readonly name: string;
  readonly lastModified: number;
  readonly fileType: MaterialFile.MaterialFileType | undefined;
}

export interface State {
  readonly metaProduct: Project.MetaProductQuery;
  readonly materialTables: MaterialList.MaterialTables;
  readonly project: Project.Project;
  readonly projectIsBusy: boolean;

  // Crm
  readonly crmExportResponse: Crm.ExportResponse | undefined;

  // Materials
  readonly disabledCustomMaterials: ReadonlySet<string>;

  // Material files
  readonly stagedFiles: ReadonlyArray<StagedFile>;
  readonly uploadingfiles: boolean;
  readonly uploadingFilesStatus: "ok" | "error" | undefined;
  readonly uploadingFilesMessages:
    | GQLOps.MaterialListFromFilesErrorResult["messages"]
    | GQLOps.MaterialListFromFilesOkResult["messages"]
    | undefined;
  readonly applyZoomRuleWhenImporting: boolean;
}

type ProjectPatch = Omit<Patch<Project.Project>, "id">;

export const Action = ctorsUnion({
  // Project
  RemoveProject: () => ({}),
  RemoveProjectResponse: () => ({}),
  UpdateProject: (patch: ProjectPatch) => ({ patch }),
  UpdateProjectLockState: (locked: boolean) => ({ locked }),
  ProjectUnlockResponse: (locked: boolean, permissions: Project.Permissions) => ({ locked, permissions }),
  DuplicateProject: () => ({}),
  DuplicateProjectResponse: (id) => ({ id }),

  // Material list
  RemoveMaterialList: (materialListId: string) => ({ materialListId }),
  UpdateMaterialList: (patch: Patch<GQLOps.UpdateMaterialListInput>) => ({ patch }),
  DuplicateMaterialList: (materialListId: string) => ({ materialListId }),
  DuplicateMaterialListPostAction: (
    newMaterialList: GQLOps.ProjectState_DuplicateMaterialListMutation,
    oldMaterialListId: string
  ) => ({
    newMaterialList,
    oldMaterialListId,
  }),
  MultiplyMaterialQuantities: (materialListId: string, multiplier: number) => ({ materialListId, multiplier }),
  MultiplyMaterialQuantitiesPostAction: (response: GQLOps.ProjectState_MultiplyQuantitiesMaterialListMutation) => ({
    response,
  }),
  AddNewMaterialsMaterialList: (materialListId: string) => ({ materialListId }),
  AddNewMaterialsMaterialListPostAction: (response: GQLOps.ProjectState_AddNewMaterialsMaterialListMutation) => ({
    response,
  }),
  ApplyZoomRule: (materialListId: string) => ({ materialListId }),
  ApplyZoomRulePostAction: (response: GQLOps.ProjectState_ApplyZoomRuleMaterialListMutation) => ({
    response,
  }),

  // Materials
  UpdateMaterialListItem: (materialListId: string, patch: Patch<Project.Material>) => ({ materialListId, patch }),
  UpdateCustomMaterial: (materialListId: string, materialId: string, itemNumber: string) => ({
    materialListId,
    materialId,
    itemNumber,
  }),
  ReceivedUpdatedCustomMaterial: (mutation: GQLOps.ProjectState_UpdateCustomMaterialMutation) => ({ mutation }),
  AddCustomMaterial: (materialListId: string) => ({ materialListId }),
  RemoveMaterial: (materialListId: string, materialId: string) => ({ materialListId, materialId }),

  // Crm
  ExportToCrm: (envelopeOnly: boolean) => ({ envelopeOnly }),
  HandleCrmResponse: (response: Crm.Response) => ({ response }),

  // Material files
  StageFiles: (stagedFiles: ReadonlyArray<StagedFile>) => ({ stagedFiles }),
  ResetFromUploadedMaterialFiles: () => ({}),
  CreatedListFromFilesResponse: (
    response: Pick<GQLOps.ProjectState_UploadMaterialFilesMutation["uploadMaterialFiles"], "okResult" | "errorResult">
  ) => ({
    response,
  }),
  SetApplyZoomRuleWhenImporting: (applyZoomRule: boolean) => ({ applyZoomRule }),

  NoOp: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  _sharedState: SharedState.SharedState,
  project: Project.Project,
  metaProduct: Project.MetaProductQuery,
  materialTables: MaterialList.MaterialTables
): readonly [State, Cmd<Action>?] {
  return [
    {
      project,
      metaProduct,
      materialTables,
      projectIsBusy: false,
      crmExportResponse: undefined,
      disabledCustomMaterials: new Set(),
      stagedFiles: [],
      uploadingfiles: false,
      uploadingFilesStatus: undefined,
      uploadingFilesMessages: undefined,
      applyZoomRuleWhenImporting: true,
    },
  ];
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const { project } = state;
  switch (action.type) {
    case "RemoveProject": {
      return ProjectActions.RemoveProject(action, state, sharedState);
    }
    case "RemoveProjectResponse": {
      return ProjectActions.RemoveProjectResponse(action, state, sharedState);
    }
    case "UpdateProject": {
      return ProjectActions.UpdateProject(action, state, sharedState);
    }
    case "UpdateProjectLockState": {
      return ProjectActions.UpdateProjectLockState(action, state, sharedState);
    }
    case "ProjectUnlockResponse": {
      return [{ ...state, project: { ...project, locked: action.locked, permissions: action.permissions } }];
    }
    case "DuplicateProject": {
      return ProjectActions.DuplicateProject(action, state, sharedState);
    }
    case "DuplicateProjectResponse": {
      return ProjectActions.DuplicateProjectResponse(action, state, sharedState);
    }
    case "RemoveMaterialList": {
      return MaterialListActions.RemoveMaterialList(action, state, sharedState);
    }
    case "UpdateMaterialList": {
      return MaterialListActions.UpdateMaterialList(action, state, sharedState);
    }
    case "DuplicateMaterialList": {
      return MaterialListActions.DuplicateMaterialList(action, state, sharedState);
    }
    case "DuplicateMaterialListPostAction": {
      return MaterialListActions.DuplicateMaterialListPostAction(action, state, sharedState);
    }
    case "MultiplyMaterialQuantities": {
      return MaterialListActions.MultiplyMaterialQuantities(action, state, sharedState);
    }
    case "MultiplyMaterialQuantitiesPostAction": {
      return MaterialListActions.MultiplyMaterialQuantitiesPostAction(action, state, sharedState);
    }
    case "ApplyZoomRule": {
      return MaterialListActions.ApplyZoomRule(action, state, sharedState);
    }
    case "ApplyZoomRulePostAction": {
      return MaterialListActions.ApplyZoomRulePostAction(action, state, sharedState);
    }
    case "AddNewMaterialsMaterialList": {
      return MaterialListActions.AddNewMaterialsMaterialList(action, state, sharedState);
    }
    case "AddNewMaterialsMaterialListPostAction": {
      return MaterialListActions.AddNewMaterialsMaterialListPostAction(action, state, sharedState);
    }
    case "UpdateMaterialListItem": {
      return MaterialActions.UpdateMaterialListItem(action, state, sharedState);
    }
    case "UpdateCustomMaterial": {
      return MaterialActions.UpdateCustomMaterial(action, state, sharedState);
    }
    case "ReceivedUpdatedCustomMaterial": {
      return MaterialActions.ReceivedUpdatedCustomMaterial(action, state, sharedState);
    }
    case "AddCustomMaterial": {
      return MaterialActions.AddCustomMaterial(action, state, sharedState);
    }
    case "RemoveMaterial": {
      return MaterialActions.RemoveMaterial(action, state, sharedState);
    }
    case "StageFiles": {
      return MaterialFileActions.StageFiles(action, state, sharedState);
    }
    case "ResetFromUploadedMaterialFiles": {
      return MaterialFileActions.ResetFromUploadedMaterialFiles(action, state, sharedState);
    }
    case "CreatedListFromFilesResponse": {
      return MaterialFileActions.CreatedListFromFilesResponse(action, state, sharedState);
    }
    case "SetApplyZoomRuleWhenImporting": {
      return MaterialFileActions.SetApplyZoomRuleWhenImporting(action, state, sharedState);
    }
    case "ExportToCrm": {
      if (!sharedState.crmParams || state.projectIsBusy) {
        return [state];
      }
      const { project } = state;
      const req: Crm.Request = {
        quoteId: sharedState.crmParams.crmQuoteId,
        market: sharedState.market.name,
        shopLanguageCode: sharedState.market.shopLanguageCode,
        locale: sharedState.crmParams.crmQuoteLanguage,
        project: project.id,
        materialLists: project.materialLists.map((l) => l.id),
        envelope: action.envelopeOnly,
        apiVersion: sharedState.crmParams.crmApi,
      };
      return [
        { ...state, projectIsBusy: true, crmExportResponse: undefined },
        HttpFetch.postWithAuth(sharedState.activeUser)(
          {},
          "/rest/crm/add-project",
          "json",
          "application/json",
          JSON.stringify(req),
          (data) => Action.HandleCrmResponse(data as unknown as Crm.Response)
        ),
      ];
    }

    case "HandleCrmResponse": {
      switch (action.response.type) {
        case "envelope":
          saveAs(
            new Blob([action.response.envelope], {
              type: "application/xml",
            }),
            "envelope.xml"
          );
          return [{ ...state, projectIsBusy: false, crmExportResponse: undefined }];
        case "export":
          return [
            {
              ...state,
              project: action.response.status === 200 ? { ...project, locked: true } : project,
              projectIsBusy: false,
              crmExportResponse: action.response,
            },
            undefined,
            action.response.status === 200 ? SharedState.SharedStateAction.SetCrm(undefined) : undefined,
          ];

        default:
          return [state];
      }
    }

    case "NoOp":
      return [state];
    default:
      return exhaustiveCheck(action, true);
  }
}
