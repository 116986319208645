import { Project } from "..";
import { DuctTable, MaterialTables } from "./types";

export interface PackageRuleLog {
  readonly packageItemNumber: string;
  readonly ductDiameters: ReadonlyArray<number>;
  readonly quantityPerPackage: number;
  readonly changes: ReadonlyArray<{
    readonly itemNumber: string;
    readonly quantityBefore: number;
    readonly quantityAfter: number;
  }>;
}

export function applyPackageRuleOnAllDucts(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): {
  readonly materials: ReadonlyArray<Project.Material>;
  readonly log: ReadonlyArray<PackageRuleLog>;
} {
  const log: Array<PackageRuleLog> = [];
  let updatedMaterials = materials;
  for (const ductRow of materialTables.tables.ductTable) {
    if (ductRow.import_rule !== "duct_package") {
      continue;
    }
    const parts = (ductRow.import_rule_param || "").split(";");
    const quantityPerPackage = Number.parseInt(parts[0], 10);
    const diameters = (parts[1] || "")
      .split(",")
      .map((d) => Number.parseInt(d, 10))
      .filter((d) => Number.isFinite(d));
    if (!Number.isFinite(quantityPerPackage) || diameters.length === 0) {
      continue;
    }

    const result = applySinglePackageRuleOnAllDucts(
      materialTables.tables.ductTable,
      ductRow.item_number || "",
      quantityPerPackage,
      diameters,
      updatedMaterials
    );
    if (!result) {
      continue;
    }

    updatedMaterials = result.materials;
    log.push(result.log);
  }

  return { materials: updatedMaterials, log };
}

function applySinglePackageRuleOnAllDucts(
  ductTable: DuctTable,
  packageItemNumber: string,
  quantityPerPackage: number,
  ductDiameters: ReadonlyArray<number>,
  materials: ReadonlyArray<Project.Material>
):
  | {
      readonly materials: ReadonlyArray<Project.Material>;
      readonly log: PackageRuleLog;
    }
  | undefined {
  const packageMaterialToUpdate = materials.find((m) => m.itemNumber === packageItemNumber);
  if (!packageMaterialToUpdate) {
    return undefined;
  }

  const ductItemNumbers = ductTable
    .filter((r) => !!r.item_number && r.import_rule === "regular_duct" && ductDiameters.some((d) => d === r.diameter))
    .map((r) => r.item_number);
  const ductMaterialsToUpdate = ductItemNumbers
    .map((i) => materials.find((m) => m.itemNumber === i))
    .filter((m): m is Project.Material => !!m);
  if (ductMaterialsToUpdate.length === 0) {
    return undefined;
  }

  const numPackagesToAdd = ductMaterialsToUpdate.reduce(
    (sofar, m) => Math.min(sofar ?? Number.MAX_SAFE_INTEGER, Math.floor(m.quantity / quantityPerPackage)),
    undefined
  );
  if (!numPackagesToAdd) {
    return undefined;
  }

  const ductQuanityReduction = numPackagesToAdd * quantityPerPackage;

  const changes = [
    {
      itemNumber: packageMaterialToUpdate.itemNumber,
      quantityBefore: packageMaterialToUpdate.quantity,
      quantityAfter: packageMaterialToUpdate.quantity + numPackagesToAdd,
      quantityChange: numPackagesToAdd,
      materialId: packageMaterialToUpdate.id,
    },
    ...ductMaterialsToUpdate.map((m) => ({
      itemNumber: m.itemNumber,
      quantityBefore: m.quantity,
      quantityAfter: m.quantity - ductQuanityReduction,
      quantityChange: -ductQuanityReduction,
      materialId: m.id,
    })),
  ];

  const log = {
    packageItemNumber: packageMaterialToUpdate.itemNumber,
    ductDiameters: ductDiameters,
    quantityPerPackage: quantityPerPackage,
    changes: changes.map((c) => ({
      itemNumber: c.itemNumber,
      quantityBefore: c.quantityBefore,
      quantityAfter: c.quantityAfter,
    })),
  };

  const materialById = new Map(materials.map((m) => [m.id, m]));
  for (const { materialId, quantityChange } of changes) {
    const material = materialById.get(materialId)!;
    materialById.set(materialId, { ...material, quantity: material.quantity + quantityChange, included: true });
  }

  // To preserve order
  const updated = materials.map((m) => materialById.get(m.id)!);

  return { materials: updated, log };
}
