import { MaterialList } from "../types";

export function createMaterialList(id: string, name: string, sortNo: number): MaterialList {
  return {
    id: id,
    sortNo: sortNo,
    name: name,
    materials: [],
  };
}
