import loglevel from "loglevel";

setLogLevel("info");

export function setLogLevel(level: "warn" | "error" | "debug" | "info"): void {
  loglevel.setDefaultLevel(level);
  loglevel.setLevel(level);
}

export { info as log } from "loglevel";
export { error as logError } from "loglevel";
export { warn as logWarn } from "loglevel";
export { info as logInfo } from "loglevel";
export { debug as logDebug } from "loglevel";
