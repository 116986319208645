import gql from "graphql-tag";

export const schema = gql`
  extend type Mutation {
    uploadMaterialFiles(input: UploadMaterialFilesInput!): UploadMaterialFilesPayload!
    resetFromUploadedMaterialFiles(input: ResetFromUploadedMaterialFilesInput!): ResetFromUploadedMaterialFilesPayload!
  }

  type MaterialFiles {
    downloadAllUrl: String!
    files: [MaterialFile!]!
  }

  type MaterialFile {
    id: ID!
    name: String!
    format: String!
    type: String!
    uploaded: Date!
    lastModified: Date!
    url: String!
  }

  input UploadMaterialFilesInput {
    marketName: String!
    projectId: ID!
    disableZoomRule: Boolean!
    files: [MaterialFileInput!]!
  }

  input MaterialFileInput {
    id: ID!
    name: String!
    format: String!
    type: String!
    uploaded: Date!
    lastModified: Date!
    contents: Upload!
  }

  type UploadMaterialFilesPayload {
    okResult: MaterialListFromFilesOkResult
    errorResult: MaterialListFromFilesErrorResult
  }

  type MaterialListFromFilesOkResult {
    downloadAllUrl: String!
    materialFiles: [MaterialFile!]!
    materialList: MaterialList!
    messages: [UploadMaterialFilesMessage!]!
  }

  type MaterialListFromFilesErrorResult {
    messages: [UploadMaterialFilesMessage!]!
  }

  type UploadMaterialFilesMessage {
    fileName: String!
    level: String!
    textKey: String!
    params: [UploadMaterialFilesMessageParam!]!
  }

  type UploadMaterialFilesMessageParam {
    key: String!
    value: String!
  }

  input ResetFromUploadedMaterialFilesInput {
    marketName: String!
    projectId: ID!
    disableZoomRule: Boolean!
  }

  type ResetFromUploadedMaterialFilesPayload {
    okResult: MaterialListFromFilesOkResult
    errorResult: MaterialListFromFilesErrorResult
  }
`;
