import { DocumentNode, print, ExecutionResult } from "graphql";
import { Cmd } from "@typescript-tea/core";
import { GraphQlUtils, User } from "@revit-order/shared";
import * as HttpFetch from "../effect-managers/http-fetch";
import * as GraphQlFetch from "../effect-managers/graphql-fetch";

function buildAuthHeaders(accessToken: string): Record<string, string> {
  return {
    Authorization: "Bearer " + accessToken,
  };
}

// Runs queries to our own rvs endpoint to get projects/data
export const graphQLQueryWithAuth =
  (activeUser: User.ActiveUser) =>
  <TData, TVariables, A>(
    query: DocumentNode,
    variables: TVariables,
    marketName: string,
    successActionCreator: (response: TData) => A
  ): Cmd<A> => {
    const gqlBody = JSON.stringify({ query: print(query), variables, marketName });
    const fetchOneCmd = HttpFetch.post(
      { ...buildAuthHeaders(activeUser.accessToken), "Content-Type": "application/json" },
      "/graphql?",
      "json",
      "application/json",
      gqlBody,
      (result: ExecutionResult) => {
        if (!result.data || result.errors) {
          throw new Error(`GraphQL query failed: ${gqlBody}, Errors: ${JSON.stringify(result.errors)}`);
        }
        return successActionCreator(result.data as TData);
      },
      () => {
        throw new Error(`GraphQL query failed (HTTP error): ${gqlBody}`);
      }
    );
    return fetchOneCmd;
  };

// Runs mutations to our own rvs endpoint to update data
// eslint-disable-next-line functional/no-let
let gqlMutationLog: ReadonlyArray<{
  readonly id: number;
  readonly time: number;
  readonly gqlBody: string;
  readonly completed: boolean;
}> = [];
// eslint-disable-next-line functional/no-let
let gqlMutationLogNextId = 0;
export const graphQLMutationWithAuth =
  (activeUser: User.ActiveUser, marketName: string) =>
  <TData, TVariables, A>(
    mutation: DocumentNode,
    variables: TVariables,
    onSuccess?: (response: TData) => A,
    onError?: () => A
  ): Cmd<A> => {
    const gqlBody = JSON.stringify({ query: print(mutation), variables });
    const logEntry = {
      id: gqlMutationLogNextId++,
      time: Date.now(),
      gqlBody,
      completed: false,
    };
    gqlMutationLog = [logEntry, ...gqlMutationLog.filter((e, i) => !e.completed || i < 10)];
    // We are using GraphQlFetch.fetchGraphl instead of HttpFetch.post to get support for multi-part (the Upload scalar)
    const fetchOneCmd = GraphQlFetch.fetchGraphl(
      { ...buildAuthHeaders(activeUser.accessToken), "x-market-name": marketName },
      mutation,
      variables,
      onSuccess &&
        ((response) => {
          if (response.type === "SuccessResponse") {
            const result = response.response;
            if (!result.data || result.errors) {
              throw new Error(
                `GraphQL errors, id:${logEntry.id}: '${JSON.stringify(result.errors)}'. Mutation log:\n${gqlMutationLog
                  .map((e, i) => `${i} (${e.time}, id:${e.id}, completed:${e.completed}): ${e.gqlBody}`)
                  .join("\n")}`
              );
            }
            gqlMutationLog = gqlMutationLog.map((e) => (e.id === logEntry.id ? { ...e, completed: true } : e));
            return onSuccess(result.data as TData);
          } else if (onError) {
            return onError();
          } else {
            throw new Error(
              `GraphQL mutation failed (HTTP error, id:${logEntry.id}). Mutation log:\n${gqlMutationLog
                .map((e, i) => `${i} (${e.time}, id:${e.id}, completed:${e.completed}): ${e.gqlBody}`)
                .join("\n")}`
            );
          }
        })
    );
    return fetchOneCmd;
  };

// Runs queries against Promaster API to get Promaster data
export const graphQLProductQueryWithAuth =
  (activeUser: User.ActiveUser, marker: string) =>
  <TData, TVariables, A>(
    query: DocumentNode,
    variables: TVariables,
    successActionCreator: (response: TData) => A
  ): Cmd<A> => {
    const gqlBody = JSON.stringify({ query: print(query), variables });
    const fetchOneCmd = HttpFetch.post(
      { ...buildAuthHeaders(activeUser.accessToken), "Content-Type": "application/json" },
      GraphQlUtils.createApiUrl(marker),
      "json",
      "application/json",
      gqlBody,
      (result: ExecutionResult) => {
        if (!result.data || result.errors) {
          throw new Error(`GraphQL query failed: ${gqlBody}, Errors: ${JSON.stringify(result.errors)}`);
        }
        return successActionCreator(result.data as TData);
      }
    );
    return fetchOneCmd;
  };
