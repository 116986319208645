import * as R from "ramda";
import { Project, Texts } from "..";
import { ValidationResult, ValidationError, MaterialTables } from "./types";

export function validate(materialTables: MaterialTables, materialList: Project.MaterialList): ValidationResult {
  const errors = [];
  errors.push(...validateCustomMaterials(materialList.materials));
  errors.push(...validateRemovedItems(materialTables, materialList.materials));
  errors.push(...validateAdddedItems(materialTables, materialList.materials));
  const errorsById = errors
    .filter((e) => !!e.materialId)
    .reduce<{ [materialId: string]: ValidationError }>((sofar, error) => {
      sofar[error.materialId!] = error;
      return sofar;
    }, {});
  return {
    errors,
    errorsById,
  };
}

function validateRemovedItems(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const errors: Array<ValidationError> = [];
  for (const m of materials) {
    if (m.type !== "custom" && !materialTables.items[m.itemNumber]) {
      errors.push({
        category: "item_has_been_removed",
        materialId: m.id,
        message: Texts.texts.item_has_been_removed,
      });
    }
  }
  return errors;
}

function validateAdddedItems(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<ValidationError> {
  const itemsInMaterialList = new Set(materials.map((m) => m.itemNumber));
  const itemsInTables = R.keys(materialTables.items);
  for (const itemNumber of itemsInTables) {
    if (!itemsInMaterialList.has(itemNumber as string)) {
      return [
        {
          category: "new_item_available",
          message: Texts.texts.new_items_available,
        },
      ];
    }
  }
  return [];
}

function validateCustomMaterials(materials: ReadonlyArray<Project.Material>): ReadonlyArray<ValidationError> {
  const errors: Array<ValidationError> = [];
  for (const m of materials) {
    if (m.type === "custom" && !m.itemNumber) {
      errors.push({
        category: "missing_item_number",
        materialId: m.id,
        message: Texts.texts.item_number_required,
      });
    }
  }
  return errors;
}
