import * as R from "ramda";
import { logWarn, Project } from "..";
import { Patch } from "../project";
import { Duct, MaterialTables, ZoomRule } from "./types";

export interface ZoomRuleLog {
  readonly ductItemNumber: string;
  readonly ductSize: number;
  readonly ductQuantity: number;
  readonly changes: ReadonlyArray<{
    readonly itemNumber: string;
    readonly quantityBefore: number;
    readonly quantityAfter: number;
  }>;
}

export function getDuctMaterialsAffectedByRoomRule(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  const ruleDiameters = new Set(materialTables.tables.zoomRuleTable.map((r) => r.duct_diameter));
  const itemNumbers = new Set(
    materialTables.tables.ductTable
      .filter((row) => row.import_rule === "regular_duct" && row.item_number && ruleDiameters.has(row.diameter))
      .map((r) => r.item_number || "")
  );
  return materials.filter((m) => itemNumbers.has(m.itemNumber));
}

export function getMaterialsAffectedByRoomRule(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<Project.Material> {
  const ruleDiameters = new Set(materialTables.tables.zoomRuleTable.map((r) => r.duct_diameter));
  const itemNumbers = new Set([
    ...materialTables.tables.ductTable
      .filter((row) => row.import_rule === "regular_duct" && row.item_number && ruleDiameters.has(row.diameter))
      .map((r) => r.item_number || ""),
    ...R.unnest(
      materialTables.tables.zoomRuleTable.map((row) => [
        row.item_1_itemnumber || "",
        row.item_2_itemnumber || "",
        row.item_3_itemnumber || "",
      ])
    ),
  ]);
  return materials.filter((m) => itemNumbers.has(m.itemNumber));
}

export function calculateZoomRuleChanges(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>,
  ductItemNumber: string
): {
  readonly changes: ReadonlyArray<Pick<Project.Material, "id" | "included" | "quantity">>;
  readonly log: ZoomRuleLog | undefined;
} {
  const affectedMaterials = getMaterialsAffectedByRoomRule(materialTables, materials);

  const duct = materialTables.tables.ductTable.find(
    (row) => row.import_rule === "regular_duct" && row.item_number === ductItemNumber
  );
  if (!duct) {
    return { changes: [], log: undefined };
  }

  const ductMaterial = materials.find((m) => m.itemNumber === duct.item_number);
  if (!ductMaterial) {
    return { changes: [], log: undefined };
  }

  const zoomRule = materialTables.tables.zoomRuleTable.find(
    (row) =>
      row.duct_diameter === duct.diameter &&
      row.min_quantity &&
      row.max_quantity &&
      ductMaterial.quantity >= row.min_quantity - 0.00001 &&
      ductMaterial.quantity <= row.max_quantity + 0.00001
  );
  if (!zoomRule) {
    return { changes: [], log: undefined };
  }

  const adjustments = getQuantityAdjustments(zoomRule, duct, affectedMaterials);
  const updates = [];
  for (const material of affectedMaterials) {
    const update = { id: material.id, included: material.included, quantity: material.quantity };
    const adjustment = adjustments.find((a) => a.id === update.id);
    if (adjustment) {
      update.quantity += adjustment.adjustment;
      if (adjustment.adjustment > 0) {
        update.included = true;
      }
    }
    if (update.included !== material.included || update.quantity !== material.quantity) {
      updates.push(update);
    }
  }

  const changes: Array<ZoomRuleLog["changes"][number]> = [];
  for (const update of updates) {
    const material = affectedMaterials.find((m) => m.id === update.id);
    if (!material) {
      continue;
    }
    changes.push({
      itemNumber: material.itemNumber,
      quantityBefore: material.quantity,
      quantityAfter: update.quantity,
    });
  }
  const log: ZoomRuleLog | undefined =
    changes.length > 0
      ? {
          ductItemNumber: ductMaterial.itemNumber,
          ductSize: duct.diameter || 0,
          ductQuantity: ductMaterial.quantity,
          changes,
        }
      : undefined;

  return { changes: updates, log };
}

export function applyZoomRuleOnAllDucts(
  materialTables: MaterialTables,
  materials: ReadonlyArray<Project.Material>
): {
  readonly materials: ReadonlyArray<Project.Material>;
  readonly patches: ReadonlyArray<Patch<Project.Material>>;
  readonly log: ReadonlyArray<ZoomRuleLog>;
} {
  const ductMaterials = getDuctMaterialsAffectedByRoomRule(materialTables, materials);
  const materialById = new Map(materials.map((m) => [m.id, m]));
  const patchById = new Map<string, Patch<Project.Material>>();
  const logs = [];
  for (const ductMaterial of ductMaterials) {
    const { changes, log } = calculateZoomRuleChanges(materialTables, materials, ductMaterial.itemNumber);
    if (log) {
      logs.push(log);
    }
    for (const change of changes) {
      materialById.set(change.id, { ...materialById.get(change.id)!, ...change });
      patchById.set(change.id, { ...(patchById.get(change.id) || {}), ...change, id: change.id });
    }
  }

  // To preserve order
  const updated = materials.map((m) => materialById.get(m.id)!);

  const patches = [...patchById.values()];

  return { materials: updated, patches: patches, log: logs };
}

function getQuantityAdjustments(
  zoomRule: ZoomRule,
  duct: Duct,
  materials: ReadonlyArray<Project.Material>
): ReadonlyArray<{ readonly id: string; readonly adjustment: number }> {
  const zoomRuleAdjust: ReadonlyArray<[string | null, number]> = [
    [zoomRule.item_1_itemnumber, zoomRule.item_1_quantity ?? NaN],
    [zoomRule.item_2_itemnumber, zoomRule.item_2_quantity ?? NaN],
    [zoomRule.item_3_itemnumber, zoomRule.item_3_quantity ?? NaN],
    [duct.item_number, -(zoomRule.quantity_reduction_duct ?? NaN)],
  ];
  const updates = [];
  for (const [itemNumber, adjustment] of zoomRuleAdjust) {
    const material = materials.find((m) => m.itemNumber === itemNumber);
    if (!material) {
      continue;
    }
    if (!Number.isFinite(adjustment)) {
      logWarn(`Incorrect data specified in ZoomRule table: ${JSON.stringify(zoomRule)}`);
      continue;
    }
    updates.push({ id: material.id, adjustment });
  }

  return updates;
}
