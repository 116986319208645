import gql from "graphql-tag";

export const updateProjectMutation = gql`
  mutation ProjectState_updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
    }
  }
`;

export const removeProjectMutation = gql`
  mutation ProjectState_removeProject($input: RemoveProjectInput!) {
    removeProject(input: $input) {
      id
    }
  }
`;

export const duplicateProjectMutation = gql`
  mutation ProjectState_duplicateProject($input: DuplicateProjectInput!) {
    duplicateProject(input: $input) {
      id
    }
  }
`;

export const updateProjectLockStateMutation = gql`
  mutation ProjectState_updateProjectLockState($input: UpdateProjectLockStateInput!) {
    updateProjectLockState(input: $input) {
      id
      locked
      permissions
    }
  }
`;

export const removeMaterialListMutation = gql`
  mutation ProjectState_removeMaterialList($input: RemoveMaterialListInput!) {
    removeMaterialList(input: $input) {
      id
    }
  }
`;

export const updateMaterialListMutation = gql`
  mutation ProjectState_updateMaterialList($input: UpdateMaterialListInput!) {
    updateMaterialList(input: $input) {
      id
    }
  }
`;

export const duplicateMaterialListMutation = gql`
  mutation ProjectState_duplicateMaterialList($input: DuplicateMaterialListInput!) {
    duplicateMaterialList(input: $input) {
      materialList {
        id
        sortNo
        name
        materials {
          id
          sortNo
          itemNumber
          quantity
          included
          type
          name
        }
      }
    }
  }
`;

export const multiplyQuantitiesMaterialListMutation = gql`
  mutation ProjectState_multiplyQuantitiesMaterialList($input: MultiplyQuantitiesMaterialListInput!) {
    multiplyQuantitiesMaterialList(input: $input) {
      materialList {
        id
        sortNo
        name
        materials {
          id
          sortNo
          itemNumber
          quantity
          included
          type
          name
        }
      }
    }
  }
`;

export const applyZoomRuleMaterialListMutation = gql`
  mutation ProjectState_applyZoomRuleMaterialList($input: ApplyZoomRuleMaterialListInput!) {
    applyZoomRuleMaterialList(input: $input) {
      materialList {
        id
        sortNo
        name
        materials {
          id
          sortNo
          itemNumber
          quantity
          included
          type
          name
        }
      }
    }
  }
`;

export const addNewMaterialsMaterialListMutation = gql`
  mutation ProjectState_addNewMaterialsMaterialList($input: AddNewMaterialsMaterialListInput!) {
    addNewMaterialsMaterialList(input: $input) {
      materialList {
        id
        sortNo
        name
        materials {
          id
          sortNo
          itemNumber
          quantity
          included
          type
          name
        }
      }
    }
  }
`;

export const createMaterialsMutation = gql`
  mutation ProjectState_createMaterials($input: CreateMaterialsInput!) {
    createMaterials(input: $input) {
      ids
    }
  }
`;

export const updateMaterialsMutation = gql`
  mutation ProjectState_updateMaterials($input: UpdateMaterialsInput!) {
    updateMaterials(input: $input) {
      ids
    }
  }
`;

export const removeMaterialsMutation = gql`
  mutation ProjectState_removeMaterials($input: RemoveMaterialsInput!) {
    removeMaterials(input: $input) {
      ids
    }
  }
`;

export const updateCustomMaterialMutation = gql`
  mutation ProjectState_updateCustomMaterial($input: UpdateCustomMaterialInput!) {
    updateCustomMaterial(input: $input) {
      materialListId
      materialId
      status
      updatedMaterial {
        id
        name
      }
    }
  }
`;

const uploadFilesResponseFragments = gql`
  fragment Files_materialListFromFilesOkResult on MaterialListFromFilesOkResult {
    downloadAllUrl
    materialFiles {
      ...Files_materialFiles
    }
    materialList {
      ...Files_materialList
    }
    messages {
      ...Files_uploadMessage
    }
  }
  fragment Files_materialListFromFilesErrorResult on MaterialListFromFilesErrorResult {
    messages {
      ...Files_uploadMessage
    }
  }
  fragment Files_uploadMessage on UploadMaterialFilesMessage {
    fileName
    level
    textKey
    params {
      key
      value
    }
  }
  fragment Files_materialList on MaterialList {
    id
    sortNo
    name
    materials {
      ...Files_material
    }
  }
  fragment Files_material on Material {
    id
    sortNo
    itemNumber
    quantity
    included
    type
    name
  }
  fragment Files_materialFiles on MaterialFile {
    id
    name
    format
    type
    uploaded
    lastModified
    url
  }
`;

export const uploadMaterialFilesMutation = gql`
  mutation ProjectState_uploadMaterialFiles($input: UploadMaterialFilesInput!) {
    uploadMaterialFiles(input: $input) {
      okResult {
        ...Files_materialListFromFilesOkResult
      }
      errorResult {
        ...Files_materialListFromFilesErrorResult
      }
    }
  }
  ${uploadFilesResponseFragments}
`;

export const resetFromUploadedMaterialFiles = gql`
  mutation ProjectState_resetFromUploadedMaterialFiles($input: ResetFromUploadedMaterialFilesInput!) {
    resetFromUploadedMaterialFiles(input: $input) {
      okResult {
        ...Files_materialListFromFilesOkResult
      }
      errorResult {
        ...Files_materialListFromFilesErrorResult
      }
    }
  }
  ${uploadFilesResponseFragments}
`;
