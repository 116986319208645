import gql from "graphql-tag";

export const schema = gql`
  type MaterialList {
    id: ID!
    sortNo: Int!
    name: String
    materials: [Material!]!
    changes: MaterialListChanges!
  }

  type MaterialListChanges {
    importLog: [MaterialListImportChange!]!
    zoomRuleLogItems: [String!]!
    packageRuleLogItems: [String!]!
  }

  type MaterialListImportChange {
    fileName: String!
    logItems: [String!]!
  }

  extend type Mutation {
    removeMaterialList(input: RemoveMaterialListInput!): RemoveMaterialListPayload!
    updateMaterialList(input: UpdateMaterialListInput!): UpdateMaterialListPayload!
    duplicateMaterialList(input: DuplicateMaterialListInput!): DuplicateMaterialListPayload!
    multiplyQuantitiesMaterialList(input: MultiplyQuantitiesMaterialListInput!): MultiplyQuantitiesMaterialListPayload!
    applyZoomRuleMaterialList(input: ApplyZoomRuleMaterialListInput!): ApplyZoomRuleMaterialListPayload!
    addNewMaterialsMaterialList(input: AddNewMaterialsMaterialListInput!): AddNewMaterialsMaterialListPayload!
  }

  input RemoveMaterialListInput {
    id: ID!
  }

  type RemoveMaterialListPayload {
    id: ID!
  }

  input UpdateMaterialListInput {
    id: ID!
    sortNo: Int
    name: String
  }

  type UpdateMaterialListPayload {
    id: ID!
  }

  input DuplicateMaterialListInput {
    id: ID!
  }

  type DuplicateMaterialListPayload {
    materialList: MaterialList!
  }

  input MultiplyQuantitiesMaterialListInput {
    id: ID!
    multiplier: Int!
  }

  type MultiplyQuantitiesMaterialListPayload {
    materialList: MaterialList!
  }

  input ApplyZoomRuleMaterialListInput {
    id: ID!
  }

  type ApplyZoomRuleMaterialListPayload {
    materialList: MaterialList!
  }

  input AddNewMaterialsMaterialListInput {
    id: ID!
  }

  type AddNewMaterialsMaterialListPayload {
    materialList: MaterialList!
  }
`;
