import { Project } from "..";
import { TextKey } from "../lang-texts";
import * as Gql from "../generated/generated-operations";
import { Row, RowWithSortNo, Table } from "../graphql-utils";
import { Categories } from "./categories";

export const customItemsCategoryName = "custom_items";
export const notFoundSortNo = 999999;

export type Duct = RowWithSortNo<
  Omit<Gql.Duct, "include_in_duct_schedule" | "import_rule"> & {
    readonly import_rule: "ignore" | "short_duct" | "regular_duct" | "duct_package" | null;
  }
>;
export type Insulation = RowWithSortNo<Gql.Insulation>;
export type StandardItem = RowWithSortNo<Gql.StandardItem>;
export type ZoomRule = Row<Gql.ZoomRule>;

export type DuctTable = Table<Duct>;
export type InsulationTable = Table<Insulation>;
export type StandardItemTable = Table<StandardItem>;
export type ZoomRuleTable = Table<ZoomRule>;

export type ItemType = "duct" | "insulation" | "standard" | "unknown";

export type DuctItem = { readonly type: "duct" } & Duct;
export type InsulationItem = { readonly type: "insulation" } & Insulation;
export type StandardItemItem = { readonly type: "standard" } & StandardItem;

export type Item =
  | DuctItem
  | InsulationItem
  | StandardItemItem
  | {
      readonly type: "unknown";
      readonly item_number: string;
    };

// An item that is stored in a material list but can't be found in the data
export const unknownItem: Item = {
  type: "unknown",
  item_number: "-",
};

export interface MaterialTables {
  readonly tables: {
    readonly ductTable: DuctTable;
    readonly insulationTable: InsulationTable;
    readonly standardItemTable: StandardItemTable;
    readonly zoomRuleTable: ZoomRuleTable;
  };
  readonly items: { readonly [itemNumber: string]: Item };
  readonly sortNos: ReadonlyMap<string, number>;
  readonly categories: Categories;
}

export interface MetaData {
  readonly group: string;
  readonly category: string | undefined;
  readonly readOnlyQuantity: boolean;
  readonly singleGroupSelection?: boolean;
  readonly material: Project.Material;
}

export interface ValidationError {
  readonly category?: "quantity" | "missing_item_number" | "new_item_available" | "item_has_been_removed";
  readonly materialId?: string;
  readonly message: TextKey;
}

export interface ValidationResult {
  readonly errors: ReadonlyArray<ValidationError>;
  readonly errorsById: {
    readonly [materialId: string]: ValidationError;
  };
}

export interface Category {
  readonly name: string;
  readonly materials: ReadonlyArray<Project.Material>;
}

export type MaterialChange = "created" | "updated" | "deleted";
export interface UpdateListResult {
  readonly materials: ReadonlyArray<Project.Material>;
  readonly changedMaterialIds: ReadonlyMap<string, MaterialChange>;
}
